<template>
  <div class="bg-neutral-50 h-full">
    <div class="lg:pl-72">
      <main class="py-4">
        <div class="px-4">
          <div class="mb-2">
            <div>
              <nav class="hidden sm:flex" aria-label="Breadcrumb">
                <ol role="list" class="flex items-center space-x-4">
                  <li>
                    <div class="flex">
                      <a href="/punktesystem" class="text-sm font-medium text-gray-500 hover:text-gray-700">
                        <span v-if="page === 'overview'">Übersicht</span>
                        <span v-else-if="page === 'goodies'">Goodies & Ränge</span>
                        <span v-else-if="page === 'shop'">Prämienshop</span>
                        <span v-else-if="page === 'settings'">Einstellungen</span>
                      </a>
                    </div>
                  </li>
                </ol>
              </nav>
            </div>
            <div class="mt-2 md:flex md:items-center md:justify-between">
              <div class="min-w-0 flex-1">
                <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">Punktesystem</h2>
              </div>
              <div class="mt-4 flex flex-shrink-0 md:ml-4 md:mt-0">
              </div>
            </div>
          </div>
          <section class="mb-4">
            <div>
              <div class="sm:hidden">
                <label for="tabs" class="sr-only">Select a tab</label>
                <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
                <select v-model="page" id="tabs" name="tabs" class="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500">
                  <option value="overview">Übersicht</option>
                  <option value="goodies">Goodies & Ränge</option>
                  <option value="shop">Prämienshop</option>
                  <option value="settings">Einstellungen</option>
                </select>
              </div>
              <div class="hidden sm:block">
                <div class="border-b border-gray-200">
                  <nav class="-mb-px flex space-x-8" aria-label="Tabs">
                    <a @click="page = 'overview'" :class="[page === 'overview' ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700']" class="cursor-pointer group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium">
                      <svg :class="[page === 'overview' ? 'text-indigo-500' : 'text-gray-400 group-hover:text-gray-500']" class="-ml-0.5 mr-2 h-5 w-5"  fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25M9 16.5v.75m3-3v3M15 12v5.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                      </svg>
                      <span>Übersicht</span>
                    </a>
                    <a @click="page = 'goodies'" :class="[page === 'goodies' ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700']" class="cursor-pointer group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium">
                      <svg :class="[page === 'goodies' ? 'text-indigo-500' : 'text-gray-400 group-hover:text-gray-500']" class="-ml-0.5 mr-2 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M21 11.25v8.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5v-8.25M12 4.875A2.625 2.625 0 109.375 7.5H12m0-2.625V7.5m0-2.625A2.625 2.625 0 1114.625 7.5H12m0 0V21m-8.625-9.75h18c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125h-18c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
                      </svg>
                      <span>Goodies & Ränge</span>
                    </a>
                    <a @click="page = 'shop'" :class="[page === 'shop' ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700']" class="cursor-pointer group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium">
                      <svg :class="[page === 'shop' ? 'text-indigo-500' : 'text-gray-400 group-hover:text-gray-500']" class="-ml-0.5 mr-2 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z" />
                      </svg>
                      <span>Prämienshop</span>
                    </a>
                    <a @click="page = 'settings'" :class="[page === 'settings' ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700']" class="cursor-pointer group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium">
                      <svg :class="[page === 'settings' ? 'text-indigo-500' : 'text-gray-400 group-hover:text-gray-500']" class="-ml-0.5 mr-2 h-5 w-5" viewBox="0 0 24 24" fill="none" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75" />
                      </svg>
                      <span>Einstellungen</span>
                    </a>
                  </nav>

                </div>
              </div>
            </div>
          </section>
          <section v-if="page === 'overview'">
            <div class="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow p-4">
              <div class="border-b border-gray-200 bg-white py-5">
                <h3 class="text-base font-semibold leading-6 text-gray-900">Highscore - Top 10</h3>
              </div>
              <div>
                <div class="flow-root">
                  <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                      <table class="min-w-full divide-y divide-gray-300">
                        <thead>
                        <tr>
                          <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">Nutzer</th>
                          <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Statuspunkte</th>
                        </tr>
                        </thead>
                        <tbody class="divide-y divide-gray-200 bg-white">
                        <tr v-for="user in userHighscore" :key="user.userId">
                          <td class="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                            <a :href="'/customerInfo/'+user.userId">
                              <div class="flex items-center">
                                <div class="h-11 w-11 flex-shrink-0">
                                  <img class="h-11 w-11 rounded-full bg-slate-200" :src="config.projectURL + 'assets/images/users/' + user.avatar" alt="">
                                </div>
                                <div class="ml-4">
                                  <div class="font-medium text-gray-900">{{ user.firstname }} {{ user.lastname }}</div>
                                  <div class="mt-1 text-gray-500">{{user.email}}</div>
                                </div>
                              </div>
                            </a>
                          </td>
                          <td class="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                            <span class="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-600/20">{{user.totalPoints}}</span>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section v-else-if="page === 'goodies'">
            <div class="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow p-4">
              <div class="border-b border-gray-200 bg-white py-5">
                <h3 class="text-base font-semibold leading-6 text-gray-900">Goodies und Ränge für Statuspunkte</h3>
              </div>
              <ul role="list" class="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8">
                <li class="overflow-hidden rounded-xl border border-gray-200 bg-slate-50" v-for="goodie in userPointsGoodies" :key="goodie.id">
                  <div class="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-100 p-6">
                    <div class="text-sm font-bold leading-6 text-gray-900">{{ goodie.rangName }}</div>
                    <div class="relative ml-auto">
                      <button @click="getGoodieById(goodie.id); editGoodieModal = true;" type="button" class="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500" id="options-menu-0-button" aria-expanded="false" aria-haspopup="true">
                        <span class="sr-only">Edit</span>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <dl class="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                    <div class="flex justify-between gap-x-4 py-3">
                      <dt class="text-gray-500">Benötigte Punkte:</dt>
                      <dd class="text-gray-700">{{goodie.pointGoal}}</dd>
                    </div>
                    <div class="flex justify-between gap-x-4">
                      <dt class="text-gray-500">Goodies:</dt>
                    </div>
                    <div class="pb-4">
                      <div class="font-medium text-gray-900" v-html="nl2br(goodie.goodies)"></div>
                    </div>
                  </dl>
                </li>
                <li>
                  <button @click="clear(); createGoodieModal = true;" type="button" class="relative block w-full h-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"  class="mx-auto h-12 w-12 text-gray-400">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M12 3.75v16.5M2.25 12h19.5M6.375 17.25a4.875 4.875 0 004.875-4.875V12m6.375 5.25a4.875 4.875 0 01-4.875-4.875V12m-9 8.25h16.5a1.5 1.5 0 001.5-1.5V5.25a1.5 1.5 0 00-1.5-1.5H3.75a1.5 1.5 0 00-1.5 1.5v13.5a1.5 1.5 0 001.5 1.5zm12.621-9.44c-1.409 1.41-4.242 1.061-4.242 1.061s-.349-2.833 1.06-4.242a2.25 2.25 0 013.182 3.182zM10.773 7.63c1.409 1.409 1.06 4.242 1.06 4.242S9 12.22 7.592 10.811a2.25 2.25 0 113.182-3.182z" />
                    </svg>
                    <span class="mt-2 block text-sm font-semibold text-gray-900">Neuen Rang erstellen</span>
                  </button>
                </li>
              </ul>
            </div>
          </section>
          <section v-else-if="page === 'shop'">
            <div class="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow p-4">
              <div class="border-b border-gray-200 bg-white py-5">
                <h3 class="text-base font-semibold leading-6 text-gray-900">Prämien zum einlösen</h3>
              </div>

              <ul role="list" class="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8">
                <li class="overflow-hidden rounded-xl border border-gray-200 bg-slate-50" v-for="praemie in userPointsPraemien" :key="praemie.id">
                  <div class="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-100 p-6">
                    <div class="text-sm font-bold leading-6 text-gray-900">{{ praemie.title }}</div>
                    <div class="relative ml-auto">
                      <button @click="getPraemieById(praemie.id); createPraemieModal = true;" type="button" class="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500" id="options-menu-0-button" aria-expanded="false" aria-haspopup="true">
                        <span class="sr-only">Edit</span>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <dl class="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                    <div class="flex justify-between gap-x-4 py-3">
                      <dt class="text-gray-500">Benötigte Punkte:</dt>
                      <dd class="text-gray-700">{{praemie.price}}</dd>
                    </div>
                    <div class="flex justify-between gap-x-4">
                      <dt class="text-gray-500">Beschreiung:</dt>
                    </div>
                    <div class="pb-4">
                      <div class="font-medium text-gray-900" v-html="nl2br(praemie.description)"></div>
                    </div>
                  </dl>
                </li>
                <li>
                  <button @click="clear(); createPraemieModal = true;" type="button" class="relative block w-full h-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"  class="mx-auto h-12 w-12 text-gray-400">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M12 3.75v16.5M2.25 12h19.5M6.375 17.25a4.875 4.875 0 004.875-4.875V12m6.375 5.25a4.875 4.875 0 01-4.875-4.875V12m-9 8.25h16.5a1.5 1.5 0 001.5-1.5V5.25a1.5 1.5 0 00-1.5-1.5H3.75a1.5 1.5 0 00-1.5 1.5v13.5a1.5 1.5 0 001.5 1.5zm12.621-9.44c-1.409 1.41-4.242 1.061-4.242 1.061s-.349-2.833 1.06-4.242a2.25 2.25 0 013.182 3.182zM10.773 7.63c1.409 1.409 1.06 4.242 1.06 4.242S9 12.22 7.592 10.811a2.25 2.25 0 113.182-3.182z" />
                    </svg>
                    <span class="mt-2 block text-sm font-semibold text-gray-900">Neuen Prämie erstellen</span>
                  </button>
                </li>
              </ul>

            </div>
          </section>
          <section v-else-if="page === 'settings'">
            <div class="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow p-4">
              <div class="border-b border-gray-200 bg-white py-5">
                <h3 class="text-base font-semibold leading-6 text-gray-900">Grundeinstellungen</h3>
              </div>
              <form method="post" @submit.prevent="saveConfig()">
                <div>
                  <label for="days" class="block text-sm font-medium leading-6 text-gray-900">Wie lange sollen Statuspunkte berechnet werden?</label>
                  <div class="mt-2 flex rounded-md shadow-sm">
                    <div class="relative flex flex-grow items-stretch focus-within:z-10">
                      <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5 text-gray-400">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      </div>
                      <input required v-model="days" type="number" name="days" id="days" class="block w-full rounded-none rounded-l-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Anzahl der Tage">
                    </div>
                    <button type="button" class="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                      Tage
                    </button>
                  </div>
                </div>
                <div class="mt-4 flex flex-row-reverse">
                  <button type="submit" class="relative inline-flex items-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
                    Speichern
                  </button>
                </div>
              </form>
            </div>
            <div class="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow p-4 mt-4">
              <div class="border-b border-gray-200 bg-white py-5">
                <h3 class="text-base font-semibold leading-6 text-gray-900">Punkteeinstellungen</h3>
              </div>
              <ul role="list" class="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8">
                <li class="overflow-hidden rounded-xl border border-gray-200 bg-slate-50" v-for="pointConfig in userPointsConfig" :key="pointConfig.id">
                  <div class="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-100 p-6">
                    <div class="text-sm font-medium leading-6 text-gray-900">{{ pointConfig.actionTitle }}</div>
                    <div class="relative ml-auto">
                      <button @click="getById(pointConfig.id); editModal = true;" type="button" class="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500" id="options-menu-0-button" aria-expanded="false" aria-haspopup="true">
                        <span class="sr-only">Edit</span>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <dl class="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                    <div class="flex justify-between gap-x-4 py-3">
                      <dt class="text-gray-500">Statuspunkte</dt>
                      <dd class="text-gray-700">{{pointConfig.statusPoints}}</dd>
                    </div>
                    <div class="flex justify-between gap-x-4 py-3">
                      <dt class="text-gray-500">Prämienpunkte</dt>
                      <dd class="flex items-start gap-x-2">
                        <div class="font-medium text-gray-900">{{pointConfig.prämienPoints}}</div>
                      </dd>
                    </div>
                  </dl>
                </li>
              </ul>
            </div>
          </section>
        </div>
      </main>
    </div>
    <notification class="my-4" v-if="saveMessage" type="success" title="Speichern erfolgreich" message="Die Änderungen wurden erfolgreich übernommen."/>
    <notification class="my-4" v-if="deleteMessage" type="success" title="Löschen erfolgreich" message="Der Rang wurde erfolgreich gelöscht."/>
    <notification class="my-4" v-if="deleteMessage2" type="success" title="Löschen erfolgreich" message="Die Prämie wurde erfolgreich gelöscht."/>

    <TransitionRoot as="template" :show="editModal">
      <Dialog as="div" class="relative z-10" @close="editModal = false">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>
        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <form @submit.prevent="edit()">
                <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <button type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" @click="editModal = false">
                      <span class="sr-only">Close</span>
                      <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div class="sm:flex sm:items-start">
                    <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                      <PencilSquareIcon class="h-6 w-6 text-blue-600" aria-hidden="true" />
                    </div>
                    <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">{{ actionTitle }} bearbeiten</DialogTitle>
                      <div>
                        <div class="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mb-4">
                          <div class="sm:col-span-3">
                            <div class="relative mt-2 rounded-md shadow-sm">
                              <label for="statusPoints" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Statuspunkte:</label>
                              <input v-model="statusPoints" required type="number" name="statusPoints" id="statusPoints" class="block w-full rounded-md border-0 py-1.5 pl-2 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Statuspunkte" />
                            </div>
                          </div>
                          <div class="sm:col-span-3">
                            <div class="relative mt-2 rounded-md shadow-sm">
                              <label for="prämienPoints" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Prämienpunkte:</label>
                              <input v-model="prämienPoints" required type="number" name="prämienPoints" id="prämienPoints" class="block w-full rounded-md border-0 py-1.5 pl-2 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Prämienpunkte" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button type="submit" class="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto">Speichern</button>
                    <button @click="editModal = false;" type="button"  class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Abbrechen</button>
                  </div>
                </DialogPanel>
              </form>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <TransitionRoot as="template" :show="createGoodieModal">
      <Dialog as="div" class="relative z-10" @close="createGoodieModal = false">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>
        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <form @submit.prevent="createGoodie()">
                <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <button type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" @click="createGoodieModal = false">
                      <span class="sr-only">Close</span>
                      <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div class="sm:flex sm:items-start">
                    <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                      <PencilSquareIcon class="h-6 w-6 text-blue-600" aria-hidden="true" />
                    </div>
                    <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">Neuen Rang erstellen</DialogTitle>
                      <div>
                          <div class="mt-4">
                            <div class="relative mt-2 rounded-md shadow-sm">
                              <label for="rangName" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Rang-Titel:</label>
                              <input v-model="rangName" required type="text" name="rangName" id="rangName" class="block w-full rounded-md border-0 py-1.5 pl-2 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Partygänger o.Ä...." />
                            </div>
                          </div>
                          <div class="mt-4">
                            <div class="relative mt-2 rounded-md shadow-sm">
                              <label for="pointGoal" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Benötigte Statuspunkte:</label>
                              <input v-model="pointGoal" required type="number" name="pointGoal" id="pointGoal" class="block w-full rounded-md border-0 py-1.5 pl-2 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Anzahl der Statuspunkte" />
                            </div>
                          </div>
                        <div class="mt-4">
                          <div class="relative mt-2 rounded-md shadow-sm">
                            <label for="goodies" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Vorteile / Goodies:</label>
                            <textarea v-model="goodies" name="goodies" id="goodies" rows="6" class="block w-full rounded-md border-0 py-1.5 pl-2 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Exklusive Vorteile ..."></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button type="submit" class="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto">Erstellen</button>
                    <button @click="createGoodieModal = false;" type="button"  class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Abbrechen</button>
                  </div>
                </DialogPanel>
              </form>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <TransitionRoot as="template" :show="editGoodieModal">
      <Dialog as="div" class="relative z-10" @close="editGoodieModal = false">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>
        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <form @submit.prevent="saveGoodie()">
                <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <button type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" @click="editGoodieModal = false">
                      <span class="sr-only">Close</span>
                      <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div class="sm:flex sm:items-start">
                    <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                      <PencilSquareIcon class="h-6 w-6 text-blue-600" aria-hidden="true" />
                    </div>
                    <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">Rang bearbeiten</DialogTitle>
                      <div>
                        <div class="mt-4">
                          <div class="relative mt-2 rounded-md shadow-sm">
                            <label for="rangName" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Rang-Titel:</label>
                            <input v-model="rangName" required type="text" name="rangName" id="rangName" class="block w-full rounded-md border-0 py-1.5 pl-2 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Partygänger o.Ä...." />
                          </div>
                        </div>
                        <div class="mt-4">
                          <div class="relative mt-2 rounded-md shadow-sm">
                            <label for="pointGoal" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Benötigte Statuspunkte:</label>
                            <input v-model="pointGoal" required type="number" name="pointGoal" id="pointGoal" class="block w-full rounded-md border-0 py-1.5 pl-2 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Anzahl der Statuspunkte" />
                          </div>
                        </div>
                        <div class="mt-4">
                          <div class="relative mt-2 rounded-md shadow-sm">
                            <label for="goodies" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Vorteile / Goodies:</label>
                            <textarea v-model="goodies" name="goodies" id="goodies" rows="6" class="block w-full rounded-md border-0 py-1.5 pl-2 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Exklusive Vorteile ..."></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button type="submit" class="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto">Speichern</button>
                    <button @click="editGoodieModal = false;" type="button"  class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Abbrechen</button>
                    <a @click="deleteModal = true" class="mx-3 my-2 text-gray-500 underline text-sm cursor-pointer">Rang löschen</a>
                  </div>
                </DialogPanel>
              </form>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <TransitionRoot as="template" :show="createPraemieModal">
      <Dialog as="div" class="relative z-10" @close="createPraemieModal = false">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>
        <div class="fixed inset-0 z-10 overflow-y-auto mt-10">
          <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <form @submit.prevent="addOrEditPraemie()">
                <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <button type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" @click="createPraemieModal = false">
                      <span class="sr-only">Close</span>
                      <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div class="sm:flex sm:items-start">
                    <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                      <PencilSquareIcon class="h-6 w-6 text-blue-600" aria-hidden="true" />
                    </div>
                    <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">Neue Prämie {{ this.praemieEditId ? 'bearbeiten' : 'erstellen' }}</DialogTitle>
                      <div>
                        <div class="mt-4">
                          <div class="relative mt-2 rounded-md shadow-sm">
                            <label for="praemieTitle" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Titel:</label>
                            <input v-model="praemieTitle" required type="text" name="praemieTitle" id="praemieTitle" class="block w-full rounded-md border-0 py-1.5 pl-2 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Titel...." />
                          </div>
                        </div>
                        <div class="mt-4">
                          <div class="relative mt-2 rounded-md shadow-sm">
                            <label for="praemiePrice" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Benötigte Prämiepunkte:</label>
                            <input v-model="praemiePrice" required type="number" name="praemiePrice" id="praemiePrice" class="block w-full rounded-md border-0 py-1.5 pl-2 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Anzahl der Punkte" />
                          </div>
                        </div>
                        <div class="mt-4">
                          <div class="relative mt-2 rounded-md shadow-sm">
                            <label for="praemieDescription" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Beschreibung:</label>
                            <textarea v-model="praemieDescription" name="praemieDescription" id="praemieDescription" rows="6" class="block w-full rounded-md border-0 py-1.5 pl-2 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Wofür kann diese Prämie eingelöst werden? ..."></textarea>
                          </div>
                        </div>
                        <div class="mt-2 bg-white rounded shadow p-4">
                          <RadioGroup v-model="discount.type">
                            <RadioGroupLabel class="text-base font-semibold leading-6 text-gray-900">Rabattart auswählen</RadioGroupLabel>
                            <div class="mt-4 grid grid-cols-1 gap-y-6 sm:gap-x-4">
                              <RadioGroupOption as="template" v-for="DiscountTypeList in DiscountTypeLists" :key="DiscountTypeList.id" :value="DiscountTypeList.id" v-slot="{ checked, active }">
                                <div :class="[checked ? 'border-transparent' : 'border-grey-600', active ? 'border-indigo-600 ring-2 ring-indigo-600' : '', 'relative flex cursor-pointer rounded-lg border bg-slate-50 p-4 shadow-sm focus:outline-none']">
                                  <span class="flex flex-1">
                                    <span class="flex flex-col">
                                      <RadioGroupLabel as="span" class="block text-sm font-medium text-gray-900">{{ DiscountTypeList.title }}</RadioGroupLabel>
                                      <RadioGroupDescription as="span" class="mt-1 flex items-center text-sm text-gray-500">{{ DiscountTypeList.description }}</RadioGroupDescription>
                                    </span>
                                  </span>
                                  <CheckCircleIcon :class="[!checked ? 'invisible' : '', 'h-5 w-5 text-indigo-600']" aria-hidden="true" />
                                  <span :class="[active ? 'border' : 'border-2', checked ? 'border-indigo-600' : 'border-transparent', 'pointer-events-none absolute -inset-px rounded-lg']" aria-hidden="true" />
                                </div>
                              </RadioGroupOption>
                            </div>
                          </RadioGroup>
                          <div class="mt-4">
                            <div v-if="discount.type == 'amount' || discount.type == 'percent'">
                              <div class="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mb-4">
                                <div v-if="discount.type == 'amount'" class="sm:col-span-3">
                                  <div class="relative mt-2 rounded-md shadow-sm">
                                    <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                    <span class="text-gray-500 sm:text-sm">
                                      <CurrencyEuroIcon class="h-4 w-4 text-gray-500" aria-hidden="true" />
                                    </span>
                                    </div>
                                    <label for="value" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Rabattbetrag</label>
                                    <input v-model="discount.value" type="number" step="0.01" name="value" id="value" class="block w-full rounded-md border-0 py-1.5 pl-8 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="0.00" />
                                  </div>
                                </div>
                                <div v-if="discount.type == 'percent'" class="sm:col-span-3">
                                  <div class="relative mt-2 rounded-md shadow-sm">
                                    <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                      <span class="text-gray-500 text-gray-500 sm:text-sm font-bold">
                                        %
                                      </span>
                                    </div>
                                    <label for="value" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Rabattbetrag</label>
                                    <input v-model="discount.value" type="number" name="value" id="value" class="block w-full rounded-md border-0 py-1.5 pl-8 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="10%" />
                                  </div>
                                </div>
                                <div class="sm:col-span-3">
                                  <div class="relative mt-2 rounded-md shadow-sm">
                                    <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                      <span class="text-gray-500 sm:text-sm">
                                        <CurrencyEuroIcon class="h-4 w-4 text-gray-500" aria-hidden="true" />
                                      </span>
                                    </div>
                                    <label for="minAmount" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Mindestbetrag</label>
                                    <input v-model="discount.minAmount" type="number" step="0.01" name="minAmount" id="minAmount" class="block w-full rounded-md border-0 py-1.5 pl-8 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="0.00" />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div v-if="discount.type == 'xForY'">
                              <div class="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mb-4">
                                <div class="sm:col-span-3">
                                  <div class="relative mt-2 rounded-md shadow-sm">
                                    <label for="x" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Anzahl</label>
                                    <input v-model="discount.x" type="x" name="x" id="x" class="block w-full rounded-md border-0 py-1.5 pl-4 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Anzahl" />
                                  </div>
                                </div>
                                <div class="sm:col-span-3">
                                  <div class="relative mt-2 rounded-md shadow-sm">
                                    <label for="y" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">für den Preis von</label>
                                    <input v-model="discount.y" type="y" name="y" id="y" class="block w-full rounded-md border-0 py-1.5 pl-4 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Anzahl" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <section>
                          <!--
                          <div class="mt-2 bg-white rounded shadow p-4">
                            <RadioGroup v-model="events">
                              <RadioGroupLabel class="text-base font-semibold leading-6 text-gray-900">Gültigkeit für Events</RadioGroupLabel>
                              <div class="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                                <RadioGroupOption as="template" v-for="DiscountList in DiscountLists" :key="DiscountList.id" :value="DiscountList.id" v-slot="{ checked, active }">
                                  <div :class="[checked ? 'border-transparent' : 'border-grey-600', active ? 'border-indigo-600 ring-2 ring-indigo-600' : '', 'relative flex cursor-pointer rounded-lg border bg-slate-50 p-4 shadow-sm focus:outline-none']">
                                    <span class="flex flex-1">
                                      <span class="flex flex-col">
                                        <RadioGroupLabel as="span" class="block text-sm font-medium text-gray-900">{{ DiscountList.title }}</RadioGroupLabel>
                                      </span>
                                    </span>
                                    <CheckCircleIcon :class="[!checked ? 'invisible' : '', 'h-5 w-5 text-indigo-600']" aria-hidden="true" />
                                    <span :class="[active ? 'border' : 'border-2', checked ? 'border-indigo-600' : 'border-transparent', 'pointer-events-none absolute -inset-px rounded-lg']" aria-hidden="true" />
                                  </div>
                                </RadioGroupOption>
                              </div>
                            </RadioGroup>
                          </div>
                          -->
                          <div class="mt-2 bg-white rounded shadow p-4">
                            <RadioGroup v-model="tickets">
                              <RadioGroupLabel class="text-base font-semibold leading-6 text-gray-900">Gültigkeit für Tickets</RadioGroupLabel>
                              <div class="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                                <RadioGroupOption as="template" v-for="DiscountList in DiscountLists" :key="DiscountList.id" :value="DiscountList.id" v-slot="{ checked, active }">
                                  <div :class="[checked ? 'border-transparent' : 'border-grey-600', active ? 'border-indigo-600 ring-2 ring-indigo-600' : '', 'relative flex cursor-pointer rounded-lg border bg-slate-50 p-4 shadow-sm focus:outline-none']">
                                    <span class="flex flex-1">
                                      <span class="flex flex-col">
                                        <RadioGroupLabel as="span" class="block text-sm font-medium text-gray-900">{{ DiscountList.title }}</RadioGroupLabel>
                                      </span>
                                    </span>
                                    <CheckCircleIcon :class="[!checked ? 'invisible' : '', 'h-5 w-5 text-indigo-600']" aria-hidden="true" />
                                    <span :class="[active ? 'border' : 'border-2', checked ? 'border-indigo-600' : 'border-transparent', 'pointer-events-none absolute -inset-px rounded-lg']" aria-hidden="true" />
                                  </div>
                                </RadioGroupOption>
                              </div>
                            </RadioGroup>
                          </div>
                          <div class="mt-2 bg-white rounded shadow p-4">
                            <RadioGroup v-model="lounges">
                              <RadioGroupLabel class="text-base font-semibold leading-6 text-gray-900">Gültigkeit für Lounges</RadioGroupLabel>
                              <div class="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                                <RadioGroupOption as="template" v-for="DiscountList in DiscountLists" :key="DiscountList.id" :value="DiscountList.id" v-slot="{ checked, active }">
                                  <div :class="[checked ? 'border-transparent' : 'border-grey-600', active ? 'border-indigo-600 ring-2 ring-indigo-600' : '', 'relative flex cursor-pointer rounded-lg border bg-slate-50 p-4 shadow-sm focus:outline-none']">
                                    <span class="flex flex-1">
                                      <span class="flex flex-col">
                                        <RadioGroupLabel as="span" class="block text-sm font-medium text-gray-900">{{ DiscountList.title }}</RadioGroupLabel>
                                      </span>
                                    </span>
                                    <CheckCircleIcon :class="[!checked ? 'invisible' : '', 'h-5 w-5 text-indigo-600']" aria-hidden="true" />
                                    <span :class="[active ? 'border' : 'border-2', checked ? 'border-indigo-600' : 'border-transparent', 'pointer-events-none absolute -inset-px rounded-lg']" aria-hidden="true" />
                                  </div>
                                </RadioGroupOption>
                              </div>
                            </RadioGroup>
                          </div>
                          <div class="mt-2 bg-white rounded shadow p-4">
                            <RadioGroup v-model="drinks">
                              <RadioGroupLabel class="text-base font-semibold leading-6 text-gray-900">Gültigkeit für Getränke</RadioGroupLabel>
                              <div class="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                                <RadioGroupOption as="template" v-for="DiscountList in DiscountLists" :key="DiscountList.id" :value="DiscountList.id" v-slot="{ checked, active }">
                                  <div :class="[checked ? 'border-transparent' : 'border-grey-600', active ? 'border-indigo-600 ring-2 ring-indigo-600' : '', 'relative flex cursor-pointer rounded-lg border bg-slate-50 p-4 shadow-sm focus:outline-none']">
                                    <span class="flex flex-1">
                                      <span class="flex flex-col">
                                        <RadioGroupLabel as="span" class="block text-sm font-medium text-gray-900">{{ DiscountList.title }}</RadioGroupLabel>
                                      </span>
                                    </span>
                                    <CheckCircleIcon :class="[!checked ? 'invisible' : '', 'h-5 w-5 text-indigo-600']" aria-hidden="true" />
                                    <span :class="[active ? 'border' : 'border-2', checked ? 'border-indigo-600' : 'border-transparent', 'pointer-events-none absolute -inset-px rounded-lg']" aria-hidden="true" />
                                  </div>
                                </RadioGroupOption>
                              </div>
                            </RadioGroup>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                  <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button type="submit" class="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto">{{ this.praemieEditId ? 'Speichern' : 'Erstellen' }}</button>
                    <button @click="createPraemieModal = false;" type="button"  class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Abbrechen</button>
                    <a v-if="this.praemieEditId" @click="deleteModal2 = true" class="mx-3 my-2 text-gray-500 underline text-sm cursor-pointer">Prämie löschen</a>
                  </div>
                </DialogPanel>
              </form>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <confirmModal v-if="deleteModal2" type="danger" title="Bist du dir Sicher?" message="Möchtest die Prämie wirklick löschen?" cancelButton="Abbrechen" button="Löschen" @action="deletePraemie()"/>
    <confirmModal v-if="deleteModal" type="danger" title="Bist du dir Sicher?" message="Möchtest den Rang wirklick löschen?" cancelButton="Abbrechen" button="Löschen" @action="deleteRang()"/>
  </div>
</template>

<script>
/* eslint-disable */
import {aSyncData, nl2br} from "@/modules/main";
import { ChevronLeftIcon, PhotoIcon, ChevronRightIcon, CurrencyEuroIcon, TicketIcon, QrCodeIcon, CheckCircleIcon, ClockIcon, ArrowDownIcon, ArrowUpIcon,   BriefcaseIcon, CalendarIcon, EnvelopeIcon, ChevronDownIcon, LinkIcon, MapPinIcon, PencilSquareIcon, BanknotesIcon, CursorArrowRaysIcon} from '@heroicons/vue/20/solid'
import { Switch, RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption, Menu, MenuButton, MenuItem, MenuItems, Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/vue/24/outline'
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import notification from "@/components/notifications/notification";
import alert from "@/components/alerts/alert";
import confirmModal from "@/components/modals/confirmModal";

export default {
  name: "punktesystem",
  title: "Punktesystem",
  components: {
    ChevronLeftIcon, ChevronRightIcon, TicketIcon, CurrencyEuroIcon, QrCodeIcon,
    notification,alert,RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption,
    CheckCircleIcon, ClockIcon, MapPinIcon, Switch, PencilSquareIcon, BanknotesIcon,
    ArrowDownIcon, ArrowUpIcon,   BriefcaseIcon, CalendarIcon, EnvelopeIcon, ChevronDownIcon, LinkIcon,
    Menu, MenuButton, MenuItem, MenuItems, Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot,
    ExclamationTriangleIcon, XMarkIcon, PhotoIcon, CursorArrowRaysIcon, confirmModal

  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      userPointsConfig: [],
      page: 'overview',
      days: 30,
      editModal: false,
      editId: '',
      actionTitle: '',
      statusPoints: '',
      prämienPoints: '',
      saveMessage: false,
      userPointsGoodies: [],
      editGoodieModal: false,
      rangName: '',
      goodie: '',
      pointGoal: '',
      createGoodieModal: false,
      goodies: '',
      goodieEditId: '',
      deleteMessage: false,
      deleteMessage2: false,
      deleteModal2: false,
      deleteModal: false,
      userHighscore: [],
      userPointsPraemien: [],
      praemieEditId: '',
      praemieTitle: '',
      praemieDescription: '',
      praemiePrice: '',
      createPraemieModal: false,
      code: '',
      discount: {
        type: 'amount',
        minAmount: 0,
        value: 5,
        x: 3,
        y: 2
      },
      events: 'all',
      tickets: 'all',
      lounges: 'all',
      drinks: 'all',
      DiscountTypeLists: [
        { id: 'amount', title: 'Betrag', description: 'Einen festen Betrag als Rabatt.' },
        { id: 'percent', title: 'Prozent', description: 'Einen prozentualen Betrag als Rabatt.' },
        { id: 'xForY', title: 'X für Y', description: 'Bspw. Drei zum Preis von Zwei.' },
      ],
      DiscountLists: [
        { id: 'all', title: 'Erlauben' },
        { id: 'none', title: 'Blockieren' },
      ]
    }
  },
  mounted() {
    this.config.loading = false
    this.getData()

  },
  methods: {
    nl2br(str){
      return nl2br(str)
    },
    getData(){
      aSyncData('punktesystem/getData', {
        organizerId: this.config.organizerId,
        userId: this.userId
      })
          .then(r => {
            console.log(r)
            if(r.status === 250){
              this.userPointsConfig = r.data.userPointsConfig
              this.userPointsGoodies = r.data.userPointsGoodies
              this.days = r.data.userPointsSettings.timeperiod
              this.userHighscore = r.data.userHighscore
              this.userPointsPraemien = r.data.userPointsPraemien
            }
          })
          .catch(e => console.error(e))
    },
    getById(id){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('id', id)
      this.axios.post('punktesystem/getById', fD)
          .then((response) => {
            console.log(response)
            if(response.status === 250){
              _this.editId = response.data.id
              _this.actionTitle = response.data.actionTitle
              _this.statusPoints = response.data.statusPoints
              _this.prämienPoints = response.data.prämienPoints
            }
          })
    },
    getGoodieById(id){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('id', id)
      this.axios.post('punktesystem/getGoodieById', fD)
          .then((response) => {
            console.log(response)
            if(response.status === 250){
              _this.goodieEditId = response.data.id
              _this.rangName = response.data.rangName
              _this.goodies = response.data.goodies
              _this.pointGoal = response.data.pointGoal
            }
          })
    },
    getPraemieById(id){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('id', id)
      this.axios.post('punktesystem/getPraemieById', fD)
          .then((response) => {
            console.log(response)
            if(response.status === 250){
              _this.praemieEditId = response.data.praemienData.id
              _this.praemieTitle = response.data.praemienData.title
              _this.praemieDescription = response.data.praemienData.description
              _this.praemiePrice = response.data.praemienData.price

              _this.discount = response.data.coupon.data.discount
              _this.events = response.data.coupon.data.events.type
              _this.tickets = response.data.coupon.data.tickets.type
              _this.lounges = response.data.coupon.data.lounges.type
              _this.drinks = response.data.coupon.data.drinks.type

            }
          })
    },
    saveConfig(){
      this.saveMessage = false
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('timeperiod', this.days)
      this.axios.post('punktesystem/saveConfig', fD)
          .then((r) => {
            console.log(r.status, r.data)
            if(r.status === 250){
              _this.getData()
              _this.saveMessage = true
            }
          })
    },
    createGoodie(){
      this.saveMessage = false
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('rangName', this.rangName)
      fD.append('goodies', this.goodies)
      fD.append('pointGoal', this.pointGoal)
      this.axios.post('punktesystem/createGoodie', fD)
          .then((r) => {
            console.log(r.status, r.data)
            if(r.status === 250){
              _this.getData()
              _this.saveMessage = true
              _this.createGoodieModal = false
              _this.clear()
            }
          })
    },
    saveGoodie(){
      this.saveMessage = false
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('id', this.goodieEditId)
      fD.append('rangName', this.rangName)
      fD.append('goodies', this.goodies)
      fD.append('pointGoal', this.pointGoal)
      this.axios.post('punktesystem/saveGoodie', fD)
          .then((r) => {
            console.log(r.status, r.data)
            if(r.status === 250){
              _this.getData()
              _this.saveMessage = true
              _this.editGoodieModal = false
              _this.clear()
            }
          })
    },
    clear(){
      this.rangName = ''
      this.goodies = ''
      this.pointGoal = ''
      this.praemieTitle = ''
      this.praemieDescription = ''
      this.praemiePrice = ''
      this.praemieEditId = ''
    },
    edit(){
      this.saveMessage = false
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('statusPoints', this.statusPoints)
      fD.append('praemienPoints', this.prämienPoints)
      fD.append('id', this.editId)
      this.axios.post('punktesystem/edit', fD)
          .then((r) => {
            console.log(r.status, r.data)
            if(r.status === 250){
              _this.getData()
              _this.saveMessage = true
              _this.editModal = false
            }
          })
    },
    deleteRang(){
      this.deleteMessage = false
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('id', this.goodieEditId)
      this.axios.post('punktesystem/delete', fD)
          .then((r) => {
            if(r.status === 250){
              _this.getData()
              _this.deleteMessage = true
            }
          })
    },
    deletePraemie(){
      this.deleteMessage2 = false
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('id', this.praemieEditId)
      this.axios.post('punktesystem/deletePraemie', fD)
          .then((r) => {
            console.log(r)
            if(r.status === 250){
              _this.getData()
              _this.deleteMessage2 = true
            }
          })
    },
    renderTimestampForJS(date){
      const jsTimestamp = date.split(' ')
      const jsDate = jsTimestamp[0].split('-')
      const jsTime = jsTimestamp[1].split(':')
      return jsDate[2] + '.' + jsDate[1] + '.' + jsDate[0] + ' ' + jsTime[0] + ':' + jsTime[1]
    },
    generateCode(length){
      let code = ''
      let counter = 0
      const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
      while (counter < length) {
        code += chars.charAt(Math.floor(Math.random() * chars.length))
        counter++
      }
      this.code = code
    },
    addOrEditPraemie(){
      if(!this.praemieEditId){this.generateCode(8)}
      aSyncData('punktesystem/addOrEditPraemie', {
        praemieEditId: this.praemieEditId,
        organizerId: this.config.organizerId,
        code: this.code,
        title: this.praemieTitle,
        price: this.praemiePrice,
        description: this.praemieDescription,
        extra: 'Dieser Gutschein kann nicht an andere Personen übertragen werden.',
        data: JSON.stringify({
          discount: this.discount,
          events: {
            type: this.events,
            items: ''
          },
          tickets: {
            type: this.tickets,
            items: ''
          },
          lounges: {
            type: this.lounges,
            items: ''
          },
          drinks: {
            type: this.drinks,
            items: ''
          },
          plattform: {
            type: 'everywhere',
          }
        })
      })
      .then(r => {
        if(r.status === 250){
          this.saveMessage = true
          this.createPraemieModal = false
          this.clear()
          this.getData()
        }
      })
    },

  }
}
</script>

<style>
  .disabled{
    background-color: grey !important;
  }
</style>