<template>
  <div class="mt-2 mb-4">
    <button v-for="lounge in unselectedLounges" :key="lounge.id" @click="$emit('addLounge', lounge.id)" type="button" class="mr-2 rounded-full bg-white px-3 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
      {{ lounge.title }}
    </button>
  </div>

  <section class="grid-12">
    <div class="col-sp-12 col-tb-6 row-sp-12 pad-4" v-for="lounge in lounges" :key="lounge.id">
      <div class="lg:col-start-3 lg:row-end-1 h-100">
        <div class="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5 h-100">
          <dl class="flex flex-wrap">
            <div class="flex-auto pl-6 pt-6">
              <dt class="text-xl font-semibold leading-6 text-gray-900">{{ lounge.title }}</dt>
            </div>
            <div class="flex-none self-end px-6 pt-4">
              <img :src="config.projectURL + 'assets/images/lounges/' + lounge.image + '_thumb.jpg'" class="w-20 rounded">
            </div>
          </dl>
          <div class="px-6">
            <div class="relative mt-4 rounded-md shadow-sm">
              <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <span class="text-gray-500 sm:text-sm">€</span>
              </div>
              <label for="loungePrice" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Preis (inkl. MwSt.)</label>
              <input v-model="lounge.price" type="number" step="0.01" name="loungePrice" id="loungePrice" class="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="0.00"  />
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <span class="text-gray-500 sm:text-sm" id="price-currency">EUR</span>
              </div>
            </div>
            <div class="relative mt-5 rounded-md shadow-sm">
              <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <span class="sm:text-sm">
                  <CalendarDaysIcon class="h-5 w-4 text-gray-500" aria-hidden="true" />
                </span>
              </div>
              <label for="loungeAvailableUntil" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Verfügbar bis (leerlassen bei keiner Beschränkung)</label>
              <input v-model="lounge.availableUntil" type="datetime-local" name="loungeAvailableUntil" id="loungeAvailableUntil" class="block w-full rounded-md border-0 py-1.5 pl-8 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"  />
            </div>
            <div class="relative mt-5 rounded-md shadow-sm">
              <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <span class="sm:text-sm">
                  <TagIcon class="h-5 w-4 text-gray-500" aria-hidden="true" />
                </span>
              </div>
              <label for="loungeQuantity" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Verfügbare Anzahl</label>
              <input v-model="lounge.quantity" required type="number" name="loungeQuantity" id="loungeQuantity" class="block w-full rounded-md border-0 py-1.5 pl-8 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Verfügbare Anzahl"  />
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <span class="text-gray-500 sm:text-sm" id="quantity-currency">Stück</span>
              </div>
            </div>
            <div class="relative mt-5 rounded-md shadow-sm">
              <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <span class="sm:text-sm">
                  <UserGroupIcon class="h-5 w-4 text-gray-500" aria-hidden="true" />
                </span>
              </div>
              <label for="loungeMaxGuests" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Maximale Gäste</label>
              <input v-model="lounge.maxGuests" type="number" name="loungeMaxGuests" id="loungeMaxGuests" class="block w-full rounded-md border-0 py-1.5 pl-8 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Maximale Gäste"  />
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <span class="text-gray-500 sm:text-sm" id="loungeMaxGuests-currency">Gäste</span>
              </div>
            </div>
          </div>
          <div class="mt-2 border-t border-gray-900/5 px-6 py-6">
            <a @click="$emit('removeLounge', lounge.id)" class="cursor-pointer text-sm font-semibold leading-6 text-gray-900">Lounge deaktivieren <span aria-hidden="true">&rarr;</span></a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
/* eslint-disable */
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import { CalendarDaysIcon, CreditCardIcon, UserCircleIcon, TagIcon, UserGroupIcon } from '@heroicons/vue/20/solid'

export default {
  name: "loungeSelect",
  props: {
    loungeTemplates: Array,
    lounges: Array
  },
  components: {
    CalendarDaysIcon, CreditCardIcon, UserCircleIcon, TagIcon, UserGroupIcon
  },
  emits: ['addLounge','removeLounge', 'priceLounge'],
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  computed: {
    unselectedLounges() {
      return this.loungeTemplates.filter(lT => {
        return !(this.lounges.some(l => l.id === lT.id))
      })
    }
  }
}
</script>

<style scoped>

</style>