<template>
  <div class="bg-neutral-50 h-full">
    <div class="lg:pl-72">
      <main class="py-4">
        <div class="px-4">
          <div class="mb-2">
            <div>
              <nav class="hidden sm:flex" aria-label="Breadcrumb">
                <ol role="list" class="flex items-center space-x-4">
                  <li>
                    <div class="flex">
                      <a href="/clubmanager" class="text-sm font-medium text-gray-500 hover:text-gray-700">Übersicht</a>
                    </div>
                  </li>
                </ol>
              </nav>
            </div>
            <div class="mt-2 md:flex md:items-center md:justify-between">
              <div class="min-w-0 flex-1">
                <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">Clubmanager</h2>
              </div>
              <div class="mt-4 flex flex-shrink-0 md:ml-4 md:mt-0">
              </div>
            </div>
          </div>
          <div class="grid-12">
            <div class="col-sp-12 col-sd-8 row-sp-12">
              <div class="bg-white p-4 rounded shadow">
                <div class="grid-12">
                  <div class="col-sp-12 col-sd-4 row-sp-12 p-4">
                    <img :src="logo" class="w-full mb-2" v-if="this.logo && !this.logoNew">
                    <cropper-upload @cropped="c => {this.logoNew = c;}" :ratio="1.77" ref="cropper">
                      <button class="w-full rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                        Logo {{ organizerId > 0 ? 'ändern' : 'hochladen' }}
                      </button>
                    </cropper-upload>
                  </div>
                  <div class="col-sp-12 col-sd-8 row-sp-12">
                    <div class="relative mt-4">
                      <label for="organizerName" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Clubname</label>
                      <input v-model="organizerName" type="text" name="organizerName" id="organizerName" required class="px-2 text-l block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6" placeholder="Clubname" />
                    </div>
                    <div class="relative mt-4">
                      <label for="aboutText" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Beschreibung</label>
                      <textarea v-model="aboutText" rows="4"  name="aboutText" id="aboutText" class="px-2 text-l block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6" placeholder="Beschreibung"></textarea>
                    </div>
                    <div class="mt-4 flex flex-row-reverse">
                      <button @click="saveClubDetails" class="relative inline-flex items-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600" :class="{disabled: organizerName.length < 1 || saveMessage}" :disabled="organizerName.length < 1 || saveMessage">
                        {{ saveMessage ? 'Gespeichert' : (organizerId > 0 ? 'Änderungen Speichern' : 'Club erstellen') }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sp-12 col-sd-4 row-sp-12 ml-2">
              <ul role="list" class="divide-y divide-gray-100 overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl">
                <li>
                  <div class="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
                    <div class="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
                      <div class="ml-4 mt-2">
                        <h3 class="text-base font-normal text-xl leading-6 text-gray-900">Meine Clubs</h3>
                      </div>
                      <div class="ml-4 mt-2 flex-shrink-0">
                        <button @click="createClub" class="relative inline-flex items-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
                          Neuen Club erstellen
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
                <clubItems v-for="citems in config.organizer" :club-items="citems" :key="citems.id" @update="getClubDetails" />
              </ul>
            </div>
          </div>
        </div>
      </main>
    </div>
    <notification class="my-4" v-if="saveMessage" type="success" title="Speichern erfolgreich" message="Die Änderungen wurden erfolgreich übernommen."/>
  </div>
</template>

<script>


import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import clubItems from "@/components/profil/club";
import CropperUpload from "@/components/images/cropperUpload";
import notification from "@/components/notifications/notification";

export default {
  name: "Clubmanager",
  title: "Clubmanager",
  components: {
    CropperUpload,
    clubItems: clubItems,
    notification
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      clubItems: [],
      categoryOptionItems: [],
      organizerName: '',
      aboutText: '',
      labels: [],
      logo: '',
      logoNew: false,
      organizerId: 0,
      saveMessage: false,
    }
  },
  mounted() {
    this.config.loading = false
    this.getClubDetails()
    this.getCategoryOptionItems()
  },
  methods: {
    getClubDetails(){
      this.$refs.cropper.reset()
      this.saveMessage = false
      this.logoNew = false
      this.organizerId = this.config.getCurrentOrganizer.id
      this.organizerName = this.config.getCurrentOrganizer.organizerName
      this.aboutText = this.config.getCurrentOrganizer.aboutText
      this.labels = this.config.getCurrentOrganizer.labels.split(',')
      this.logo = this.config.projectURL + 'assets/images/eventhoster/'+this.config.getCurrentOrganizer.logo
    },
    createClub(){
      this.$refs.cropper.reset()
      this.saveMessage = false
      this.logoNew = false
      this.organizerId = -1
      this.organizerName = ''
      this.aboutText = ''
      this.labels = []
      this.logo = false
    },
    saveClubDetails(){
      this.saveMessage = false
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('id', this.organizerId)
      fD.append('organizerName', this.organizerName)
      fD.append('aboutText', this.aboutText)
      fD.append('labels', this.labels)
      fD.append('logo', this.logoNew)
      this.axios.post('clubmanager/saveClubDetails', fD, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
          .then((r) => {
            console.log(r.status, r.data)
            if(r.status === 250){
              _this.config.organizer = r.data.organizer
              _this.config.organizerId = r.data.organizerId
              _this.getClubDetails()
              _this.saveMessage = true
            }
          })
    },
    getCategoryOptionItems(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('clubmanager/getCategoryOptionItems', fD)
          .then((response) => {
            if(response.status === 250){
              _this.categoryOptionItems = response.data
            }
          })
          .catch(e => {console.error(e)})
    },
    getEventItems(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('dashboard/geteventItems', fD)
          .then((response) => {
            if(response.status === 250){
              _this.eventItems = response.data
            }
          })
    }
  }
}
</script>

<style>
  .disabled{
    background-color: grey !important;
  }
</style>