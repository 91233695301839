<template>
  <div class="bg-neutral-50 h-full">
    <div class="lg:pl-72">
      <main class="py-4">
        <div class="px-4">
          <div class="mb-2">
            <div>
              <nav class="hidden sm:flex" aria-label="Breadcrumb">
                <ol role="list" class="flex items-center space-x-4">
                  <li>
                    <div class="flex">
                      <a href="/admanager" class="text-sm font-medium text-gray-500 hover:text-gray-700">Übersicht</a>
                    </div>
                  </li>
                </ol>
              </nav>
            </div>
            <div class="mt-2 md:flex md:items-center md:justify-between">
              <div class="min-w-0 flex-1">
                <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">Werbemanager</h2>
              </div>
              <div class="mt-4 flex flex-shrink-0 md:ml-4 md:mt-0">
                <button @click="clear(); createAdModal = true" type="button" class="ml-3 inline-flex items-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
                  Neue Werbeanzeige erstellen
                </button>
              </div>
            </div>
          </div>
          <section class="mb-4">
            <alert v-if="infoAdblocker" type="info" title="Adblocker deaktivieren!" message="Bitte deaktiviere deinen Adblocker für diese Seite, da sonst ggf. die Funktionen nicht genutzt werden können."/>
          </section>
          <section>
            <div class="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
              <h3 class="text-base font-semibold leading-6 text-gray-900 mb-4">Aktuelle Kampagnen</h3>
              <ul role="list" class="grid grid-cols-1 gap-6 sm:grid-cols-2">
                <li class="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow" v-for="ad in current" :key="ad.id">
                  <div>
                    <img class="w-full rounded" :src="config.projectURL + 'assets/images/ads/'+ad.img+''">
                    <h3 class="px-6 mt-3 text-xl font-bold">[#{{ad.id}}] {{ad.name}}</h3>
                    <p class="px-6 mt-1 text-sm text-gray-500">von {{ renderTimestampForJS(ad.startTime) }} - {{ renderTimestampForJS(ad.endTime) }} Uhr</p>
                  </div>
                  <div class="flex w-full items-center justify-between space-x-6 p-6">
                    <div class="flex-1">
                      <div class="flex items-center space-x-3">
                        <h3 class="text-sm font-bold text-gray-900">Platzierung: <span class="font-medium text-gray-700">{{ ad.title }} ({{ad.route}})</span></h3>
                      </div>
                      <div class="flex items-center space-x-3">
                        <h3 class="text-sm font-bold text-gray-900">Verlinkung: <a :href="ad.href" target="_blank" class="font-medium text-gray-700">{{ad.href}}</a></h3>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="-mt-px flex divide-x divide-gray-200">
                      <div class="-ml-px flex w-0 flex-1">
                        <a @click="getById(ad.id);editId = ad.id; editModal = true" class="relative inline-flex w-0 flex-1 items-center hover:bg-slate-100 cursor-pointer justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5 text-gray-400">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                          </svg>
                          Bearbeiten
                        </a>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="mt-4 border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
              <h3 class="text-base font-semibold leading-6 text-gray-900 mb-4">Zukünftige Kampagnen</h3>
              <ul role="list" class="grid grid-cols-1 gap-6 sm:grid-cols-2">
                <li class="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow" v-for="ad in future" :key="ad.id">
                  <div>
                    <img class="w-full rounded" :src="config.projectURL + 'assets/images/ads/'+ad.img+''">
                    <h3 class="px-6 mt-3 text-xl font-bold">[#{{ad.id}}] {{ad.name}}</h3>
                    <p class="px-6 mt-1 text-sm text-gray-500">von {{ renderTimestampForJS(ad.startTime) }} - {{ renderTimestampForJS(ad.endTime) }} Uhr</p>
                  </div>
                  <div class="flex w-full items-center justify-between space-x-6 p-6">
                    <div class="flex-1">
                      <div class="flex items-center space-x-3">
                        <h3 class="text-sm font-bold text-gray-900">Platzierung: <span class="font-medium text-gray-700">{{ ad.title }} ({{ad.route}})</span></h3>
                      </div>
                      <div class="flex items-center space-x-3">
                        <h3 class="text-sm font-bold text-gray-900">Verlinkung: <a :href="ad.href" target="_blank" class="font-medium text-gray-700">{{ad.href}}</a></h3>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="-mt-px flex divide-x divide-gray-200">
                      <div class="-ml-px flex w-0 flex-1">
                        <a @click="getById(ad.id);editId = ad.id; editModal = true" class="relative inline-flex w-0 flex-1 items-center hover:bg-slate-100 cursor-pointer justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5 text-gray-400">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                          </svg>
                          Bearbeiten
                        </a>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="mt-4 border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
              <h3 class="text-base font-semibold leading-6 text-gray-900 mb-4">Vergangende Kampagnen</h3>
              <ul role="list" class="grid grid-cols-1 gap-6 sm:grid-cols-2">
                <li class="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow" v-for="ad in past" :key="ad.id">
                  <div>
                    <img class="w-full rounded" :src="config.projectURL + 'assets/images/ads/'+ad.img+''">
                    <h3 class="px-6 mt-3 text-xl font-bold">[#{{ad.id}}] {{ad.name}}</h3>
                    <p class="px-6 mt-1 text-sm text-gray-500">von {{ renderTimestampForJS(ad.startTime) }} - {{ renderTimestampForJS(ad.endTime) }} Uhr</p>
                  </div>
                  <div class="flex w-full items-center justify-between space-x-6 p-6">
                    <div class="flex-1">
                      <div class="flex items-center space-x-3">
                        <h3 class="text-sm font-bold text-gray-900">Platzierung: <span class="font-medium text-gray-700">{{ ad.title }} ({{ad.route}})</span></h3>
                      </div>
                      <div class="flex items-center space-x-3">
                        <h3 class="text-sm font-bold text-gray-900">Verlinkung: <a :href="ad.href" target="_blank" class="font-medium text-gray-700">{{ad.href}}</a></h3>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="-mt-px flex divide-x divide-gray-200">
                      <div class="-ml-px flex w-0 flex-1">
                        <a @click="getById(ad.id);editId = ad.id; editModal = true" class="relative inline-flex w-0 flex-1 items-center hover:bg-slate-100 cursor-pointer justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5 text-gray-400">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                          </svg>
                          Bearbeiten
                        </a>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </section>
        </div>
      </main>
    </div>
    <notification class="my-4" v-if="saveMessage" type="success" title="Speichern erfolgreich" message="Die Änderungen wurden erfolgreich übernommen."/>
    <notification class="my-4" v-if="deleteMessage" type="success" title="Löschen erfolgreich" message="Die Werbeanzeige wurde erfolgreich gelöscht."/>
    <TransitionRoot as="template" :show="createAdModal">
      <Dialog as="div" class="relative z-10" @close="createAdModal = false">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>
        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <form @submit.prevent="createAd()">
                <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <button type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" @click="createAdModal = false">
                      <span class="sr-only">Close</span>
                      <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div class="sm:flex sm:items-start">
                    <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                      <CursorArrowRaysIcon class="h-6 w-6 text-blue-600" aria-hidden="true" />
                    </div>
                    <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">Werbeanzeige erstellen</DialogTitle>
                      <div>
                        <div class="relative mt-4">
                          <label for="location" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Platzierung des Werbebanners</label>
                          <select id="location" v-model="location" required class="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                            <option v-for="Adloc in adLocations" :key="Adloc.id" :value="Adloc.id">
                              {{Adloc.title}} ({{Adloc.route}})
                            </option>
                          </select>
                        </div>
                        <div class="relative mt-4">
                          <label for="name" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Bezeichung des Werbebanners</label>
                          <input id="name" v-model="name" required type="text" maxlength="40" placeholder="Bezeichung des Werbebanners" class="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                        </div>
                        <div class="relative mt-4">
                          <label for="url" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Ziel-URL</label>
                          <input id="url" v-model="url" required type="text" placeholder="https://ziel-der-werbung.de" class="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                        </div>
                        <div class="relative mt-4">
                          <label for="banner" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Werbebanner (.png oder .jpeg) [320 x 50 px]</label>
                          <input id="banner" required type="file" ref="fileInput" @change="handleImageUpload" style="display: none">
                          <button type="button"  @click="openFileInput" class="w-full rounded bg-white px-2 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">Datei auswählen</button>
                        </div>
                        <div class="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mb-4">
                          <div class="sm:col-span-3">
                            <div class="relative mt-2 rounded-md shadow-sm">
                              <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                              <span class="text-gray-500 sm:text-sm">
                                <ClockIcon class="h-6 w-3 text-gray-500" aria-hidden="true" />
                              </span>
                              </div>
                              <label for="adStart" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Start der Ausspielung</label>
                              <input v-model="adStart" required type="datetime-local" name="adStart" id="adStart" class="block w-full rounded-md border-0 py-1.5 pl-7 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Verkaufsstart" />
                            </div>
                          </div>
                          <div class="sm:col-span-3">
                            <div class="relative mt-2 rounded-md shadow-sm">
                              <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                              <span class="text-gray-500 sm:text-sm">
                                <ClockIcon class="h-6 w-3 text-gray-500" aria-hidden="true" />
                              </span>
                              </div>
                              <label for="adEnd" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Ende der Ausspielung</label>
                              <input v-model="adEnd" required type="datetime-local" name="adEnd" id="adEnd" class="block w-full rounded-md border-0 py-1.5 pl-7 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Verkaufsende" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button type="submit" class="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto">Erstellen</button>
                    <button @click="createAdModal = false;" type="button"  class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Abbrechen</button>
                  </div>
                </DialogPanel>
              </form>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <TransitionRoot as="template" :show="editModal">
      <Dialog as="div" class="relative z-10" @close="editModal = false">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>
        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <form @submit.prevent="edit()">
                <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <button type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" @click="editModal = false">
                      <span class="sr-only">Close</span>
                      <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div class="sm:flex sm:items-start">
                    <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                      <CursorArrowRaysIcon class="h-6 w-6 text-blue-600" aria-hidden="true" />
                    </div>
                    <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">Werbeanzeige bearbeiten</DialogTitle>
                      <div>
                        <div class="relative mt-4">
                          <label for="location" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Platzierung des Werbebanners</label>
                          <select id="location" v-model="location" required class="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                            <option v-for="Adloc in adLocations" :key="Adloc.id" :value="Adloc.id">
                              {{Adloc.title}} ({{Adloc.route}})
                            </option>
                          </select>
                        </div>
                        <div class="relative mt-4">
                          <label for="name" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Platzierung des Werbebanners</label>
                          <input id="name" v-model="name" required type="text" maxlength="40" placeholder="Bezeichung des Werbebanners" class="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                        </div>
                        <div class="relative mt-4">
                          <label for="url" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Ziel-URL</label>
                          <input id="url" v-model="url" required type="text" placeholder="https://ziel-der-werbung.de" class="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                        </div>
                        <div class="relative mt-4">
                          <label for="banner" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Werbebanner (.png oder .jpeg) [320 x 50 px]</label>
                          <input id="banner" type="file" ref="fileInput" @change="handleImageUpload" style="display: none">
                          <button type="button"  @click="openFileInput" class="w-full rounded bg-white px-2 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">Datei auswählen</button>
                        </div>
                        <div class="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mb-4">
                          <div class="sm:col-span-3">
                            <div class="relative mt-2 rounded-md shadow-sm">
                              <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                              <span class="text-gray-500 sm:text-sm">
                                <ClockIcon class="h-6 w-3 text-gray-500" aria-hidden="true" />
                              </span>
                              </div>
                              <label for="adStart" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Start der Ausspielung</label>
                              <input v-model="adStart" required type="datetime-local" name="adStart" id="adStart" class="block w-full rounded-md border-0 py-1.5 pl-7 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Verkaufsstart" />
                            </div>
                          </div>
                          <div class="sm:col-span-3">
                            <div class="relative mt-2 rounded-md shadow-sm">
                              <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                              <span class="text-gray-500 sm:text-sm">
                                <ClockIcon class="h-6 w-3 text-gray-500" aria-hidden="true" />
                              </span>
                              </div>
                              <label for="adEnd" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Ende der Ausspielung</label>
                              <input v-model="adEnd" required type="datetime-local" name="adEnd" id="adEnd" class="block w-full rounded-md border-0 py-1.5 pl-7 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Verkaufsende" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button type="submit" class="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto">Speichern</button>
                    <button @click="editModal = false;" type="button"  class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Abbrechen</button>
                    <a @click="deleteModal = true" class="mx-3 my-2 text-gray-500 underline text-sm cursor-pointer">Werbenanzeige löschen</a>
                  </div>
                </DialogPanel>
              </form>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <confirmModal v-if="deleteModal" type="danger" title="Bist du dir Sicher?" message="Möchtest du die Werbeanzeige wirklick löschen?" cancelButton="Abbrechen" button="Löschen" @action="deleteAd()"/>
  </div>
</template>

<script>
/* eslint-disable */
import { ChevronLeftIcon, PhotoIcon, ChevronRightIcon, CurrencyEuroIcon, TicketIcon, QrCodeIcon, CheckCircleIcon, ClockIcon, ArrowDownIcon, ArrowUpIcon,   BriefcaseIcon, CalendarIcon, EnvelopeIcon, ChevronDownIcon, LinkIcon, MapPinIcon, PencilSquareIcon, BanknotesIcon, CursorArrowRaysIcon} from '@heroicons/vue/20/solid'
import { Switch, RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption, Menu, MenuButton, MenuItem, MenuItems, Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/vue/24/outline'
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import notification from "@/components/notifications/notification";
import alert from "@/components/alerts/alert";
import confirmModal from "@/components/modals/confirmModal";

import {aSyncData} from "@/modules/main";

export default {
  name: "admanager",
  title: "AdManager",
  components: {
    ChevronLeftIcon, ChevronRightIcon, TicketIcon, CurrencyEuroIcon, QrCodeIcon,
    notification,alert,RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption,
    CheckCircleIcon, ClockIcon, MapPinIcon, Switch, PencilSquareIcon, BanknotesIcon,
    ArrowDownIcon, ArrowUpIcon,   BriefcaseIcon, CalendarIcon, EnvelopeIcon, ChevronDownIcon, LinkIcon,
    Menu, MenuButton, MenuItem, MenuItems, Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot,
    ExclamationTriangleIcon, XMarkIcon, PhotoIcon, CursorArrowRaysIcon, confirmModal

  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      saveMessage: false,
      createAdModal: false,
      deleteMessage: false,
      adStart: '',
      adEnd: '',
      name: '',
      url: '',
      location: '',
      banner: null,
      adLocations: [],
      current: [],
      future: [],
      past: [],
      editId: '',
      editModal: false,
      deleteModal: false,
      infoAdblocker: true,
    }
  },
  mounted() {
    this.config.loading = false
    this.getAdLocations()
    this.getData()

  },
  methods: {
    getData(){
      aSyncData('admanager/getData', {
        organizerId: this.config.organizerId,
      })
          .then(r => {
            if(r.status === 250){
              this.current = r.data.current
              this.future = r.data.future
              this.past = r.data.past
            }
          })
          .catch(e => console.error(e))
    },
    clear(){
      this.adStart = ''
      this.adEnd = ''
      this.name = ''
      this.url = ''
      this.location = ''
      this.banner = null
    },
    deleteAd(){
      this.deleteMessage = false
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('id', this.editId)
      this.axios.post('admanager/delete', fD)
          .then((r) => {
            if(r.status === 250){
              _this.getData()
              _this.deleteMessage = true
            }
          })
    },
    getAdLocations(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('admanager/getAdLocations', fD)
          .then((response) => {
            if(response.status === 250){
              _this.adLocations = response.data
            }
          })
    },
    getById(id){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('id', id)
      this.axios.post('admanager/getById', fD)
          .then((response) => {
            console.log(response)
            if(response.status === 250){
              _this.editId = response.data.id
              _this.adStart = response.data.startTime
              _this.adEnd = response.data.endTime
              _this.name = response.data.name
              _this.url = response.data.href
              _this.location = response.data.page
            }
          })
    },
    renderTimestampForJS(date){
      const jsTimestamp = date.split(' ')
      const jsDate = jsTimestamp[0].split('-')
      const jsTime = jsTimestamp[1].split(':')
      return jsDate[2] + '.' + jsDate[1] + '.' + jsDate[0] + ' ' + jsTime[0] + ':' + jsTime[1]
    },
    edit(){
      this.saveMessage = false
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('adStart', this.adStart)
      fD.append('adEnd', this.adEnd)
      fD.append('name', this.name)
      fD.append('url', this.url)
      fD.append('location', this.location)
      fD.append('banner', this.banner)
      fD.append('id', this.editId)
      this.axios.post('admanager/edit', fD, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
          .then((r) => {
            console.log(r.status, r.data)
            if(r.status === 250){
              _this.getData()
              _this.saveMessage = true
              _this.editModal = false
              _this.clear()
            }
          })
    },
    openFileInput() {
      this.$refs.fileInput.click();
    },
    handleImageUpload(event) {
      this.banner = event.target.files[0];
      if (this.banner) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imageUrl = e.target.result;
        };
        reader.readAsDataURL(this.banner);
      }
    },
    createAd(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('adStart', this.adStart)
      fD.append('adEnd', this.adEnd)
      fD.append('name', this.name)
      fD.append('url', this.url)
      fD.append('location', this.location)
      fD.append('banner', this.banner)
      console.log(this.banner)
      this.axios.post('admanager/create', fD, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
          .then((r) => {
            console.log(r.status, r.data)
            if(r.status === 250){
              _this.getData()
              _this.saveMessage = true
              _this.createAdModal = false
              _this.clear()
            }
          })
    },
  }
}
</script>

<style>
  .disabled{
    background-color: grey !important;
  }
</style>