<template>
  <div class="col-sp-12 col-sd-6 row-sp-12 p-2">
    <div class="lg:col-start-3 lg:row-end-1 h-100">
      <div class="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5 h-100">
        <div class="px-6 flex flex-row-reverse pt-6">
          <button @click="$emit('editLounge', lounge)" class="rounded bg-blue-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
            Lounge bearbeiten
          </button>
        </div>
        <dl class="flex flex-wrap">
          <div class="flex-auto pl-6 pt-6">
            <dt class="text-xl font-semibold leading-6 text-gray-900">{{ lounge.title }}</dt>
          </div>
          <div class="flex-none self-end px-6 pt-4">
            <img :src="config.projectURL + 'assets/images/lounges/' + lounge.image + '_thumb.jpg'" class="w-20 h-20 rounded">
          </div>
        </dl>
        <div class="px-6">
          <b class="text-sm text-gray-700 font-bold">Beschreibung:</b>
          <p class="text-sm text-gray-500 h-20 min-h-full	" v-html="nl2br(lounge.description)"></p>
          <ul role="list" class="mt-4">
            <li class="col-span-1 flex rounded-md shadow-sm mt-3">
              <div class="bg-blue-700 flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white">
                <CurrencyEuroIcon class="h-5 w-5 text-white" aria-hidden="true" />
              </div>
              <div class="flex items-center justify-between truncate w-full rounded-r-md border-b border-r border-t border-gray-200 bg-white">
                <div class="px-4 py-2 text-sm">
                  <a class="font-bold text-gray-900 hover:text-gray-600">
                    Preis
                  </a>
                  <p class="text-gray-500">
                    {{lounge.price}}€
                  </p>
                </div>
              </div>
            </li>
            <li class="col-span-1 flex rounded-md shadow-sm mt-3">
              <div class="bg-blue-700 flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white">
                <UserGroupIcon class="h-5 w-5 text-white" aria-hidden="true" />
              </div>
              <div class="flex items-center justify-between truncate w-full rounded-r-md border-b border-r border-t border-gray-200 bg-white">
                <div class="px-4 py-2 text-sm">
                  <a class="font-bold text-gray-900 hover:text-gray-600">
                    Maximale Gäste
                  </a>
                  <p class="text-gray-500">
                    {{lounge.maxGuests}} Personen
                  </p>
                </div>
              </div>
            </li>
            <li class="col-span-1 flex rounded-md shadow-sm mt-3">
              <div class="bg-blue-700 flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white">
                <TagIcon class="h-5 w-5 text-white" aria-hidden="true" />
              </div>
              <div class="flex items-center justify-between truncate w-full rounded-r-md border-b border-r border-t border-gray-200 bg-white">
                <div class="px-4 py-2 text-sm">
                  <a class="font-bold text-gray-900 hover:text-gray-600">
                    Verfügbare Anzahl
                  </a>
                  <p class="text-gray-500">
                    {{lounge.quantity}} Stück
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="mt-2 border-t border-gray-900/5 px-6 py-6">
          <a @click="deleteModal = !deleteModal" class="cursor-pointer text-sm font-semibold leading-6 text-gray-900">Lounge löschen <span aria-hidden="true">&rarr;</span></a>
        </div>
      </div>
    </div>
  </div>
  <TransitionRoot as="template" :show="deleteModal">
    <Dialog as="div" class="relative z-10" @close="deleteModal = false">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
              <form @submit.prevent="$emit('deleteLounge', lounge.id)">
                <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" @click="deleteModal = false">
                    <span class="sr-only">Close</span>
                    <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div class="sm:flex sm:items-start">
                  <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                    <svg class="h-6 w-6 text-blue-600" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                      <path d="M29 18.18v-2.84A3.35 3.35 0 0 0 25.66 12h-7.32A3.34 3.34 0 0 0 16 13a3.34 3.34 0 0 0-2.34-1H6.34A3.35 3.35 0 0 0 3 15.34v2.84A3 3 0 0 0 1 21v7a1 1 0 0 0 1 1h1v1a1 1 0 0 0 2 0v-1h22v1a1 1 0 0 0 2 0v-1h1a1 1 0 0 0 1-1v-7a3 3 0 0 0-2-2.82ZM18.34 14h7.32A1.34 1.34 0 0 1 27 15.34v2.83h-.07a2.86 2.86 0 0 0-.43.21l-.15.08a2.68 2.68 0 0 0-.49.4l-.09.12a2.88 2.88 0 0 0-.3.39l-.11.19a2.2 2.2 0 0 0-.17.39.61.61 0 0 0-.05.12h-.08a2.39 2.39 0 0 0-.66-.1H17v-4.63A1.34 1.34 0 0 1 18.34 14ZM7 22.6a.6.6 0 0 1 .6-.6H15v1H7Zm10-.6h7.4a.6.6 0 0 1 .6.6v.4h-8ZM5 15.34A1.34 1.34 0 0 1 6.34 14h7.32A1.34 1.34 0 0 1 15 15.34V20H7.6a2.84 2.84 0 0 0-.66.09h-.07v-.11a3.89 3.89 0 0 0-.19-.42l-.09-.17a2.93 2.93 0 0 0-.41-.52 2.74 2.74 0 0 0-.5-.4s-.12-.06-.17-.1a3.49 3.49 0 0 0-.39-.19h-.07ZM3 21a1 1 0 0 1 1-1 1 1 0 0 1 .71.31A.93.93 0 0 1 5 21v6H3Zm4 4h18v2H7Zm22 2h-2v-6a1 1 0 0 1 1-1 1 1 0 0 1 .71.31.93.93 0 0 1 .29.69Z" data-name="Layer 2" fill="#2564eb" class="fill-000000"></path>
                    </svg>
                  </div>
                  <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">Lounge löschen</DialogTitle>
                    <div class="mt-2">
                      <p class="text-sm text-gray-500 block w-100">
                        Bist du sicher, dass du die Lounge löschen willst?
                      </p>
                    </div>
                  </div>
                </div>
                <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button type="submit" class="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto">Löschen</button>
                  <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto" @click="deleteModal = false">Abbrechen</button>
                </div>
              </form>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
/* eslint-disable */
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import {nl2br} from "@/modules/main";
import { UserGroupIcon, TagIcon, CurrencyEuroIcon, TicketIcon } from '@heroicons/vue/20/solid'
import notification from "@/components/notifications/notification";
import alert from "@/components/alerts/alert";
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/vue/24/outline'
export default {
  name: "lounge",
  props: {
    lounge: JSON
  },
  components:{
    TicketIcon, CurrencyEuroIcon, UserGroupIcon, TagIcon,
    notification,alert, Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot,
    ExclamationTriangleIcon, XMarkIcon
  },
  emits: ['editLounge', 'deleteLounge'],
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      deleteModal: false,
    }
  },
  methods: {
    renderTimestampForJS(date){
      const jsTimestamp = date.split(' ')
      const jsDate = jsTimestamp[0].split('-')
      const jsTime = jsTimestamp[1].split(':')
      return jsDate[2] + '.' + jsDate[1] + '.' + jsDate[0] + ' ' + jsTime[0] + ':' + jsTime[1]
    },
    nl2br(str){
      return nl2br(str)
    },
  }

}
</script>

<style scoped>

</style>