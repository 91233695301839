<template>
  <div v-if="step === 1">
    <div class="px-4">
      <div class="mb-2">
        <div>
          <nav class="hidden sm:flex" aria-label="Breadcrumb">
            <ol role="list" class="flex items-center space-x-4">
              <li>
                <div class="flex">
                  <a href="/notificationservice" class="text-sm font-medium text-gray-500 hover:text-gray-700">Übersicht</a>
                </div>
              </li>
            </ol>
          </nav>
        </div>
        <div class="mt-2 md:flex md:items-center md:justify-between">
          <div class="min-w-0 flex-1">
            <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">Promotion Service</h2>
          </div>
          <div class="mt-4 flex flex-shrink-0 md:ml-4 md:mt-0">
            <button @click="$router.push({name: 'NotificationService Birthday'})" type="button" class="h-10 items-center justify-center rounded-lg px-6 text-sm sm:flex bg-blue-600 font-semibold text-white hover:bg-blue-700">
              Geburtstags-Benachrichtigung
            </button>
          </div>
        </div>
      </div>
      <section class="mt-4 bg-white shadow rounded p-4">
        <div class="border-b border-gray-200 bg-white py-5">
          <h3 class="text-xl font-semibold leading-6 text-gray-900">Filter:</h3>
        </div>
        <div class="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mb-4">
          <div class="sm:col-span-3">
            <SwitchGroup as="div" class="flex items-center">
              <Switch v-model="filterNameBool" :class="[filterNameBool ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2']">
                <span :class="[filterNameBool ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']">
                  <span :class="[filterNameBool ? 'opacity-0 duration-100 ease-out' : 'opacity-100 duration-200 ease-in', 'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity']" aria-hidden="true">
                    <svg class="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                      <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </span>
                  <span :class="[filterNameBool ? 'opacity-100 duration-200 ease-in' : 'opacity-0 duration-100 ease-out', 'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity']" aria-hidden="true">
                    <svg class="h-3 w-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
                      <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                    </svg>
                  </span>
                </span>
              </Switch>
              <SwitchLabel as="span" class="ml-3 text-sm">
                <span class="font-medium text-gray-900">Nach Namen</span>
              </SwitchLabel>
            </SwitchGroup>
            <div v-if="filterNameBool" class="relative mt-4">
              <label for="filterName" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Name</label>
              <input v-model="filterName" placeholder="Max Mustermann" type="text" name="filterName" id="filterName" class="px-2 text-l block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6" />
            </div>
          </div>
          <div class="sm:col-span-3">
            <SwitchGroup as="div" class="flex items-center">
              <Switch v-model="filterGeoBool" :class="[filterGeoBool ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2']">
                <span :class="[filterGeoBool ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']">
                  <span :class="[filterGeoBool ? 'opacity-0 duration-100 ease-out' : 'opacity-100 duration-200 ease-in', 'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity']" aria-hidden="true">
                    <svg class="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                      <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </span>
                  <span :class="[filterGeoBool ? 'opacity-100 duration-200 ease-in' : 'opacity-0 duration-100 ease-out', 'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity']" aria-hidden="true">
                    <svg class="h-3 w-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
                      <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                    </svg>
                  </span>
                </span>
              </Switch>
              <SwitchLabel as="span" class="ml-3 text-sm">
                <span class="font-medium text-gray-900">Nach Entfernung</span>
              </SwitchLabel>
            </SwitchGroup>
            <div v-if="filterGeoBool" class="relative mt-4">
              <label for="city_search" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Entfernung</label>
              <SimpleTypeahead
                  style="width: 100%; max-width: 100%; margin: unset;"
                  id="city_search"
                  placeholder="Suche nach Orten..."
                  :items="cities"
                  :min-input-length="1"
                  :item-projection="item => {return item.name}"
                  @select-item="item => {this.filterCity = item}"
                  @on-input="loadCities"
                  class="px-2 text-l block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6"
              />
              <Slider v-model="filterDistance" :format="sliderFormat" class="mt-10 mx-2 slider-yellow" :lazy="lazy" :min="0" :max="500" v-if="filterCity.id" :merge="50" />
            </div>
          </div>
          <div class="sm:col-span-3">
            <SwitchGroup as="div" class="flex items-center">
              <Switch v-model="filterEventBool" :class="[filterEventBool ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2']">
                <span :class="[filterEventBool ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']">
                  <span :class="[filterEventBool ? 'opacity-0 duration-100 ease-out' : 'opacity-100 duration-200 ease-in', 'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity']" aria-hidden="true">
                    <svg class="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                      <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </span>
                  <span :class="[filterEventBool ? 'opacity-100 duration-200 ease-in' : 'opacity-0 duration-100 ease-out', 'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity']" aria-hidden="true">
                    <svg class="h-3 w-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
                      <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                    </svg>
                  </span>
                </span>
              </Switch>
              <SwitchLabel as="span" class="ml-3 text-sm">
                <span class="font-medium text-gray-900">Nach Event</span>
              </SwitchLabel>
            </SwitchGroup>
            <div v-if="filterEventBool" class="relative mt-4">
              <label for="filterName" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Event auswählen</label>
              <select v-model="filterEvent" name="filterEvent" id="filterEvent" class="px-2 text-l block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6">
                <option :value="0">
                  Bitte Event wählen
                </option>
                <option v-for="event in events" :value="event.id" :key="event.id">
                  {{ dateString(event.startTime) + ' - ' + event.title }}
                </option>
              </select>
            </div>
          </div>
          <div class="sm:col-span-3">
            <SwitchGroup as="div" class="flex items-center">
              <Switch v-model="filterAverageSalesBool" :class="[filterAverageSalesBool ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2']">
                <span :class="[filterAverageSalesBool ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']">
                  <span :class="[filterAverageSalesBool ? 'opacity-0 duration-100 ease-out' : 'opacity-100 duration-200 ease-in', 'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity']" aria-hidden="true">
                    <svg class="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                      <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </span>
                  <span :class="[filterAverageSalesBool ? 'opacity-100 duration-200 ease-in' : 'opacity-0 duration-100 ease-out', 'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity']" aria-hidden="true">
                    <svg class="h-3 w-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
                      <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                    </svg>
                  </span>
                </span>
              </Switch>
              <SwitchLabel as="span" class="ml-3 text-sm">
                <span class="font-medium text-gray-900">Nach durchschnittlichen Umsatz</span>
              </SwitchLabel>
            </SwitchGroup>
            <div v-if="filterAverageSalesBool" class="relative mt-4">
              <Slider v-model="filterAverageSales" :format="formatSliderToolTip" class="mt-10 mx-2 slider-yellow" :min="0" :max="filterSliderMaxValue" :merge="15" />
            </div>
          </div>
          <div class="sm:col-span-3">
            <SwitchGroup as="div" class="flex items-center">
              <Switch v-model="filterSalesBool" :class="[filterSalesBool ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2']">
                <span :class="[filterSalesBool ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']">
                  <span :class="[filterSalesBool ? 'opacity-0 duration-100 ease-out' : 'opacity-100 duration-200 ease-in', 'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity']" aria-hidden="true">
                    <svg class="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                      <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </span>
                  <span :class="[filterSalesBool ? 'opacity-100 duration-200 ease-in' : 'opacity-0 duration-100 ease-out', 'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity']" aria-hidden="true">
                    <svg class="h-3 w-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
                      <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                    </svg>
                  </span>
                </span>
              </Switch>
              <SwitchLabel as="span" class="ml-3 text-sm">
                <span class="font-medium text-gray-900">Nach Gesamtumsatz</span>
              </SwitchLabel>
            </SwitchGroup>
            <div v-if="filterSalesBool" class="relative mt-4">
              <Slider v-model="filterSales" :format="formatSliderToolTipBig" class="mt-10 mx-2 slider-yellow" :min="0" :max="filterSliderMaxValue * 4" :merge="60" />
            </div>
          </div>
        </div>
      </section>
      <section class="mt-4 flex flex-row-reverse">
        <button @click="step = 2" :disabled="!chosenOnes.length" :class="{disabled: !chosenOnes.length}" type="button" class="ml-4 h-10 items-center justify-center rounded-lg px-6 text-sm sm:flex bg-blue-600 font-semibold text-white hover:bg-blue-700">
          Weiter
        </button>
      </section>
      <section class="mt-4 bg-white shadow rounded p-4">
        <div class="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mb-4">
          <div class="sm:col-span-3">
            <div class="border-b border-gray-200 bg-white py-5">
              <div class="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
                <div class="ml-4 mt-2">
                  <h3 class="text-xl font-semibold leading-6 text-gray-900">Nutzerliste:</h3>
                </div>
                <div class="ml-4 mt-2 flex-shrink-0">
                  <button @click="choseAll" :disabled="!checkChoseAll" :class="{disabled: !checkChoseAll}" type="button" class="mr-2 relative inline-flex items-center rounded-md bg-blue-600 px-1 py-1 text-xs font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
                    <PlusIcon class="h-5 w-5 text-white" aria-hidden="true" />
                  </button>
                  <button @click="removeAll" :disabled="!checkRemoveAll" :class="{disabled: !checkRemoveAll}" type="button" class="relative inline-flex items-center rounded-md bg-blue-600 px-1 py-1 text-xs font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
                    <MinusIcon class="h-5 w-5 text-white" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
            <div>
              <transition-group name="list" tag="ul">
                <li v-for="customerEntry in customerSelection.slice(0, 250)" :key="customerEntry.userId" @click="choseOne(customerEntry)" :class="{alreadyIn: chosenOnes.some(c => c.userId === customerEntry.userId )}" style="cursor: pointer;">
                  <div class="grid-12">
                    <div class="col-sp-8 row-sp-12 pad-4">
                      {{ (customerEntry.firstname + ' ' + customerEntry.lastname).length > 30 ? (customerEntry.firstname + ' ' + customerEntry.lastname).substring(0,25)+"..." : customerEntry.firstname + ' ' + customerEntry.lastname }}
                    </div>
                    <div class="col-sp-4 row-sp-12 pad-4 t-right">
                      <div class="tooltip">
                        <span class="tooltiptext tooltip-top">Benachrichtigung via E-Mail{{ customerEntry.mail ? '' : ' nicht' }} möglich</span>
                        <img src="assets/images/icons/mail.png" class="mailPush" :class="{available: customerEntry.mail}">
                      </div>
                      <div class="tooltip">
                        <span class="tooltiptext tooltip-top">Benachrichtigung via Push{{ customerEntry.push ? '' : ' nicht' }} möglich</span>
                        <img src="assets/images/icons/push.png" class="mailPush" :class="{available: customerEntry.push}">
                      </div>
                    </div>
                  </div>
                </li>
              </transition-group>
            </div>
          </div>
          <div class="sm:col-span-3">
            <div class="border-b border-gray-200 bg-white py-5">
              <div class="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
                <div class="ml-4 mt-2">
                  <h3 class="text-xl font-semibold leading-6 text-gray-900">Ausgewählte Nutzer:</h3>
                </div>
                <div class="ml-4 mt-2 flex-shrink-0">
                  <button @click="chosenOnes = []" :disabled="!chosenOnes.length" :class="{disabled: !chosenOnes.length}" type="button" class="relative inline-flex items-center rounded-md bg-blue-600 px-1 py-1 text-xs font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
                    <MinusIcon class="h-5 w-5 text-white" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
            <div>
              <transition-group name="list" tag="ul">
                <li v-for="chosenOne in chosenOnes" :key="chosenOne.userId" @click="removeOne(chosenOne.userId)" :class="{inCustomerList: customerSelection.some(s => s.userId === chosenOne.userId )}" style="cursor: pointer;">
                  <div class="grid-12">
                    <div class="col-sp-8 row-sp-12 pad-4">
                      <!--:class="{inCustomerList: customerSelection.some(s => s.userId === chosenOne.userId )}"-->
                      {{ (chosenOne.firstname + ' ' + chosenOne.lastname).length > 30 ? (chosenOne.firstname + ' ' + chosenOne.lastname).substring(0,25)+"..." : chosenOne.firstname + ' ' + chosenOne.lastname }}
                    </div>
                    <div class="col-sp-4 row-sp-12 pad-4 t-right">
                      <div class="tooltip">
                        <span class="tooltiptext tooltip-top">Benachrichtigung via E-Mail{{ chosenOne.mail ? '' : ' nicht' }} möglich</span>
                        <img src="assets/images/icons/mail.png" class="mailPush" :class="{available: chosenOne.mail}">
                      </div>
                      <div class="tooltip">
                        <span class="tooltiptext tooltip-top">Benachrichtigung via Push{{ chosenOne.push ? '' : ' nicht' }} möglich</span>
                        <img src="assets/images/icons/push.png" class="mailPush" :class="{available: chosenOne.push}">
                      </div>
                    </div>
                  </div>
                </li>
              </transition-group>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
  <div v-else-if="step === 2">
    <div class="px-4">
      <div class="mb-2">
        <div>
          <nav class="sm:hidden" aria-label="Back">
            <router-link to="/notificationservice" class="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700">
              <ChevronLeftIcon class="-ml-1 mr-1 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
              Zurück
            </router-link>
          </nav>
          <nav class="hidden sm:flex" aria-label="Breadcrumb">
            <ol role="list" class="flex items-center space-x-4">
              <li>
                <div class="flex">
                  <a href="/notificationservice" class="text-sm font-medium text-gray-500 hover:text-gray-700">Übersicht</a>
                </div>
              </li>
              <li>
                <div class="flex items-center">
                  <ChevronRightIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                  <a class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">Notification erstellen</a>
                </div>
              </li>
            </ol>
          </nav>
        </div>
        <div class="mt-2 md:flex md:items-center md:justify-between">
          <div class="min-w-0 flex-1">
            <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">Promotion Service</h2>
          </div>
          <div class="mt-4 flex flex-shrink-0 md:ml-4 md:mt-0">
          </div>
        </div>
      </div>
    </div>
    <section class="px-4 mt-4">
      <div class="bg-white p-4 rounded shadow">
        <div class="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mb-4">
          <div class="sm:col-span-3">
            <SwitchGroup as="div" class="flex items-center justify-between">
              <span class="flex flex-grow flex-col">
                <SwitchLabel as="span" class="text-sm font-medium leading-6 text-gray-900" passive>E-Mail Benachrichtigung & Coupons</SwitchLabel>
                <SwitchDescription as="span" class="text-sm text-gray-500">{{ chosenOnes.reduce((counter, chosenOne) => {if(chosenOne.mail) counter++; return counter}, 0) }} Nutzer verfügbar</SwitchDescription>
              </span>
              <Switch v-model="mailBool" :class="[mailBool ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2']">
                <span :class="[mailBool ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']">
                  <span :class="[mailBool ? 'opacity-0 duration-100 ease-out' : 'opacity-100 duration-200 ease-in', 'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity']" aria-hidden="true">
                    <svg class="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                      <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </span>
                  <span :class="[mailBool ? 'opacity-100 duration-200 ease-in' : 'opacity-0 duration-100 ease-out', 'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity']" aria-hidden="true">
                    <svg class="h-3 w-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
                      <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                    </svg>
                  </span>
                </span>
              </Switch>
            </SwitchGroup>

          </div>
          <div class="sm:col-span-3">
            <SwitchGroup as="div" class="flex items-center justify-between">
              <span class="flex flex-grow flex-col">
                <SwitchLabel as="span" class="text-sm font-medium leading-6 text-gray-900" passive>Push-Nachricht</SwitchLabel>
                <SwitchDescription as="span" class="text-sm text-gray-500">{{ chosenOnes.reduce((counter, chosenOne) => {if(chosenOne.push) counter++; return counter}, 0) }} Nutzer verfügbar</SwitchDescription>
              </span>
              <Switch v-model="pushBool" :class="[pushBool ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2']">
                <span :class="[pushBool ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']">
                  <span :class="[pushBool ? 'opacity-0 duration-100 ease-out' : 'opacity-100 duration-200 ease-in', 'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity']" aria-hidden="true">
                    <svg class="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                      <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </span>
                  <span :class="[pushBool ? 'opacity-100 duration-200 ease-in' : 'opacity-0 duration-100 ease-out', 'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity']" aria-hidden="true">
                    <svg class="h-3 w-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
                      <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                    </svg>
                  </span>
                </span>
              </Switch>
            </SwitchGroup>

          </div>
        </div>
      </div>
      <div class="mt-4 bg-white p-4 rounded shadow">
        <div class="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mb-4">
          <div class="sm:col-span-3">
            <div v-if="mailBool">
              <SwitchGroup as="div" class="flex items-center">
                <Switch v-model="couponsOnly" :class="[couponsOnly ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2']">
                <span :class="[couponsOnly ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']">
                  <span :class="[couponsOnly ? 'opacity-0 duration-100 ease-out' : 'opacity-100 duration-200 ease-in', 'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity']" aria-hidden="true">
                    <svg class="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                      <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </span>
                  <span :class="[couponsOnly ? 'opacity-100 duration-200 ease-in' : 'opacity-0 duration-100 ease-out', 'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity']" aria-hidden="true">
                    <svg class="h-3 w-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
                      <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                    </svg>
                  </span>
                </span>
                </Switch>
                <SwitchLabel as="span" class="ml-3 text-sm">
                  <span class="font-medium text-gray-900">Nur Coupons zuweisen</span>
                </SwitchLabel>
              </SwitchGroup>
              <div v-if="couponsOnly">
                <div class="border-b border-gray-200 bg-white py-5">
                  <h3 class="text-xl font-semibold leading-6 text-gray-900">Coupon auswählen</h3>
                </div>
              </div>
              <div v-else>
                <div class="border-b border-gray-200 bg-white py-5">
                  <h3 class="text-xl font-semibold leading-6 text-gray-900">E-Mail Konfigurieren</h3>
                </div>
                <div class="relative mt-4">
                  <label for="mailTemplate" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">E-Mail-Design <span>*</span></label>
                  <select v-model="mailTemplate" name="mailTemplate" id="mailTemplate" class="px-2 text-sm block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6">
                    <option v-for="template in mailTemplates" :key="template.id" :value="template.id">
                      {{ template.name }}
                    </option>
                  </select>
                </div>
                <div class="relative mt-4">
                  <label for="mailSubject" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Betreff <span>*</span></label>
                  <input v-model="mailSubject" type="text" name="mailSubject" id="mailSubject" placeholder="Betreff" required class="px-2 text-sm block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6" />
                </div>
                <div class="relative mt-4">
                  <label for="mailGreeting" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Begrüßung <span>*</span></label>
                  <input v-model="mailGreeting" type="text" name="mailGreeting" id="mailGreeting" placeholder="Hey / Hallo o.Ä." required class="px-2 text-sm block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6" />
                </div>
                <div class="relative mt-4">
                  <label for="mailBody" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Inhalt <span>*</span></label>
                  <textarea v-model="mailBody" name="mailBody" id="mailBody" rows="4" required placeholder="Inhalt" class="px-2 text-sm block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6"></textarea>
                </div>
                <div class="relative mt-4">
                  <label for="mailEnding" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Abschieds / Grußformel <span>*</span></label>
                  <textarea v-model="mailEnding" name="mailEnding" id="mailEnding" required rows="4" placeholder="Viel Spaß auf der Party" class="px-2 text-sm block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6"></textarea>
                </div>
                <div class="relative mt-4">
                  <label for="mailAttachment" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Anhang</label>
                  <input type="file" ref="mailAttachment" @change="onFileChange" accept="application/pdf,image/*" name="mailAttachment" id="mailAttachment" class="px-2 text-sm block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6" />
                  <button v-if="mailAttachment" @click="$refs.mailAttachment.value = null; mailAttachment = null" class="rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                    Datei löschen
                  </button>
                </div>
                <div class="relative mt-8">
                  <label for="mailEvents" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Events auswählen</label>
                  <select v-model="mailEvents" multiple name="mailEvents" id="mailEvents" class="px-2 text-sm block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6">
                    <option v-for="event in events" :value="event.id" :key="event.id">
                      {{ dateString(event.startTime) + ' - ' + event.title }}
                    </option>
                  </select>
                  <p class="text-grey-300 text-xs">Mehrfachauswahl möglich: (Strg / Command) gedrückt halten.</p>
                </div>
              </div>
              <div class="relative mt-8">
                <label for="mailCoupons" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Coupon auswählen</label>
                <select v-model="mailCoupons" multiple name="mailCoupons" id="mailCoupons" class="px-2 text-sm block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6">
                  <option v-for="coupon in coupons" :key="coupon.id" :value="coupon.id">
                    {{ coupon.title + ' - ' + coupon.code }}
                  </option>
                </select>
                <p class="text-grey-300 text-xs">Mehrfachauswahl möglich: (Strg / Command) gedrückt halten.</p>
              </div>
              <div v-if="!couponsOnly" class="mt-4">
                <button @click="mailPreview" :disabled="!checkPreview" :class="{disabled: !checkPreview}" type="button" class="rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">E-Mail Vorschau anzeigen</button>
              </div>
            </div>
          </div>
          <div class="sm:col-span-3">
            <div v-if="pushBool">
              <div class="border-b border-gray-200 bg-white py-5">
                <h3 class="text-xl font-semibold leading-6 text-gray-900">Push-Nachricht konfigurieren</h3>
              </div>
              <div class="relative mt-4">
                <label for="pushTitle" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Titel <span>*</span></label>
                <input v-model="pushTitle" type="text" name="pushTitle" id="pushTitle" maxlength="64" placeholder="Titel (max. 64 Zeichen)" required class="px-2 text-sm block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6" />
              </div>
              <div class="relative mt-4">
                <label for="pushBody" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Inhalt <span>*</span></label>
                <textarea v-model="pushBody" rows="3" name="pushBody" id="pushBody" maxlength="256" placeholder="Inhalt (max. 256 Zeichen)" required class="px-2 text-sm block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6"></textarea>
              </div>
              <div class="relative mt-4">
                <label for="pushLink" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Link</label>
                <input v-model="pushLink" type="text" name="pushLink" id="pushLink" placeholder="https://www.zeillink.de" class="px-2 text-sm block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-8 flex flex-row-reverse">
        <button @click="send" :disabled="!checkSend" :class="{disabled: !checkSend}" type="button" class="rounded-md bg-blue-600 px-3 py-2 text-l font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
          Senden
        </button>
        <button @click="step = 1" type="submit" class="mr-2 rounded-md bg-gray-700 px-3 py-2 text-l font-semibold text-white shadow-sm hover:bg-gray-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600">
          Abbrechen
        </button>
      </div>
    </section>
  </div>
  <TransitionRoot as="template" :show="mailPreviewModal">
    <Dialog as="div" class="relative z-10" @close="mailPreviewModal = false">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
              <div>
                <div class="mt-3 text-center sm:mt-5">
                  <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">E-Mail Vorschau</DialogTitle>
                  <div class="mt-2">
                    <div v-html="mailPreviewHTML" />
                  </div>
                </div>
              </div>
              <div class="mt-5 sm:mt-6">
                <button type="button" class="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600" @click="mailPreviewModal = false">Vorschau schließen</button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
  <notification class="my-4" v-if="sendStatus" type="success" title="Das hat geklappt!" message="Deine Nachrichten wurden versendet."/>
  <notification class="my-4" v-if="this.$route.query.save == 'success'" type="success" title="Speichern erfolgreich" message="Die Änderungen wurden erfolgreich übernommen."/>
</template>

<script>
/* eslint-disable */
import {aSyncData, calcDistance, stringToDate} from "@/modules/main"
import {authStore} from "@/store/authStore"
import {configStore} from "@/store/configStore"
import {userStore} from "@/store/userStore"
import Slider from '@vueform/slider'
import SimpleTypeahead from "vue3-simple-typeahead";
import { PlusIcon, MinusIcon, ChevronLeftIcon, ChevronRightIcon, CheckIcon } from '@heroicons/vue/24/outline'
import { Switch, SwitchDescription, SwitchGroup, SwitchLabel, Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import notification from "@/components/notifications/notification";

export default {
  name: "NotificationServiceManual",
  components: {
    Slider,
    SimpleTypeahead,
    Switch, SwitchGroup, SwitchLabel, SwitchDescription,
    PlusIcon, MinusIcon,
    ChevronLeftIcon, ChevronRightIcon, CheckIcon,
    Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot,notification
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return{
      step: 1,
      lazy: true,
      filterSliderMaxValue: 251,
      filterNameBool: false,
      filterName: '',
      filterEventBool: false,
      filterEvent: 0,
      filterSalesByEventBool: false,
      filterSalesByEvent: [0, 10000],
      filterAverageSalesBool: false,
      filterAverageSales: [0, 10000],
      filterSalesBool: false,
      filterSales: [0, 10000],
      filterGeoBool: false,
      filterCitySearchString: '',
      filterCity: {},
      filterDistance: [0, 50],
      sliderFormat: {suffix: 'km', decimal: 0},
      cities: [],
      events: [],
      coupons: [],
      geoPositions: [],
      sales: [],
      customer: [],
      chosenOnes: [],
      couponsOnly: false,
      mailPreviewModal: false,
      mailPreviewHTML: '',
      mailBool: false,
      mailTemplate: 1,
      mailTemplates: [],
      mailEvents: [],
      mailCoupons: [],
      mailSubject: '',
      mailGreeting: '',
      mailBody: '',
      mailEnding: '',
      mailAttachment: null,
      pushBool: false,
      pushTitle: '',
      pushBody: '',
      pushLink: this.config.projectURLApp,
      maxListCount: 200,
      sendStatus: false
    }
  },
  computed: {
    customerSelection(){
      const _this = this
      let unique = []
      let ok = true

      let candidates = this.customer.filter(c => {
        if(unique.includes(c.userId) || unique.length >= this.maxListCount) return false

        //filter by search string
        if(this.filterNameBool && !(c.firstname + ' ' + c.lastname).toLowerCase().includes(this.filterName.toLowerCase())) return false

        //filter by event
        if(this.filterEventBool && this.filterEvent && c.eventId !== this.filterEvent) return false

        //filter by total sales
        if(this.filterSalesBool && (this.filterSales[0] > 0 || this.filterSales[1] !== this.filterSliderMaxValue * 4) && this.sales.has(c.userId) && (this.sales.get(c.userId).sales < _this.filterSales[0] || (this.sales.get(c.userId).sales > _this.filterSales[1] && _this.filterSales[1] < _this.filterSliderMaxValue * 4))) return false

        //filter by average sales
        if(this.filterAverageSalesBool && (this.filterAverageSales[0] > 0 || this.filterAverageSales[1] !== this.filterSliderMaxValue) && this.sales.has(c.userId) && (this.sales.get(c.userId).average < _this.filterAverageSales[0] || (this.sales.get(c.userId).average > _this.filterAverageSales[1] && _this.filterAverageSales[1] < _this.filterSliderMaxValue))) return false

        //filter by event sales
        if(this.filterSalesByEventBool && this.filterEvent && (this.filterSalesByEvent[0] !== 0 || this.filterSalesByEvent[1] !== this.filterSliderMaxValue) && (c.sales < _this.filterSalesByEvent[0] || (c.sales > _this.filterSalesByEvent[1] && _this.filterSalesByEvent[1] < _this.filterSliderMaxValue))) return false


        //filter by distance
        if(this.filterGeoBool && this.filterCity.id){
            try {
              ok = !_this.geoPositions.filter(gP => {
                return gP.userId === c.userId
              }).some(c => {
                let distance = calcDistance(c.lat, c.lon, _this.filterCity.lat, _this.filterCity.lon)
                return distance >= _this.filterDistance[0] && distance <= _this.filterDistance[1]
              })
            }catch (e) {
              return false
            }
        }
        if(ok) unique.push(c.userId)
        return ok
      })

      //remove double entries
      //return [...new Map(candidates.map(c => [c.userId, c])).values()]
      return candidates
    },
    checkChoseAll(){
      return this.customerSelection.some(s => !this.chosenOnes.some(c => c.userId === s.userId))
    },
    checkRemoveAll(){
      return this.customerSelection.some(s => this.chosenOnes.some(c => c.userId === s.userId))
    },
    checkSend(){
      return (this.mailBool || this.pushBool) && (!this.mailBool || (this.mailSubject.length && this.mailGreeting.length && this.mailBody.length && this.mailEnding.length && !this.couponsOnly) || (this.couponsOnly && this.mailCoupons.length)) && (!this.pushBool || (this.pushTitle.length && this.pushBody.length))
    },
    checkPreview(){
      return this.mailGreeting.length && this.mailBody.length && this.mailEnding.length
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init(){
      const _this = this
      aSyncData('notificationService/getData', {organizerId: this.config.organizerId})
          .then(r => {
            if(r.status === 250){
              _this.events = r.data.events
              _this.coupons = r.data.coupons
              _this.mailTemplates = r.data.mailTemplates
              _this.geoPositions = r.data.geoPositions
              _this.sales = new Map(r.data.sales.map(s => [s.userId, s]))
              _this.customer = r.data.customer
              _this.lazy = r.data.customer.length > 1000
            }
          })
    },
    dateString(eventDate){
      let timestampArr = eventDate.split(" ");
      let date = timestampArr[0];
      let time = timestampArr[1];
      const dateArr = date.split("-");
      const timeArr = time.split(":");
      return dateArr[2]+'.'+dateArr[1]+'.'+dateArr[0];
    },
    formatSliderToolTip(position){
        return position >= this.filterSliderMaxValue ? '&infin;' : Math.round(position) + '€'
    },
    formatSliderToolTipBig(position){
      return position >= this.filterSliderMaxValue * 4 ? '&infin;' : Math.round(position) + '€'
    },
    choseOne(chosenOne){
      /*
      if(!chosenOne.push && !chosenOne.mail){
        alert('Weder E-Mail noch Push möglich')
        return
      }
      */

      if(!this.chosenOnes.some(e => e.userId === chosenOne.userId)) {
        this.chosenOnes.push(chosenOne)
      }else{
        this.removeOne(chosenOne.userId)
      }
    },
    choseAll(){
      this.customerSelection.forEach(c => {
        if((c.push || c.mail) && !this.chosenOnes.some(e => e.userId === c.userId)) this.chosenOnes.push(c)
      })
    },
    removeOne(id){
      this.chosenOnes = this.chosenOnes.filter(e => e.userId !== id)
    },
    removeAll(){
      const _this = this
      this.chosenOnes = this.chosenOnes.filter(e => !_this.customerSelection.some(c => c.userId === e.userId))
    },
    loadCities(event){
      const _this = this
      this.filterCitySearchString = event.input
      aSyncData('events/cities', {search: event.input})
          .then(r => {
            if(r.status === 250 && _this.filterCitySearchString === event.input) _this.cities = r.data
          })
          .catch(e => {console.error(e)})
    },
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length)
        return;
      this.mailAttachment = files[0]
    },
    genPush(){
      this.pushBool = true
      this.pushTitle = 'Neue Gutscheine!'
      this.pushBody = 'Wir haben neue Gutscheine für dich.'
      this.pushLink = this.config.projectURLApp + 'coupons'
    },
    mailPreview(){
      const _this = this
      aSyncData('notificationService/mailPreview', {
        organizerId: this.config.organizerId,
        mailTemplate: this.mailTemplate,
        mailGreeting: this.mailGreeting,
        mailBody: this.mailBody,
        mailEnding: this.mailEnding,
        mailEvents: JSON.stringify(this.mailEvents),
        mailCoupons: JSON.stringify(this.mailCoupons)
      })
          .then(r => {
            if(r.status === 250){
              _this.mailPreviewHTML = r.data
              _this.mailPreviewModal = true
            }else{
              console.log(r)
            }
          })
          .catch(e => {
            console.log(e)
          })
    },
    send(){
      this.sendStatus = false
      const _this = this
      aSyncData('notificationService/send', {
        organizerId: this.config.organizerId,
        chosenOnes: JSON.stringify(this.chosenOnes),
        couponsOnly: this.couponsOnly,
        mailBool: this.mailBool,
        pushBool: this.pushBool,
        mailSubject: this.mailSubject,
        mailTemplate: this.mailTemplate,
        mailGreeting: this.mailGreeting,
        mailBody: this.mailBody,
        mailEnding: this.mailEnding,
        mailEvents: JSON.stringify(this.mailEvents),
        mailCoupons: JSON.stringify(this.mailCoupons),
        mailAttachment: this.mailAttachment,
        pushTitle: this.pushTitle,
        pushBody: this.pushBody,
        pushLink: this.pushLink,
      },{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
          .then(r => {
            console.log(r)
            _this.step = 1
            _this.sendStatus = true
          })
          .catch(e => {
            console.log(e)
          })
    }
  }
}
</script>

<style scoped>
@import "@vueform/slider/themes/default.css";

.slider-yellow {
  --slider-connect-bg: #2068ff;
  --slider-tooltip-bg: #2068ff;
  --slider-handle-ring-color: rgba(32, 104, 255, 0.19);
}
.tooltip img{
  width: 32px;
}
</style>