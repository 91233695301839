<template>
  <div class="bg-neutral-50 h-full">
    <div class="lg:pl-72">
      <main class="py-4">
        <div class="px-4">
          <div class="mb-2">
            <div>
              <nav class="hidden sm:flex" aria-label="Breadcrumb">
                <ol role="list" class="flex items-center space-x-4">
                  <li>
                    <div class="flex">
                      <a href="/feedback" class="text-sm font-medium text-gray-500 hover:text-gray-700">Übersicht</a>
                    </div>
                  </li>
                </ol>
              </nav>
            </div>
            <div class="mt-2 md:flex md:items-center md:justify-between">
              <div class="min-w-0 flex-1">
                <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">Feedback</h2>
              </div>
              <div class="mt-4 flex flex-shrink-0 md:ml-4 md:mt-0">
              </div>
            </div>
          </div>
          <section class="mt-8">
            <ul role="list" class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
              <li class="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow" v-for="feedback in feedbacks" :key="feedback.id">
                <div>
                  <img class="w-full rounded" :src="config.projectURL + 'assets/images/events/'+feedback.image+'.jpg'">
                  <h3 class="px-6 mt-3 text-xl font-bold">{{feedback.title}} - {{renderDateForJS(feedback.startTime)}}</h3>
                </div>
                <div class="flex w-full items-center justify-between space-x-6 p-6">
                  <div class="flex-1">
                    <div class="flex items-center space-x-3">
                      <h3 class="text-sm font-bold text-gray-900">{{ feedback.firstname }} {{ feedback.lastname }}:</h3>
                    </div>
                    <p class="mt-1 text-sm text-gray-500">{{ feedback.description }}</p>
                    <p class="mt-1 text-xs text-gray-500">Feedback von: {{renderTimestampForJS(feedback.timestamp)}} Uhr</p>
                  </div>
                  <img class="h-10 w-10 flex-shrink-0 rounded-full bg-gray-300" :src="config.projectURL + 'assets/images/users/' + feedback.avatar" alt="">
                </div>
                <div>
                  <div class="-mt-px flex divide-x divide-gray-200">
                    <div class="flex w-0 flex-1">
                      <a :href="'/customerInfo/'+feedback.userId" class="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5 text-gray-400">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
                        </svg>
                        Nutzer-Infos
                      </a>
                    </div>
                  </div>
                  <div class="-mt-px flex divide-x divide-gray-200">
                    <div class="flex w-0 flex-1">
                      <a :href="'mailto:'+feedback.email" class="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900">
                        <svg class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path d="M3 4a2 2 0 00-2 2v1.161l8.441 4.221a1.25 1.25 0 001.118 0L19 7.162V6a2 2 0 00-2-2H3z" />
                          <path d="M19 8.839l-7.77 3.885a2.75 2.75 0 01-2.46 0L1 8.839V14a2 2 0 002 2h14a2 2 0 002-2V8.839z" />
                        </svg>
                        {{ feedback.email }}
                      </a>
                    </div>
                  </div>
                  <div class="-mt-px flex divide-x divide-gray-200">
                    <div class="-ml-px flex w-0 flex-1">
                      <a :href="'tel:'+feedback.phone" class="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900">
                        <svg class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M2 3.5A1.5 1.5 0 013.5 2h1.148a1.5 1.5 0 011.465 1.175l.716 3.223a1.5 1.5 0 01-1.052 1.767l-.933.267c-.41.117-.643.555-.48.95a11.542 11.542 0 006.254 6.254c.395.163.833-.07.95-.48l.267-.933a1.5 1.5 0 011.767-1.052l3.223.716A1.5 1.5 0 0118 15.352V16.5a1.5 1.5 0 01-1.5 1.5H15c-1.149 0-2.263-.15-3.326-.43A13.022 13.022 0 012.43 8.326 13.019 13.019 0 012 5V3.5z" clip-rule="evenodd" />
                        </svg>
                        {{ feedback.phone }}
                      </a>
                    </div>
                  </div>
                </div>
              </li>

              <!-- More people... -->
            </ul>
          </section>
        </div>
      </main>
    </div>
  </div>
</template>

<script>

import { XCircleIcon, CheckCircleIcon,TrashIcon } from '@heroicons/vue/20/solid'
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import notification from "@/components/notifications/notification";
import {aSyncData} from "@/modules/main";

export default {
  name: "feedback",
  title: "Feedback",
  components: {
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      feedbacks: [],

    }
  },
  mounted() {
    this.config.loading = false
    this.getData()

  },
  methods: {
    getData(){
      aSyncData('feedback/getData', {
        organizerId: this.config.organizerId,
        userId: this.userId
      })
          .then(r => {
            console.log(r)
            if(r.status === 250){
              this.feedbacks = r.data.feedbacks
            }
          })
          .catch(e => console.error(e))
    },

    renderTimestampForJS(date){
      const jsTimestamp = date.split(' ')
      const jsDate = jsTimestamp[0].split('-')
      const jsTime = jsTimestamp[1].split(':')
      return jsDate[2] + '.' + jsDate[1] + '.' + jsDate[0] + ' ' + jsTime[0] + ':' + jsTime[1]
    },
    renderDateForJS(date){
      const jsTimestamp = date.split(' ')
      const jsDate = jsTimestamp[0].split('-')
      return jsDate[2] + '.' + jsDate[1] + '.' + jsDate[0]
    },

  }
}
</script>

<style>
  .disabled{
    background-color: grey !important;
  }
</style>