<template>
  <TransitionRoot as="template" :show="true">
    <Dialog as="div" class="relative z-10" @close="$emit('closeNewSlotModal')">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <form @submit.prevent="insertSlot()">
              <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" @click="$emit('closeNewSlotModal')">
                    <span class="sr-only">Close</span>
                    <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div class="sm:flex sm:items-start">
                  <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ClockIcon class="h-6 w-6 text-blue-600" aria-hidden="true" />
                  </div>
                  <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">Schicht erstellen</DialogTitle>
                    <div class="mt-2">
                      <div class="relative mt-4">
                        <label for="job" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Schichtart auswählen</label>
                        <select required v-model="job" name="job" id="job" class="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" >
                          <option :value="7" selected>
                            Büroschichten
                          </option>
                          <option :value="9">
                            Event-Schichten
                          </option>
                          <option :value="6">
                            DJ-Bookings
                          </option>
                          <option :value="8">
                            Free Bookings
                          </option>
                        </select>
                      </div>
                      <div v-if="job === 9" class="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mb-4">
                        <div class="sm:col-span-3">
                          <div class="relative mt-2 rounded-md shadow-sm">
                            <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                              <span class="text-gray-500 sm:text-sm">
                                <ClockIcon class="h-6 w-3 text-gray-500" aria-hidden="true" />
                              </span>
                            </div>
                            <label for="driveStart" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Abfahrt</label>
                            <input v-model="driveStart" required type="datetime-local" name="driveStart" id="driveStart" class="block w-full rounded-md border-0 py-1.5 pl-7 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"  />
                          </div>
                        </div>
                        <div class="sm:col-span-3">
                          <div class="relative mt-2 rounded-md shadow-sm">
                            <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                              <span class="text-gray-500 sm:text-sm">
                                <ClockIcon class="h-6 w-3 text-gray-500" aria-hidden="true" />
                              </span>
                            </div>
                            <label for="driveEnd" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Ankunft</label>
                            <input v-model="driveEnd" required type="datetime-local" name="driveEnd" id="driveEnd" class="block w-full rounded-md border-0 py-1.5 pl-7 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                          </div>
                        </div>
                      </div>
                      <div v-if="job !== 7" class="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mb-4">
                        <div class="sm:col-span-3">
                          <div class="relative mt-4">
                            <label for="eventPlz" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Event-PLZ</label>
                            <input id="eventPlz" v-model="eventPlz" required type="number" placeholder="Event-PLZ" class="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                          </div>
                        </div>
                        <div class="sm:col-span-3">
                          <div class="relative mt-4">
                            <label for="eventCity" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Event-Ort</label>
                            <input id="eventCity" v-model="eventCity" required type="text" placeholder="Event-Ort" class="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                          </div>
                        </div>
                      </div>
                      <div v-if="job === 6 || job === 8" class="relative mt-4">
                        <label for="clubname" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Clubname</label>
                        <input id="clubname" v-model="clubname" required type="text" placeholder="Clubname" class="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                      </div>
                      <div class="relative mt-4" v-if="job === 6">
                        <label for="music" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Musikrichtung</label>
                        <input required v-model="music" placeholder="Musikrichtung" type="text" name="music" id="music" class="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" >
                      </div>
                      <div class="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mb-4">
                        <div class="sm:col-span-3">
                          <div class="relative mt-2 rounded-md shadow-sm">
                            <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                              <span class="text-gray-500 sm:text-sm">
                                <ClockIcon class="h-6 w-3 text-gray-500" aria-hidden="true" />
                              </span>
                            </div>
                            <label for="slotBeginDay" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Schichtstart</label>
                            <input v-model="slotBeginDay" @change="calcSlotTime" required type="datetime-local" name="slotBeginDay" id="slotBeginDay" class="block w-full rounded-md border-0 py-1.5 pl-7 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"  />
                          </div>
                        </div>
                        <div class="sm:col-span-3">
                          <div class="relative mt-2 rounded-md shadow-sm">
                            <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                              <span class="text-gray-500 sm:text-sm">
                                <ClockIcon class="h-6 w-3 text-gray-500" aria-hidden="true" />
                              </span>
                            </div>
                            <label for="slotEndDay" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Schichtende</label>
                            <input v-model="slotEndDay" @change="calcSlotTime" required type="datetime-local" name="slotEndDay" id="slotEndDay" class="block w-full rounded-md border-0 py-1.5 pl-7 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                          </div>
                        </div>
                      </div>
                      <div class="relative mt-4">
                        <label for="description" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Beschreibung</label>
                        <textarea required v-model="description" placeholder="Beschreibung" name="description" id="description" class="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" ></textarea>
                      </div>
                      <div class="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mb-4" v-if="job === 8">
                        <div class="sm:col-span-3">
                          <div class="relative mt-2 rounded-md shadow-sm">
                            <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                              <span class="text-gray-500 sm:text-sm">€</span>
                            </div>
                            <label for="verdienst" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Raum Gage</label>
                            <input v-model="roomGage" required type="number" step="0.01" name="verdienst" id="verdienst" class="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="0.00" aria-describedby="price-currency" />
                            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                              <span class="text-gray-500 sm:text-sm" id="price-currency">EUR</span>
                            </div>
                          </div>
                        </div>
                        <div class="sm:col-span-3">
                          <div class="relative mt-2">
                            <label for="crewJobs" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Bookingart auswählen</label>
                            <select required v-model="crewJobs" name="crewJobs" id="crewJobs" class="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" >
                              <option value="Fotograf">
                                Fotograf
                              </option>
                              <option value="Videograf">
                                Videograf
                              </option>
                              <option value="Actor">
                                Act
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mb-4" v-else>
                        <div class="sm:col-span-3">
                          <div class="relative mt-2 rounded-md shadow-sm">
                            <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                              <span class="text-gray-500 sm:text-sm">€</span>
                            </div>
                            <label for="verdienst" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Stundenlohn</label>
                            <input v-model="officePay" required type="number" step="0.01" name="verdienst" id="verdienst" class="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="0.00" aria-describedby="price-currency" />
                            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                              <span class="text-gray-500 sm:text-sm" id="price-currency">EUR</span>
                            </div>
                          </div>
                        </div>
                        <div class="sm:col-span-3">
                          <div class="relative mt-2 rounded-md shadow-sm">
                            <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                              <span class="text-gray-500 sm:text-sm">
                                <TicketIcon class="h-6 w-3 text-gray-500" aria-hidden="true" />
                              </span>
                            </div>
                            <label for="officeJobs" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Anzahl der Stellen</label>
                            <input v-model="officeJobs" required type="text" name="officeJobs" id="officeJobs" class="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="0" />
                            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                              <span class="text-gray-500 sm:text-sm">Stück</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mt-4">
                      <p v-if="job === 8" class="text-sm text-gray-500">
                        Kosten pro Stunde: <span class="font-bold">{{(Math.round( ((roomGage / slotTime)*100))/100).toFixed(2) }}</span>€
                      </p>
                      <p v-else class="text-sm text-gray-500">
                        Gesamt kosten: <span class="font-bold">{{ ((Math.round((slotTime * officePay)*100)/100) * officeJobs).toFixed(2) }}</span>€
                      </p>
                    </div>
                  </div>
                </div>
                <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button type="submit" class="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto">Erstellen</button>
                  <button @click="$emit('closeNewSlotModal')" type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Abbrechen</button>
                </div>
              </DialogPanel>
            </form>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>

import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { XMarkIcon, ClockIcon } from '@heroicons/vue/24/outline'

export default {
  name: "createNewSlotModal",
  components: {
    Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot, XMarkIcon, ClockIcon
  },
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return{
      slotBeginDay:new Date().toISOString().slice(0,10)+'T18:30',
      slotEndDay:new Date().toISOString().slice(0,10)+'T22:00',
      driveStart:new Date().toISOString().slice(0,10)+'T18:00',
      driveEnd:new Date().toISOString().slice(0,10)+'T22:30',
      slotTime:4,
      job:7,
      officePay:12,
      officeJobs:1,
      eventPlz:'',
      description:'',
      eventCity:'',
      music:'',
      crewJobs:'Fotograf',
      clubname:'',
      roomGage:''
    }
  },
  methods:{
    calcSlotTime(){
      const startTime = new Date(this.slotBeginDay)
      const EndTime = new Date(this.slotEndDay)
      if (EndTime.getTime() <= startTime.getTime()){return this.slotTime = 0}
      this.slotTime = ((EndTime.getTime()/1000) - (startTime.getTime()/1000))/3600
    },
    insertSlot(){
      switch (this.job) {
        case 7:{
          const fD = new FormData
          fD.append('sessionID', this.auth.sessionID)
          fD.append('job', this.job)
          fD.append('slotStart', this.slotBeginDay)
          fD.append('slotEnd', this.slotEndDay)
          fD.append('description', this.description)
          fD.append('officePay', this.officePay)
          fD.append('officeJobs', this.officeJobs)
          this.axios.post(this.config.projectURL + 'slots/createSlot', fD, {})
              .then(response => {
                console.log(response.data, response.status)
                if (response.status === 250) {
                  console.log(response)
                  this.$emit('closeNewSlotModal')
                }
              })
              .catch(e => {
                console.error(e)
              })
          return
        }
        case 9:{
          const fD = new FormData
          fD.append('sessionID', this.auth.sessionID)
          fD.append('job', this.job)
          fD.append('driveStart', this.driveStart)
          fD.append('driveEnd', this.driveEnd)
          fD.append('slotStart', this.slotBeginDay)
          fD.append('slotEnd', this.slotEndDay)
          fD.append('description', this.description)
          fD.append('eventPlz', this.eventPlz)
          fD.append('eventCity', this.eventCity)
          fD.append('officePay', this.officePay)
          fD.append('officeJobs', this.officeJobs)
          this.axios.post(this.config.projectURL + 'slots/createSlot', fD, {})
              .then(response => {
                console.log(response.data, response.status)
                if (response.status === 250) {
                  console.log(response)
                  this.$emit('closeNewSlotModal')
                }
              })
              .catch(e => {
                console.error(e)
              })
          return
        }
        case 6:{
          const fD = new FormData
          fD.append('sessionID', this.auth.sessionID)
          fD.append('job', this.job)
          fD.append('slotStart', this.slotBeginDay)
          fD.append('slotEnd', this.slotEndDay)
          fD.append('eventPlz', this.eventPlz)
          fD.append('eventCity', this.eventCity)
          fD.append('clubname', this.clubname)
          fD.append('description', this.description)
          fD.append('music', this.music)
          fD.append('officePay', this.officePay)
          fD.append('officeJobs', this.officeJobs)
          this.axios.post(this.config.projectURL + 'slots/createSlot', fD, {})
              .then(response => {
                console.log(response.data, response.status)
                if (response.status === 250) {
                  console.log(response)
                  this.$emit('closeNewSlotModal')
                }
              })
              .catch(e => {
                console.error(e)
              })
          return
        }
        case 8:{
          const fD = new FormData
          fD.append('sessionID', this.auth.sessionID)
          fD.append('job', this.job)
          fD.append('slotStart', this.slotBeginDay)
          fD.append('slotEnd', this.slotEndDay)
          fD.append('eventPlz', this.eventPlz)
          fD.append('eventCity', this.eventCity)
          fD.append('clubname', this.clubname)
          fD.append('description', this.description)
          fD.append('crewJobs', this.crewJobs)
          fD.append('roomGage', this.roomGage)
          fD.append('officeJobs', this.officeJobs)
          console.log(fD)
          this.axios.post(this.config.projectURL + 'slots/createSlot', fD, {})
              .then(response => {
                console.log(response.data, response.status)
                if (response.status === 250) {
                  console.log(response)
                  this.$emit('closeNewSlotModal')
                }
              })
              .catch(e => {
                console.error(e)
              })
          return
        }

      }
    }
  }
}
</script>

<style scoped>

</style>