<template>
  <main id="snippetsView">
    <div class="content-1400">
      <div class="pad-16">
        <h2 style="padding-left: 4px; font-size: 1.2em;" class="color-blue">
          Snippet
        </h2>
        <h1 style="font-size: 2em;">
          Snippets Bearbeiten
        </h1>
        <div class="pad-16" />
        <div class="pad-4">
          <div class="bgcolor-white pad-16 br-10">
            <div class="grid-12" id="snippetsView">
              <div class="col-sp-12 col-tb-12 row-sp-12">
                <h3 style="font-weight: 400; font-size: 1.4em; margin-bottom: 6px;">
                  Größe
                </h3>
                <div class="bgcolor-lightgrey pad-16 flex sizes-wrapper br-10">
                  <div class="flex radio-wrapper">
                    <input type="radio" v-model="size" value="small" id="size-small">
                    <label for="size-small">Klein</label>
                  </div>
                  <div class="flex radio-wrapper">
                    <input type="radio" v-model="size" value="medium" id="size-medium">
                    <label for="size-medium">Mittel</label>
                  </div>
                  <div class="flex radio-wrapper">
                    <input type="radio" v-model="size" value="large" id="size-large">
                    <label for="size-large">Groß</label>
                  </div>
                </div>


                <h3 style="font-weight: 400; font-size: 1.4em; margin: 18px 0 6px;">
                  Schriftfarbe
                </h3>
                <div class="bgcolor-lightgrey pad-16 flex sizes-wrapper br-10">
                  <div class="flex radio-wrapper">
                    <input type="radio" v-model="fontColor" value="light" id="fontcolorBright">
                    <label for="fontcolorBright">Hell</label>
                  </div>
                  <div class="flex radio-wrapper">
                    <input type="radio" v-model="fontColor" value="dark" id="fontcolorDark">
                    <label for="fontcolorDark">Dunkel</label>
                  </div>
                </div>
                <h3 style="font-weight: 400; font-size: 1.4em; margin: 18px 0 6px;">
                  Hintergrundfarbe
                </h3>
                <div class="bgcolor-lightgrey pad-16 flex sizes-wrapper br-10">
                  <input type="radio" v-model="background" value="transparent" id="backgroundColorTransp">
                  <label for="backgroundColorTransp">Transparent</label>
                  <input type="radio" v-model="background" value="light" id="backgroundColorLight">
                  <label for="backgroundColorLight">Hell</label>
                  <input type="radio" v-model="background" value="dark" id="backgroundColorDark">
                  <label for="backgroundColorDark">Dunkel</label>
                </div>
                <hr style="margin: 18px 0;">
                <h3 style="font-weight: 400; font-size: 1.4em; margin: 18px 0 6px;">
                  Eventübersicht
                </h3>
                <div class="grid-12">
                  <div class="col-sp-12 row-sp-12 iframeCopy">
                    <pre class="" @click="copy(eventOverview)">
                        {{ eventOverview }}
                    </pre>
                  </div>
                  <div class="col-sp-12 row-sp-12" style="overflow: hidden;">
                    <iframe :src="'https://nachtaktivevents.app/snippet/events/' + config.organizerId + '?background=' + background + '&fontcolor=' + fontColor" :style="styleSize" />
                  </div>
                </div>
                <h3 style="font-weight: 400; font-size: 1.4em; margin: 18px 0 6px;">
                  Event Details
                </h3>
                <select v-model="eventId" class="button bgcolor-lightgrey" style="margin-bottom: 12px;">
                  <option :value="0">
                    Event auswählen
                  </option>
                  <option v-for="event in events" :key="event.id" :value="event.id">
                    {{ event.title }}
                  </option>
                </select>
                <div class="grid-12" v-if="eventId">
                  <div class="col-sp-12 row-sp-12">
                    <pre @click="copy(eventDetails)">
                      {{ eventDetails }}
                  </pre>
                  </div>
                  <div class="col-sp-12 row-sp-12" style="overflow: hidden;">
                    <iframe :src="'https://nachtaktivevents.app/snippet/event/' + eventId + '?background=' + background + '&fontcolor=' + fontColor" :style="styleSize" />
                  </div>
                </div>
                <hint :text="hintText" hint-type="success" @bye="() => {hintText = ''}" />
              </div>
              <div style="margin-bottom: 40px;" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import {aSyncData} from "@/modules/main";
import Hint from "@/components/basic/hint";

export default {
  name: "snippetsView",
  components: {Hint},
  title: 'Snippets',
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return{
      size: 'small',
      events: [],
      eventId: 0,
      background: 'dark',
      fontColor: 'light',
      hintText: ''
    }
  },
  computed: {
    styleSize(){
      return {
        height: this.size === 'large' ? '400px' : (this.size === 'medium' ? '400px' : '400px'),
        width: this.size === 'large' ? '1400px' : (this.size === 'medium' ? '800px' : '300px'),
      }
    },
    eventOverview(){
      return "<iframe src=\"https://nachtaktivevents.app/snippet/events/"+this.config.organizerId + "?background=" + this.background + "&fontcolor=" + this.fontColor + "\" style=\"width: 100%; max-width: " + this.styleSize.width + "; height: " + this.styleSize.height + ";\" >"
    },
    eventDetails(){
      return "<iframe src=\"https://nachtaktivevents.app/snippet/event/"+this.eventId + "?background=" + this.background + "&fontcolor=" + this.fontColor + "\" style=\"width: 100%; max-width: " + this.styleSize.width + "; height: " + this.styleSize.height + ";\" >"
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init(){
      aSyncData('events/getCurrentEvents', {organizerId: this.config.organizerId})
          .then(r => {
            if(r.status === 250){
              this.events = r.data
            }
          })
          .catch(e => {console.error(e)})
    },
    copy(text){
      navigator.clipboard.writeText(text)
      this.hintText = 'In die Zwischenablage kopiert.'
    }
  }
}
</script>

<style scoped>
pre{
  display: block;
  padding: 3px 3px 2px;
  font-size: 14px;
  line-height: 20px;
  background-color: #333;
  color: #f7f7f7;
  font-weight: bold;
  overflow: auto;
  cursor: copy;
  text-align: left;
}

.flex{
  display: flex;
}

.sizes-wrapper{
  column-gap: 16px;
}

.radio-wrapper{
  column-gap: 4px;
}

.iframeCopy pre{
  width: 100%;
  border-radius: 10px;
  display: block;
  padding: 16px 3px 0px;
  margin-bottom: 12px;
  font-size: 14px;
  line-height: 20px;
  background-color: #333;
  color: #f7f7f7;
  font-weight: bold;
  overflow: auto;
  cursor: copy;
  text-align: left;
}



</style>