<template>
  <li class="flex items-center justify-between gap-x-6 py-5 bg-slate-50 px-5 mb-4">
    <div class="min-w-0">
      <div class="flex items-start gap-x-3">
        <p class="text-sm font-semibold leading-6 text-gray-900">Ticket: #{{ ticketItems.id }} </p>
        <p v-if="ticketItems.status == 1 || ticketItems.status == 4" class="text-green-700 bg-green-50 ring-green-600/20 rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset">
          Gescannt
        </p>
        <p v-if="ticketItems.status == 0" class="text-gray-600 bg-gray-50 ring-gray-500/10 rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset">
          Noch nicht gescannt
        </p>
        <p v-if="ticketItems.status == 3" class="text-red-800 bg-red-50 ring-red-600/20 rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset">
          Ticket wurde entwertet
        </p>
      </div>
      <div class="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
        <p class="whitespace-nowrap">
          Gescannt um:
          <a v-if="ticketItems.scanTimestamp">{{covertStartTime}}</a>
          <a v-else>Noch nicht gescannt</a>
        </p>
        <svg viewBox="0 0 2 2" class="h-0.5 w-0.5 fill-current">
          <circle cx="1" cy="1" r="1" />
        </svg>
        <p class="truncate">Nutzer:
          <router-link v-if="ticketItems.userId" class="color-blue" :to="'/kundeninfo/'+ticketItems.userId">
            {{ ticketItems.firstname }} {{ ticketItems.lastname }}
          </router-link>
          <a v-else class="color-grey">Gastbestellung</a>
        </p>
      </div>
    </div>
    <div class="flex flex-none items-center gap-x-4">
      <a class="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block">
        {{ ticketItems.buyPrice }}€
      </a>
    </div>
  </li>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";

export default {
  name: "ticketItems",
  props: ["ticketItems"],
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
      covertStartTime: '',
    }
  },
  mounted() {
    this.config.loading = false
    this.covertTime()
  },
  methods: {
    covertTime(){
      if(this.ticketItems.scanTimestamp != null){
        const _this = this
        let timestampArr = this.ticketItems.scanTimestamp.split(" ");
        let date = timestampArr[0];
        let time = timestampArr[1];
        const dateArr = date.split("-");
        const timeArr = time.split(":");
        _this.covertStartTime = dateArr[2]+'.'+dateArr[1]+'.'+dateArr[0]+' - '+timeArr[0]+':'+timeArr[1]+' Uhr';
      }
    },
  }
}
</script>

<style scoped>
tbody td{
  padding: 16px 8px;
  font-size: 0.9em;
}
tbody td .label{
  padding: 4px 8px;
  border-radius: 10px;
  font-size: 0.9em;
}
tbody td .label.scan{
  background-color: #9bf2d233;
  color: #67cba6;
}
tbody td .label.unscan{
  background-color: #2068ff33;
  color: #2068ff;
}
</style>