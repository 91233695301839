
<template>
  <Vue3Lottie
      :animationData="TicketLoaderJSON"
      :height="150"
      :width="200"
  />
  <div class="relative pb-8">
    <div class="absolute inset-0 flex items-center" aria-hidden="true">
      <div class="w-full border-t border-gray-300" />
    </div>
    <div class="relative flex justify-center">
      <span class="bg-white px-2 text-sm text-gray-500">Lädt...</span>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import TicketLoaderJSON from '@/components/loader/ticketloader.json'

export default {
  name: "ticketLoader",
  components: {
    TicketLoaderJSON
  },
  data(){
    return {
      TicketLoaderJSON
    }
  },
}
</script>

<style scoped>

</style>