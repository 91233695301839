<template>
  <li class="flex items-center justify-between gap-x-6 py-5 bg-slate-50 px-4 mb-2 rounded">
    <div class="min-w-0">
      <div class="flex items-start gap-x-3">
        <p v-if="ticketItems.ticketType == 1" class="bg-blue-500 text-slate-100 rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset">
          Standard-Ticket
        </p>
        <p v-else-if="ticketItems.ticketType == 2" class="bg-indigo-500 text-slate-100 rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset">
          Deluxe-Ticket
        </p>
        <p v-else-if="ticketItems.ticketType == 3" class="bg-purple-500 text-slate-100 rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset">
          Premium-Ticket
        </p>
        <p class="text-sm font-semibold leading-6 text-gray-900">{{ ticketItems.ticketTitle }}</p>
      </div>
      <div class="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
        <p class="whitespace-nowrap">
          <b class="font-bold">Verkaufszeitraum:</b> <time :datetime="ticketItems.sellStart">{{ renderTimestampForJS(ticketItems.sellStart) }} Uhr</time> - <time :datetime="ticketItems.sellEnd">{{ renderTimestampForJS(ticketItems.sellEnd) }} Uhr</time>
        </p>
      </div>
      <p class="whitespace-nowrap text-xs leading-5 text-gray-500">
        <b class="font-bold">Verfügbare Anzahl:</b> {{ticketItems.quantity}} Stück
      </p>
      <p class="whitespace-nowrap text-xs leading-5 text-gray-500">
        <b class="font-bold">Gebühr: </b>
        <span v-if="ticketItems.feeType == 'none'">Keine Gebühr</span>
        <span v-else-if="ticketItems.feeType == 'ticket'">{{ ticketItems.fee.toFixed(2) }}€ pro Ticket</span>
        <span v-else-if="ticketItems.feeType == 'order'">{{ ticketItems.fee.toFixed(2) }}€ pro Bestellung</span>

      </p>
      <p class="mt-1 text-xs font-bold leading-5 text-gray-500">Ticketbeschreibung:</p>
      <p class="whitespace-nowrap text-xs leading-5 text-gray-500" style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap;" v-html="nl2br(ticketItems.ticketDescription)"></p>
    </div>
    <div class="flex flex-none items-center gap-x-4">
      <a class="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block">
        {{ ticketItems.price.toFixed(2) }} €
      </a>
      <Menu as="div" class="relative flex-none">
        <MenuButton class="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
          <span class="sr-only">Open options</span>
          <EllipsisVerticalIcon class="h-5 w-5" aria-hidden="true" />
        </MenuButton>
        <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
          <MenuItems class="absolute right-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
            <MenuItem v-slot="{ active }">
              <a @click="editTicketModal = true;" :class="[active ? 'bg-gray-50' : '', 'cursor-pointer block px-3 py-1 text-sm leading-6 text-gray-900']">Bearbeiten</a>
            </MenuItem>
            <MenuItem v-if="ticketCenterLink" v-slot="{ active }">
              <a :href="'/ticketcenter/edit/'+ticketItems.id" target="_blank" :class="[active ? 'bg-gray-50' : '', 'block px-3 py-1 text-sm leading-6 text-gray-900']">Im Ticketcenter ansehen</a>
            </MenuItem>
          </MenuItems>
        </transition>
      </Menu>
    </div>
  </li>

  <TransitionRoot as="template" :show="editTicketModal">
    <Dialog as="div" class="relative z-10" @close="editTicketModal = false">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <form @submit.prevent="saveTicket(ticketItems.id)">
              <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" @click="editTicketModal = false">
                    <span class="sr-only">Close</span>
                    <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div class="sm:flex sm:items-start">
                  <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                    <TicketIcon class="h-6 w-6 text-blue-600" aria-hidden="true" />
                  </div>
                  <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">Ticketvariante bearbeiten</DialogTitle>
                    <div>
                      <div class="relative mt-4">
                        <label for="ticketType" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Ticketart</label>
                        <select id="ticketType" v-model="ticketType" required class="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                          <option value="1" selected>
                            Standard-Ticket
                          </option>
                          <option value="2">
                            Deluxe-Ticket
                          </option>
                          <option value="3">
                            Premium-Ticket
                          </option>
                        </select>
                      </div>
                      <div class="relative mt-4">
                        <label for="ticketTitle" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Ticket-Titel</label>
                        <input id="ticketTitle" v-model="ticketTitle" required type="text" maxlength="20" placeholder="Ticketname (max. 20 Zeichen)" class="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                      </div>
                      <div class="relative mt-4">
                        <label for="ticketDescription" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Beschreibung & Informationen</label>
                        <textarea id="ticketDescription" v-model="ticketDescription" rows="4" placeholder="Beschreibung & Informationen" class="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></textarea>
                      </div>
                      <div class="relative mt-4">
                        <label for="badge" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Badge</label>
                        <input v-model="badge" maxlength="40" type="text" name="badge" id="badge" class="px-2 text-l block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6" placeholder="Badge" />
                      </div>
                      <div class="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mb-4">
                        <div class="sm:col-span-3">
                          <div class="relative mt-2 rounded-md shadow-sm">
                            <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                              <span class="text-gray-500 sm:text-sm">
                                <ClockIcon class="h-6 w-3 text-gray-500" aria-hidden="true" />
                              </span>
                            </div>
                            <label for="sellStart" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Verkaufsstart</label>
                            <input v-model="sellStart" required type="datetime-local" name="sellStart" id="sellStart" class="block w-full rounded-md border-0 py-1.5 pl-7 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Verkaufsstart" />
                          </div>
                        </div>
                        <div class="sm:col-span-3">
                          <div class="relative mt-2 rounded-md shadow-sm">
                            <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                              <span class="text-gray-500 sm:text-sm">
                                <ClockIcon class="h-6 w-3 text-gray-500" aria-hidden="true" />
                              </span>
                            </div>
                            <label for="sellEnd" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Verkaufsende</label>
                            <input v-model="sellEnd" required type="datetime-local" name="sellEnd" id="sellEnd" class="block w-full rounded-md border-0 py-1.5 pl-7 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Verkaufsende" />
                          </div>
                        </div>
                      </div>
                      <div class="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mb-4">
                        <div class="sm:col-span-3">
                          <div class="relative mt-2">
                            <label for="feeType" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Ticket Gebühr</label>
                            <select v-model="feeType" id="feeType" class="px-2 block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6">
                              <option value="none">
                                Keine
                              </option>
                              <option value="ticket">
                                Pro Ticket
                              </option>
                              <option value="order">
                                Pro Bestellung
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="sm:col-span-3" v-if="feeType != 'none'">
                          <div class="relative mt-2 rounded-md shadow-sm">
                            <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                              <span class="text-gray-500 sm:text-sm">€</span>
                            </div>
                            <label for="fee" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Gebühr (inkl. MwSt.)</label>
                            <input v-model="fee" type="number" step="0.01" name="fee" id="fee" class="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="0.00" aria-describedby="fee-currency" />
                            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                              <span class="text-gray-500 sm:text-sm" id="fee-currency">EUR</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mb-4">
                        <div class="sm:col-span-3">
                          <div class="relative mt-2 rounded-md shadow-sm">
                            <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                              <span class="text-gray-500 sm:text-sm">€</span>
                            </div>
                            <label for="price" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Brutto-Endpreis (inkl. MwSt.)</label>
                            <input v-model="price" required type="number" step="0.01" name="price" id="price" class="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="0.00" aria-describedby="price-currency" />
                            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                              <span class="text-gray-500 sm:text-sm" id="price-currency">EUR</span>
                            </div>
                          </div>
                        </div>
                        <div class="sm:col-span-3">
                          <div class="relative mt-2 rounded-md shadow-sm">
                            <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                              <span class="text-gray-500 sm:text-sm">
                                <TicketIcon class="h-6 w-3 text-gray-500" aria-hidden="true" />
                              </span>
                            </div>
                            <label for="quantity" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Verfügbare Tickets</label>
                            <input v-model="quantity" required type="text" name="quantity" id="quantity" class="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="0" />
                            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                              <span class="text-gray-500 sm:text-sm">Stück</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <fieldset>
                        <div class="relative flex items-start px-2 mb-8">
                          <div class="flex h-6 items-center">
                            <input v-model="personal" id="personal" aria-describedby="comments-description" name="personal" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                          </div>
                          <div class="ml-3 text-sm leading-6">
                            <label for="personal" class="font-medium text-gray-900">Personengebunden</label>
                            <p id="comments-description" class="text-gray-500">Tickets sind personengebunden und können nicht weitergegeben werden</p>
                          </div>
                        </div>
                      </fieldset>
                      <div class="pb-4">
                        <alert class="my-4" v-if="ticketDeleteError" type="error" title="Löschen nicht möglich" message="Ticketvariante konnte nicht gelöscht werden, da bereits Tickets von diesem Typ verkauft wurden. Du kannst das Ticket alternativ für den Verkauf im Ticketcenter deaktivieren."/>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <div class="sm:col-span-3">
                    <a class="underline text-gray-500 text-xs cursor-pointer" @click="deleteTicket(ticketItems.id)">Ticket löschen</a>
                  </div>
                  <div class="sm:col-span-3">
                    <div class="sm:flex sm:flex-row-reverse">
                      <button type="submit" class="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto">Speichern</button>
                      <button @click="editTicketModal = false;" type="button"  class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Abbrechen</button>
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </form>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
  <notification class="my-4" v-if="successDelete" type="success" title="Ticket gelöscht" message="Die Ticketvariante wurde erfolgreich gelöscht."/>
  <notification class="my-4" v-if="saveMessage" type="success" title="Speichern erfolgreich" message="Die Änderungen wurden erfolgreich übernommen."/>

</template>

<script>
/* eslint-disable */
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";
import notification from "@/components/notifications/notification";
import alert from "@/components/alerts/alert";
import { ChevronLeftIcon, PhotoIcon, ChevronRightIcon, CurrencyEuroIcon, TicketIcon, QrCodeIcon, CheckCircleIcon, ClockIcon, ArrowDownIcon, ArrowUpIcon, BriefcaseIcon, CalendarIcon, EnvelopeIcon, ChevronDownIcon, LinkIcon, MapPinIcon, PencilSquareIcon, BanknotesIcon, EllipsisVerticalIcon } from '@heroicons/vue/20/solid'
import { Switch, RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption, Menu, MenuButton, MenuItem, MenuItems, Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/vue/24/outline'
import {nl2br} from "@/modules/main";

export default {
  name: "ticketItems",
  props: ["ticketItems"],
  emits: ["update"],
  components:{
    ChevronLeftIcon, ChevronRightIcon, TicketIcon, CurrencyEuroIcon, QrCodeIcon,
    notification,alert,RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption,
    CheckCircleIcon, ClockIcon, MapPinIcon, Switch, PencilSquareIcon, BanknotesIcon,
    ArrowDownIcon, ArrowUpIcon,   BriefcaseIcon, CalendarIcon, EnvelopeIcon, ChevronDownIcon, LinkIcon,
    Menu, MenuButton, MenuItem, MenuItems, Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot,
    ExclamationTriangleIcon, XMarkIcon, PhotoIcon, EllipsisVerticalIcon
  },
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
      editTicketModal: false,
      ticketTitle: this.ticketItems.ticketTitle,
      ticketDescription: this.ticketItems.ticketDescription,
      price: this.ticketItems.price,
      quantity: this.ticketItems.quantity,
      personal: !!parseInt(this.ticketItems.personal),
      fee: this.ticketItems.fee,
      feeType: this.ticketItems.feeType,
      sellStart: this.ticketItems.sellStart,
      sellEnd: this.ticketItems.sellEnd,
      ticketDeleteError: false,
      ticketType: this.ticketItems.ticketType,
      successDelete: false,
      saveMessage: false,
      ticketCenterLink: false,
      badge: this.ticketItems.badge
    }
  },
  mounted() {
    this.config.loading = false
    this.checkCurrentPage()
  },
  methods: {
    checkCurrentPage() {
      const currentURL = window.location.href;
      const parts = currentURL.split('/');
      const lastPart = parts[parts.length - 1];

      // Überprüfen, ob der letzte Teil "create" ist
      if (lastPart === 'create') {
        this.ticketCenterLink = false;
      } else {
        this.ticketCenterLink = true;
      }
    },
    deleteTicket(ticketid){
      this.successDelete = false
      const _this = this
      const fD = new FormData()
      fD.append('ticketId', ticketid)
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('ticketcenter/deleteTicket', fD)
          .then((response) => {
            console.log(response)
            if(response.status === 250){
              _this.editTicketModal = false
              _this.successDelete = true
              this.$emit('update')
            }else{
              _this.ticketDeleteError = true;
            }
          })
    },
    saveTicket(ticketid){
      this.saveMessage = false
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('ticketTitle', this.ticketTitle)
      fD.append('ticketDescription', this.ticketDescription)
      fD.append('price', this.price)
      fD.append('quantity', this.quantity)
      fD.append('ticketId', ticketid)
      fD.append('personal', this.personal)
      fD.append('fee', this.fee)
      fD.append('feeType', this.feeType)
      fD.append('sellStart', this.sellStart)
      fD.append('sellEnd', this.sellEnd)
      fD.append('ticketType', this.ticketType)
      fD.append('badge', this.badge)
      this.axios.post('ticketcenter/saveTicket', fD)
          .then((response) => {
            if(response.status === 250){
              _this.editTicketModal = false
              _this.saveMessage = true
              this.$emit('update')
            }
          })
    },
    renderTimestampForJS(date){
      const jsTimestamp = date.split(' ')
      const jsDate = jsTimestamp[0].split('-')
      const jsTime = jsTimestamp[1].split(':')
      return jsDate[2] + '.' + jsDate[1] + '.' + jsDate[0] + ' ' + jsTime[0] + ':' + jsTime[1]
    },
    nl2br(str){
      return nl2br(str)
    },
  }
}
</script>

