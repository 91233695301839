<template>
  <div class="bg-neutral-50 h-full">
    <div class="lg:pl-72">
      <main class="py-4" id="appPay">
        <div class="px-4">
          <div class="mb-2">
            <div>
              <nav class="hidden sm:flex" aria-label="Breadcrumb">
                <ol role="list" class="flex items-center space-x-4">
                  <li>
                    <div class="flex">
                      <a href="/eventlypay" class="text-sm font-medium text-gray-500 hover:text-gray-700">App-Pay</a>
                    </div>
                  </li>
                </ol>
              </nav>
            </div>
            <div class="mt-2 md:flex md:items-center md:justify-between">
              <div class="min-w-0 flex-1">
                <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">App Zahlung </h2>
              </div>
              <div class="mt-4 flex flex-shrink-0 md:ml-4 md:mt-0">
              </div>
            </div>
          </div>
          <div>
            <div>
              <form>
                <div class="relative mt-4 mb-4">
                  <label for="eventId" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Event auswählen</label>
                  <select v-model="eventId" @change="getTransactions();" id="eventId" class="text-l px-2 block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6">
                    <option value="-1" disabled>
                      Event...
                    </option>
                    <option v-for="event in events" :key="event.id" :value="event.id">
                      {{ event.title + ' - ' + event.startTime.split(' ')[0].split('-')[2] + '.' + event.startTime.split(' ')[0].split('-')[1] }}
                    </option>
                  </select>
                </div>
              </form>
            </div>
            <div class="grid-12" v-if="eventId != -1">
              <div class="col-sp-12 col-tb-6 row-sp-12 mr-2">
                <section class="bgcolor-blue t-center color-white br-10 pad-16" @click="scanner = true">
                  <QrCodeIcon class="h-20 w-20 flex-shrink-0 text-white " aria-hidden="true" style="margin: 0 auto;"/>
                  <h2 style="font-size: 2em;">
                    Neue Zahlung
                  </h2>
                </section>
              </div>
              <div class="col-sp-12 col-tb-6 row-sp-12">
                <section class="bg-white rounded shadow p-4" style="overflow: hidden;" v-if="eventId != -1">
                  <div class="border-b border-gray-200 bg-white">
                    <h3 class="text-base text-xl font-semibold leading-6 text-gray-900">Letzte Zahlungen</h3>
                  </div>
                  <div class="mt-4">
                    <table class="w-full text-left">
                      <thead class="bg-white">
                        <tr>
                          <th scope="col" class="relative isolate py-3.5 pr-3 text-left text-sm font-semibold text-gray-900">
                            ID
                            <div class="absolute inset-y-0 right-full -z-10 w-screen border-b border-b-gray-200" />
                            <div class="absolute inset-y-0 left-0 -z-10 w-screen border-b border-b-gray-200" />
                          </th>
                          <th scope="col" class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">Vorname</th>
                          <th scope="col" class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell">Nachname</th>
                          <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Zeitstempel</th>
                          <th scope="col" class="relative py-3.5 pl-3 text-sm font-semibold">
                            Umsatz
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <transactionItems v-for="titems in transactionItems" :transaction-items="titems" :key="titems.id" />
                      </tbody>
                    </table>
                  </div>
                </section>
              </div>
            </div>
            <section id="Scanner" v-show="scanner">
              <transition name="scanned">
                <div id="payCheck" class="overlay green" v-if="payed">
                  <div class="t-center">
                    <div class="pad-24" />
                    <img src="assets/images/icons/check-white.svg" class="width-100">
                    <h5 style="font-size: 1.5em; margin-bottom: 16px; margin-top: 8px">
                      Zahlung erfolgreich
                    </h5>
                    <button @click="stopScanner();payed = false;" class="bgcolor-white color-black button">
                      Okay
                    </button>
                  </div>
                </div>
                <div class="overlay blue" v-else-if="cancel || start" />
              </transition>
              <div v-show="scan" class="videoContainer">
                <video ref="scanner" />
                <button type="button" class="button bgcolor-blue color-white circular" @click="cancelScan">
                  Abbrechen
                </button>
                <transition id="payError" name="error">
                  <div class="error t-center" v-if="error">
                    <div class="pad-24" />
                    <img src="assets/images/icons/error-white.svg" class="width-100">
                    <h5 style="font-size: 1.5em; margin-bottom: 16px; margin-top: 8px">
                      {{ error }}
                    </h5>
                    <button @click="error = false" class="bgcolor-white color-black button">
                      Okay
                    </button>
                  </div>
                </transition>
              </div>
              <!--
              <TransitionRoot as="template" v-if="!scan" :show="!scan">
                <Dialog as="div" class="relative z-10" @close="setNumberModal = false; startScanner()">
                  <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                  </TransitionChild>
                  <div class="fixed inset-0 z-10 overflow-y-auto">
                    <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                          <form @submit.prevent="setNumber()">
                            <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                              <button type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" @click="setNumberModal = false; startScanner()">
                                <span class="sr-only">Close</span>
                                <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                              </button>
                            </div>
                            <div class="sm:flex sm:items-start">
                              <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                                <QrCodeIcon class="h-6 w-6 text-blue-600" aria-hidden="true" />
                              </div>
                              <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">Gaderoben-Nummer vergeben</DialogTitle>
                                <div class="mt-2">
                                  <p class="text-sm text-gray-500">
                                    Bitte gebe die Nummer ein, mit welcher du die Kleidung in der Gaderobe hinterlegst.
                                  </p>
                                </div>
                                <div>
                                  <div class="relative mt-4">
                                    <label for="pickupNumber" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Gaderoben-Nummer festlegen:</label>
                                    <input required type="number" v-model="pickupNumber" name="pickupNumber" id="pickupNumber" class="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Abholnummer" />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                              <button type="submit" class="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto">Nummer vergeben</button>
                              <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto" @click="setNumberModal = false; startScanner()">Abbrechen</button>
                            </div>
                          </form>
                        </DialogPanel>
                      </TransitionChild>
                    </div>
                  </div>
                </Dialog>
              </TransitionRoot>
              -->
              <div v-if="!scan">
                <TransitionRoot as="template" :show="!scan && eventId != -1 && scanner == true">
                  <Dialog as="div" class="relative z-10" @close="scanner = false">
                    <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </TransitionChild>
                    <div class="fixed inset-0 z-10 overflow-y-auto">
                      <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                          <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                            <form @submit.prevent="startScanner()">
                              <div>
                                <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-blue-100">
                                  <CurrencyEuroIcon class="h-6 w-6 text-blue-600" aria-hidden="true" />
                                </div>
                                <div class="mt-3 text-center sm:mt-5">
                                  <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">Betrag festlegen</DialogTitle>
                                  <div class="mt-2">
                                    <div class="grid-12 text-center" style="grid-gap: 4px;">
                                      <div class="col-sp-12 col-sd-12 row-sp-2 input font-bold pb-4">
                                        {{ (credits / 100).toFixed(2) }} €
                                      </div>
                                      <div class="col-sp-4 col-sd-4 row-sp-2 pt-2" @click="add(7)">
                                        <span class="py-2 px-4 bg-slate-50 rounded cursor-pointer text-xl">7</span>
                                      </div>
                                      <div class="col-sp-4 col-sd-4 row-sp-2 pt-2" @click="add(8)">
                                        <span class="py-2 px-4 bg-slate-50 rounded cursor-pointer text-xl">8</span>
                                      </div>
                                      <div class="col-sp-4 col-sd-4 row-sp-2 pt-2" @click="add(9)">
                                        <span class="py-2 px-4 bg-slate-50 rounded cursor-pointer text-xl">9</span>
                                      </div>
                                      <div class="col-sp-4 col-sd-4 row-sp-2 pt-2" @click="add(4)">
                                        <span class="py-2 px-4 bg-slate-50 rounded cursor-pointer text-xl">4</span>
                                      </div>
                                      <div class="col-sp-4 col-sd-4 row-sp-2 pt-2" @click="add(5)">
                                        <span class="py-2 px-4 bg-slate-50 rounded cursor-pointer text-xl">5</span>
                                      </div>
                                      <div class="col-sp-4 col-sd-4 row-sp-2 pt-2" @click="add(6)">
                                        <span class="py-2 px-4 bg-slate-50 rounded cursor-pointer text-xl">6</span>
                                      </div>
                                      <div class="col-sp-4 col-sd-4 row-sp-2 pt-2" @click="add(1)">
                                        <span class="py-2 px-4 bg-slate-50 rounded cursor-pointer text-xl">1</span>
                                      </div>
                                      <div class="col-sp-4 col-sd-4 row-sp-2 pt-2" @click="add(2)">
                                        <span class="py-2 px-4 bg-slate-50 rounded cursor-pointer text-xl">2</span>
                                      </div>
                                      <div class="col-sp-4 col-sd-4 row-sp-2 pt-2" @click="add(3)">
                                        <span class="py-2 px-4 bg-slate-50 rounded cursor-pointer text-xl">3</span>
                                      </div>
                                      <div class="col-sp-4 col-sd-4 row-sp-2 special pt-2" @click="add(0); add(0)">
                                        <span class="py-2 px-4 bg-slate-50 rounded cursor-pointer text-xl">00</span>
                                      </div>
                                      <div class="col-sp-4 col-sd-4 row-sp-2 pt-2" @click="add(0)">
                                        <span class="py-2 px-4 bg-slate-50 rounded cursor-pointer text-xl">0</span>
                                      </div>
                                      <div class="col-sp-4 col-sd-4 row-sp-2 delete pt-2" @click="del">
                                        <span class="py-3 px-2 bg-slate-50 rounded cursor-pointer text-xs">Löschen</span>
                                      </div>


                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                <button type="submit" class="inline-flex w-full justify-center rounded-md bg-blue-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 sm:col-start-2">Bestätigen</button>
                                <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0" @click="scanner = false" ref="cancelButtonRef">Abbrechen</button>
                              </div>
                            </form>
                          </DialogPanel>
                        </TransitionChild>
                      </div>
                    </div>
                  </Dialog>
                </TransitionRoot>
              </div>
              <VueModal :show="result" @approve="result = null; initScanner()" approve="Ok">
                {{ JSON.stringify(result, null, 4) }}
              </VueModal>
            </section>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import transactionItems from "@/components/eventlypay/transaction";
import QrScanner from 'qr-scanner';
import { ChevronLeftIcon, ChevronRightIcon, CurrencyEuroIcon, TicketIcon, QrCodeIcon, CheckCircleIcon, ClockIcon, ArrowDownIcon, ArrowUpIcon,   BriefcaseIcon, CalendarIcon, EnvelopeIcon, ChevronDownIcon, LinkIcon, MapPinIcon, PencilSquareIcon, BanknotesIcon, ShoppingBagIcon  } from '@heroicons/vue/20/solid'
import notification from "@/components/notifications/notification";
import alert from "@/components/alerts/alert";
import { RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption, Menu, MenuButton, MenuItem, MenuItems, Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/vue/24/outline'

export default {
  name: "eventlyPay",
  title: 'eventlyPay',
  components: {
    transactionItems: transactionItems,
    ChevronLeftIcon, ChevronRightIcon, TicketIcon, CurrencyEuroIcon, QrCodeIcon,
    notification,alert,RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption,
    CheckCircleIcon, ClockIcon,ArrowDownIcon, ArrowUpIcon,  BriefcaseIcon,
    CalendarIcon, EnvelopeIcon, ChevronDownIcon, LinkIcon, MapPinIcon, PencilSquareIcon, BanknotesIcon,
    Menu, MenuButton, MenuItem, MenuItems, Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot,
    ExclamationTriangleIcon, XMarkIcon, ShoppingBagIcon
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      qrScanner: null,
      paymentIntent: null,
      result: null,
      credits: 0,
      scan: false,
      error: false,
      payed: false,
      cancel: false,
      start: false,
      scanner: false,
      eventId: -1,
      transactionItems: [],
      events: [],
    }
  },
  mounted() {
    this.initScanner()
    this.getTransactions()
    this.getEvents()
  },
  beforeUnmount() {
    try {
      this.qrScanner.stop()
      this.qrScanner.destroy();
      this.qrScanner = null;
    }catch (e) {}
  },
  methods: {
    add(number){
      this.credits = this.credits*10 + number
    },
    del(){
      this.credits = Math.floor(this.credits/10)
    },
    initScanner(){
      const _this = this
      this.qrScanner = new QrScanner(
          this.$refs.scanner,
          _this.scanned,
          {
            highlightScanRegion: true,
            highlightCodeOutline: true
          })
      this.qrScanner.setInversionMode('both');
    },
    startScanner(){
      const _this = this
      this.qrScanner.start()
      this.scan = true
      _this.start = true
      setTimeout(() => {
        _this.start = false
      }, 1000)
    },
    cancelScan(){
      const _this = this
      _this.cancel = true
      setTimeout(() => {
        _this.cancel = false
        this.stopScanner()
      }, 1000)
    },
    stopScanner(){
      try {
        this.credits = 0;
        this.scan = false
        this.qrScanner.stop()
      }catch (e) {}
    },
    scanned(r){
      this.qrScanner.stop()
      const _this = this
      this.paymentIntent = r.data
      const fD = new FormData()
      console.log(this.credits)
      fD.append('sessionID', this.auth.sessionID)
      fD.append('paymentIntent', btoa(this.paymentIntent))
      fD.append('credits', this.credits)
      //fD.append('organizerId', this.config.organizerId) nicht gebraucht da über event geholt
      fD.append('eventId', this.eventId)
      this.axios.post('payment/pay', fD)
          .then(r => {
            console.log(r.data, r.status)
            if(r.status === 250) {
              _this.payed = true
              _this.transactions.push(r.data)
              _this.getTransactions()
              setTimeout(() => {
                _this.stopScanner()
                _this.payed = false
              }, 12000)
            }else{
              _this.error = r.data
              this.qrScanner.start()
              setTimeout(() => {
                _this.error = false
              }, 13000)
            }
          })
          .catch(e => {
            console.log(e)
          })
    },
    getTransactions() {
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('eventId',  this.eventId)
      this.axios.post('eventlyPay/getTransactions', fD)
          .then(r => {
            if(r.status === 250) {
              console.log(r.data)
              _this.transactionItems = r.data
            }
          })
          .catch(e => {
            console.error(e)
          })
    },
    getEvents(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('organizerId',  this.config.organizerId)
      this.axios.post('ticketCenter/getEvents', fD)
          .then(r => {
            console.log(r.data, r.status)
            if(r.status === 250) {
              _this.events = r.data
            }
          })
          .catch(e => {
            console.log(e)
          })
    }
  },
}
</script>

<style scoped>
#appPay h1{
  font-size: 2em;
}
#appPay h3{
  font-size: 1.2em;
}
main#appPay{
  padding-bottom: 80px;
}
section#Scanner{
  position: fixed;
  background-color: #ffffff;
  top: 0;
  left: 50%;
  width: 100%;
  height: 100%;
  max-width: 100%;
  transform: translateX(-50%);
}
.green{
  background-color: #00cc66;
}
.blue{
  background-color: #2068ff;
}
section#Scanner video{
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
section#Scanner .videoContainer{
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%,-50%);
}
section#Scanner .videoContainer button{
  position: fixed;
  bottom: 120px;
  left: 50%;
  transform: translateX(-50%);
}
section#Scanner .grid-12{
  height: calc(100vh - 80px);
}
section#Scanner .number{
  text-align: center;
  background-color: rgba(255,255,255,1);
  display: flex;
  width: 99%;
  height: 98%;
  margin: auto;
  border: 2px solid rgba(0,0,0,0.05);
  align-items:center;
  justify-content:center;
  font-size: 2.5em;
  cursor: pointer;
}
section#Scanner .input{
  text-align: center;
  background-color: rgba(0,0,0,0.2);
  display: flex;
  width: 100%;
  height: 100%;
  margin: auto;
  align-items:center;
  justify-content:center;
  color: white;
  font-size: 4em;
}
section#Scanner .delete{
  background-color: rgba(0,0,0,0.05);
}
section#Scanner .disabled{
  background-color: grey !important;
}
section#Scanner .button:active{
  top: auto;
}
.scanned-enter-active,
.scanned-leave-active{
  transition: opacity 1s ease;
}
.scanned-enter-from,
.scanned-leave-to {
  opacity: 0;
}

.error-enter-active{
  animation: bounce-in 0.5s;
}
.error-leave-active {
  animation: bounce-in 0.5s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}
#appPay .field select{
  display: block;
  width: 100%;
  border-radius: 10px;
  border: none;
  background-color: rgba(255,255,255,1);
  padding: 12px;
  margin-bottom: 8px;
}
table thead{
  background-color: rgba(0,0,0,0.05);
  font-weight: bold;
}
table thead tr td{
  padding: 4px;
}
#Scanner{
  position: relative;
}
#Scanner #payCheck{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  color: #ffffff;
  background-color: #00cc66;
  border-radius: 10px;
  z-index: 9;
  padding-top: 20vh;
}
#Scanner #payError{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  color: #ffffff;
  background-color: #BD081C;
  border-radius: 10px;
  z-index: 9;
  padding-top: 20vh;
}
</style>