<template>
  <div class="bg-neutral-50 h-full">
    <div class="lg:pl-72">
      <main class="py-4">
        <router-view />
      </main>
    </div>
  </div>
</template>

<script>
export default {
  name: "customerInformationParentView"
}
</script>

<style scoped>

</style>