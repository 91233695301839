<template>
  <div v-if="media.status === 'upload'">
    Upload...
  </div>
  <div v-else-if="media.status === 'error'">
    Fehler...
  </div>
  <li class="relative">
    <div class="group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
      <img :src="!media.file ? objectUrl : config.projectURL + 'assets/images/media/' + media.file + '_thumbnail.' + media.extension" alt="" class="pointer-events-none object-cover group-hover:opacity-75" />
    </div>
    <button v-if="media.file" @click="$emit('deleteImage')" type="button" class="w-full rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">Bild löschen</button>
  </li>
</template>
<script>
import {configStore} from "@/store/configStore";

export default {
  name: "mediaUpload",
  props: ['media'],
  emits: ['deleteImage'],
  setup () {
    const config = configStore();
    return {
      config
    }
  },
  computed: {
    objectUrl() {
      return URL.createObjectURL(this.media)
    }
  }
}
</script>
<style scoped>

</style>