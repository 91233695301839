<template>
  <tr>
    <td class="relative py-4 pr-3 text-sm font-medium text-gray-900">
      [{{customer.userId}}] <span class="font-bold">{{ customer.firstname }} {{ customer.lastname }}</span>
      <div class="absolute bottom-0 right-full h-px w-screen bg-gray-100" />
      <div class="absolute bottom-0 left-0 h-px w-screen bg-gray-100" />
    </td>
    <td class="hidden px-3 py-4 text-sm text-gray-500 md:table-cell"> {{ customer.email }}</td>
    <td class="hidden px-3 py-4 text-sm text-gray-500 md:table-cell"> {{ lastSale }} Uhr</td>

    <td class="px-3 py-4 text-sm text-gray-500 text-right">{{ (-saleByUser/100).toFixed(2) }}€</td>
    <td class="py-4 pl-3 text-right text-sm">
      <button class="button tiny color-white" @click="$router.push({name: 'Customer', params: {userId: customer.userId}})" v-if="customer.userId">
        Info
      </button>
    </td>
  </tr>
</template>

<script>
/* eslint-disable */
import {dateToString, stringToDate} from "@/modules/main";

export default {
  name: "saleUsers",
  props: {
    customer: Object
  },
  emits: ['userInfo'],
  data() {
    return {
      expanded: false
    }
  },
  computed: {
    lastSale(){
      return dateToString(this.customer.lastSale)
    },
    saleByUser(){
      return this.customer.sales.reduce((a, c) => {
        return a + c.amount
      }, 0)
    }
  }
}
</script>

<style scoped>

  .eventData{
    margin: 4px 0 4px 12px;
  }

</style>