<script>
export default {
  name: "universalAlbumsParentView",
  title: "Medien"
}
</script>

<template>
  <router-view />
</template>

<style scoped>

</style>