<template>
  <div class="bg-neutral-50 h-full">
    <div class="lg:pl-72">
      <main class="py-4">
        <div class="px-4">
          <div class="mb-2">
            <nav class="sm:hidden" aria-label="Back">
              <a href="/albums" class="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700">
                <ChevronLeftIcon class="-ml-1 mr-1 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                Zurück
              </a>
            </nav>
            <div>
              <nav class="hidden sm:flex" aria-label="Breadcrumb">
                <ol role="list" class="flex items-center space-x-4">
                  <li>
                    <div class="flex">
                      <a href="/albums" class="text-sm font-medium text-gray-500 hover:text-gray-700">Mediengalerie</a>
                    </div>
                  </li>
                  <li>
                    <div class="flex items-center">
                      <ChevronRightIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                      <a class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">Album bearbeiten</a>
                    </div>
                  </li>
                </ol>
              </nav>
            </div>
            <div class="mt-2 md:flex md:items-center md:justify-between">
              <div class="min-w-0 flex-1">
                <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">Album bearbeiten</h2>
              </div>
              <div class="mt-4 flex flex-shrink-0 md:ml-4 md:mt-0">
                <button @click="deleteModal = true" type="button" class="ml-3 inline-flex items-center rounded-md bg-white px-2 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                  Album löschen
                </button>
                <button @click="editModal = true" type="button" class="ml-3 inline-flex items-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
                  Album bearbeiten
                </button>
              </div>
            </div>
            <div class="ml-3 flex justify-start md:justify-end">
              <p class="text-left pt-2 text-gray-500">Das Album kann über den Button "Album bearbeiten" veröffentlicht werden.</p>
            </div>
          </div>
          <section>
            <div class="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
              <article class="relative isolate flex flex-col gap-8 lg:flex-row">
                <div class="relative aspect-[16/9] lg:w-64 lg:shrink-0">
                  <img :src="config.projectURL + 'assets/images/media/' + album.cover + '_thumbnail.jpg'" v-if="album?.cover?.length" alt="" class="absolute inset-0 h-full w-full rounded-2xl bg-gray-50 object-cover">
                  <div class="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10"></div>
                </div>
                <div>
                  <div class="flex items-center gap-x-4 text-xs">
                    <a class="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100">{{album.online ? 'Veröffentlicht' : 'Noch nicht Veröffentlicht'}}</a>
                  </div>
                  <div class="group relative max-w-xl">
                    <h3 class="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                      <a>
                        <span class="absolute inset-0"></span>
                        {{album.title}}
                      </a>
                    </h3>
                    <p class="mt-5 text-sm leading-6 text-gray-600" v-html="nl2br(album.description)"></p>
                  </div>
                </div>
              </article>
            </div>
          </section>
          <section class="mt-4 border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
            <div>
              <ul role="list" class="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-5 xl:gap-x-8">
                <li class="relative">
                  <input type="file" ref="input" accept="images/*" hidden @change="onMediaChange" multiple>
                  <button @click="$refs.input.click()" type="button" class="relative block w-full h-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="mx-auto h-12 w-12 text-gray-400">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
                    </svg>
                    <span class="mt-2 block text-sm font-semibold text-gray-900">Bilder hochladen</span>
                  </button>
                </li>
                <media-upload :media="singleMedia" v-for="(singleMedia, index) in media" :key="index" @delete-image="deleteImage(index)"/>
              </ul>
            </div>
          </section>
        </div>
      </main>
    </div>
    <notification class="my-4" v-if="saveMessage" type="success" title="Speichern erfolgreich" message="Die Änderungen wurden erfolgreich übernommen."/>
    <notification class="my-4" v-if="deleteMessage" type="success" title="Löschen erfolgreich" message="Das Wasserzeichen wurde erfolgreich gelöscht."/>
    <confirmModal v-if="deleteModal" type="danger" title="Bist du dir Sicher?" message="Möchtest du das Album wirklich löschen?" cancelButton="Abbrechen" button="Löschen" @action="deleteAlbum();"/>
    <TransitionRoot as="template" :show="editModal">
      <Dialog as="div" class="relative z-10" @close="editModal = false">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>
        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <form @submit.prevent="edit()">
                <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <button type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" @click="editModal = false">
                      <span class="sr-only">Close</span>
                      <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div class="sm:flex sm:items-start">
                    <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                      <PhotoIcon class="h-6 w-6 text-blue-600" aria-hidden="true" />
                    </div>
                    <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">Album bearbeiten</DialogTitle>
                      <div>
                        <img class="w-20 mt-4 br-4" :src="config.projectURL + 'assets/images/media/' + album.cover + '_thumbnail.jpg'" v-if="album?.cover?.length" />
                        <div class="relative mt-2">
                          <label for="banner" class="absolute w-full -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Cover auswählen (.jpg / .png Datei)</label>
                          <input id="banner" @change="onCoverChange" ref="cover" type="file" accept="image/*" class="w-full rounded bg-white px-2 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                        </div>
                        <div class="relative mt-4">
                          <label for="title" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Titel des Albums</label>
                          <input id="title" v-model="albumForm.title" required type="text" placeholder="Titel des Albums" class="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                        </div>
                        <div class="relative mt-4">
                          <label for="description" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Beschreibung</label>
                          <textarea id="description" v-model="albumForm.description" required placeholder="Beschreibung" rows="6" class="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></textarea>
                        </div>
                        <RadioGroup v-model="albumForm.online">
                          <div class="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                            <RadioGroupOption as="template" v-for="online in onlineLists" :key="online.id" :value="online.id" v-slot="{ active, checked }">
                              <div :class="[active ? 'border-indigo-600 ring-2 ring-indigo-600' : 'border-gray-200 ring-gray-200  ring-2 ', 'relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none']">
                                <CheckCircleIcon :class="[!checked ? 'invisible' : '', 'h-5 w-5 text-indigo-600']" aria-hidden="true" />
                                <span class="flex flex-1">
                                  <span class="flex flex-col">
                                    <RadioGroupLabel as="span" class="block text-sm font-medium text-gray-900">{{ online.title }}</RadioGroupLabel>
                                  </span>
                                </span>
                                <span :class="[active ? 'border' : 'border-2', checked ? 'border-indigo-600' : 'border-transparent', 'pointer-events-none absolute -inset-px rounded-lg']" aria-hidden="true" />
                              </div>
                            </RadioGroupOption>
                          </div>
                        </RadioGroup>
                      </div>
                    </div>
                  </div>
                  <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button type="submit" class="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto">Speichern</button>
                    <button @click="editModal = false;" type="button"  class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Abbrechen</button>
                  </div>
                </DialogPanel>
              </form>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>
<script>
/* eslint-disable */
import { ChevronLeftIcon, PhotoIcon, ChevronRightIcon, CurrencyEuroIcon, TicketIcon, QrCodeIcon, CheckCircleIcon, ClockIcon, ArrowDownIcon, ArrowUpIcon,   BriefcaseIcon, CalendarIcon, EnvelopeIcon, ChevronDownIcon, LinkIcon, MapPinIcon, PencilSquareIcon, BanknotesIcon, CursorArrowRaysIcon} from '@heroicons/vue/20/solid'
import { Switch, RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption, Menu, MenuButton, MenuItem, MenuItems, Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/vue/24/outline'
import notification from "@/components/notifications/notification";
import alert from "@/components/alerts/alert";
import confirmModal from "@/components/modals/confirmModal";
import {aSyncData, nl2br} from "@/modules/main";
import {configStore} from "@/store/configStore";
import Loader from "@/components/basic/loader.vue";
import MediaUpload from "@/components/media/mediaUpload.vue";
export default {
  name: "universalAlbumDetailView",
  components: {MediaUpload, Loader, ChevronLeftIcon, ChevronRightIcon, TicketIcon, CurrencyEuroIcon, QrCodeIcon,
    notification,alert,RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption,
    CheckCircleIcon, ClockIcon, MapPinIcon, Switch, PencilSquareIcon, BanknotesIcon,
    ArrowDownIcon, ArrowUpIcon,   BriefcaseIcon, CalendarIcon, EnvelopeIcon, ChevronDownIcon, LinkIcon,
    Menu, MenuButton, MenuItem, MenuItems, Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot,
    ExclamationTriangleIcon, XMarkIcon, PhotoIcon, CursorArrowRaysIcon, confirmModal},
  props: ['albumId'],
  setup () {
    const config = configStore();
    return {
      config
    }
  },
  data () {
    return {
      album: {},
      albumForm: {},
      media: [],
      loading: false,
      deleteModal: false,
      deleteMessage: false,
      editModal: false,
      saveMessage: false,
      onlineLists : [
        { id: 0, title: 'Nicht Öffentlich'},
        { id: 1, title: 'Veröffentlicht' },
      ]
    }
  },
  mounted() {
    this.loadAlbum()
  },
  methods: {
    nl2br(str){
      return nl2br(str)
    },
    onCoverChange(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length)
        return;
      this.albumForm.cover = files[0];
    },
    async onMediaChange(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length)
        return;
      for (let i = 0; i< files.length; i++) {
        files[i].status = 'upload'
        this.media.unshift(files[i])
      }
      for (const file of this.media) {
        const index = this.media.indexOf(file);
        if (file.status === 'upload') {
          try {
            const response = await aSyncData('albums/upload', {
              file,
              albumId: this.album.id
            })
            if(response.status === 250) {
              this.media[index] = response.data
            }else{
              this.media[index].status = 'error'
            }
          } catch (e) {
            this.media[index].status = 'error'
          }
        }
      }
      this.$refs.input.value = null;
    },
    loadAlbum () {
      aSyncData('albums/get',{
        organizerId: this.config.organizerId,
        albumId: this.albumId
      })
          .then(r => {
            this.album = {...r.data.album}
            this.albumForm = {...this.album}
            this.media = r.data.media
          })
          .catch(console.log)
    },
    edit() {
      this.loading = true
      aSyncData('albums/edit',{
        organizerId: this.config.organizerId,
        albumId: this.albumId,
        title: this.albumForm.title,
        description: this.albumForm.description,
        online: this.albumForm.online,
        cover: this.albumForm.cover
      })
          .then(r => {
            if(r.status === 250) {
              this.album = {...this.albumForm}
              this.album.cover = r.data.cover
              this.$refs.cover.value = null;
              this.saveMessage = true
              this.editModal = false
            }
          })
          .catch(console.log)
          .finally(() => {this.loading = false})
    },
    deleteAlbum() {
        this.loading = true
        aSyncData('albums/deleteAlbum',{
          organizerId: this.config.organizerId,
          albumId: this.albumId
        })
            .then(r => {
              console.log(r)
              if(r.status === 250) {
                this.deleteMessage = true;
                this.$router.push({name: 'universalMedia'})
              }
            })
            .catch(console.log)
            .finally(() => {
              this.loading = false
            })

    },
    renderTimestampForJS(date){
      const jsTimestamp = date.split(' ')
      const jsDate = jsTimestamp[0].split('-')
      const jsTime = jsTimestamp[1].split(':')
      return jsDate[2] + '.' + jsDate[1] + '.' + jsDate[0] + ' ' + jsTime[0] + ':' + jsTime[1]
    },
    async deleteImage(index) {
      try {
        this.loading = true
        const response = await aSyncData('albums/deleteImage',{
          organizerId: this.config.organizerId,
          albumId: this.albumId,
          file: this.media[index].file
        })
        console.log(response)
        if(response.status === 250) {
          this.media.splice(index, 1);
        }
      }catch (e) {

      }
      this.loading = false
    }
  }
}
</script>
<style scoped>

</style>