<template>
  <div class="bg-neutral-50 h-full">
    <div class="lg:pl-72">
      <main class="py-4">
        <div class="px-4">
          <div class="mb-2">
            <div>
              <nav class="sm:flex" aria-label="Breadcrumb">
                <ol role="list" class="flex items-center space-x-4">
                  <li>
                    <div class="flex">
                      <a href="/dashboard" class="text-sm font-medium text-gray-500 hover:text-gray-700">Hey {{user.firstname}},</a>
                    </div>
                  </li>
                </ol>
              </nav>
            </div>
            <div class="mt-2 md:flex md:items-center md:justify-between">
              <div class="min-w-0 flex-1">
                <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">Willkommen im Dashboard</h2>
              </div>
              <div class="mt-4 flex flex-shrink-0 md:ml-4 md:mt-0">
              </div>
            </div>
          </div>
        </div>
        <div class="grid-12">
          <section class="col-sp-12 col-sd-4 col-ld-4 row-sp-12 mb-4">
            <div class="px-4">
              <div class="border-b border-gray-200 bg-white px-4 pt-5 sm:px-6">
                <div class="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
                  <div class="ml-4 mt-2">
                    <h3 class="text-xl font-semibold leading-6 text-gray-900">Statistiken</h3>
                  </div>
                  <div class="ml-4 mt-2 flex-shrink-0">
                  </div>
                </div>
              </div>
              <div class="bg-white p-4 rounded shadow">
                <ul role="list">
                  <li class="col-span-1 flex rounded-md shadow-sm mt-3">
                    <div class="bg-blue-700 flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white">
                      <CalendarDaysIcon class="h-5 w-5 text-white" aria-hidden="true" />
                    </div>
                    <div class="flex items-center justify-between truncate w-full rounded-r-md border-b border-r border-t border-gray-200 bg-slate-50">
                      <div class="px-4 py-2 text-sm">
                        <a class="font-bold text-gray-900 hover:text-gray-600">
                          Veröffentlichte Events
                        </a>
                        <p class="text-gray-500">
                          {{stat.events}}
                        </p>
                      </div>
                    </div>
                  </li>
                  <li class="col-span-1 flex rounded-md shadow-sm mt-3">
                    <div class="bg-blue-700 flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white">
                      <UserGroupIcon class="h-5 w-5 text-white" aria-hidden="true" />
                    </div>
                    <div class="flex items-center justify-between truncate w-full rounded-r-md border-b border-r border-t border-gray-200 bg-slate-50">
                      <div class="px-4 py-2 text-sm">
                        <a class="font-bold text-gray-900 hover:text-gray-600">
                          Registrierte Nutzer
                        </a>
                        <p class="text-gray-500">
                          {{stat.users}} Nutzer
                        </p>
                      </div>
                    </div>
                  </li>
                  <li class="col-span-1 flex rounded-md shadow-sm mt-3">
                    <div class="bg-blue-700 flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white">
                      <TicketIcon class="h-5 w-5 text-white" aria-hidden="true" />
                    </div>
                    <div class="flex items-center justify-between truncate w-full rounded-r-md border-b border-r border-t border-gray-200 bg-slate-50">
                      <div class="px-4 py-2 text-sm">
                        <a class="font-bold text-gray-900 hover:text-gray-600">
                          Generierte Tickets
                        </a>
                        <p class="text-gray-500">
                          {{stat.tickets}} Stück
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div v-if="user.checkPermission('viewSales')" class="mt-4 px-4">
              <div class="border-b border-gray-200 bg-white px-4 pt-5 sm:px-6">
                <div class="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
                  <div class="ml-4 mt-2">
                    <h3 class="text-xl font-semibold leading-6 text-gray-900">Letzte Transaktionen</h3>
                  </div>
                  <div class="ml-4 mt-2 flex-shrink-0">
                  </div>
                </div>
              </div>
              <div class="bg-white p-4 rounded shadow">
                <ul role="list">
                  <li v-for="sale in salesData" :key="sale.id" class="col-span-1 flex rounded-md shadow-sm mt-3">
                    <div :class="sale.status === 'fulfilled' ? 'bg-green-500' : sale.status === 'pending' ? 'bg-yellow-500' : ''" class=" flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white">
                      <TicketIcon v-if="typ(sale.meta) === 'Tickets'" class="h-5 w-5 text-white" aria-hidden="true" />
                      <svg v-else-if="typ(sale.meta) === 'Getränke'" class="h-8 w-8 text-white" data-name="Layer 2" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="M22.53 2a4.67 4.67 0 0 0-4.66 4.66h-4.35a.93.93 0 0 0 0 1.86h8.08v1.86h-8.83l-5.32-8a.93.93 0 0 0-1.55 1L8 6.68H5.74a.93.93 0 0 0-.93.93v4.65a2.79 2.79 0 0 0 2.8 2.8.91.91 0 0 1 .66.29.9.9 0 0 1 .27.65 2.8 2.8 0 0 0 2.8 2.8h1.86v6.53h-.93a2.8 2.8 0 0 0-2.8 2.8v.93a.93.93 0 0 0 .93.93h7.46a.93.93 0 0 0 .93-.93v-.93a2.8 2.8 0 0 0-2.8-2.8h-.93V18.8h1.87a2.79 2.79 0 0 0 2.8-2.8.94.94 0 0 1 .94-.93 2.8 2.8 0 0 0 2.8-2.8V7.62a.93.93 0 0 0-.93-.93h-2.81a2.8 2.8 0 1 1 5.59 0 2.76 2.76 0 0 1-.69 1.83A.93.93 0 1 0 26 9.75 4.66 4.66 0 0 0 22.53 2Zm-5.6 26.12h-5.59a.93.93 0 0 1 .93-.93H16a.93.93 0 0 1 .93.93Zm3.73-14.92a2.8 2.8 0 0 0-2.8 2.8.93.93 0 0 1-.28.66.89.89 0 0 1-.66.27h-5.58a.93.93 0 0 1-.93-.93 2.81 2.81 0 0 0-2.8-2.8.92.92 0 0 1-.66-.28.89.89 0 0 1-.27-.65h1.87a.93.93 0 0 0 0-1.86H6.68V8.55h2.61l4.07 6.1a.93.93 0 1 0 1.55-1L14 12.28h7.6a.93.93 0 0 1-.94.92Z" fill="#ffffff" class="fill-000000"></path></svg>
                      <CurrencyEuroIcon v-else class="h-5 w-5 text-white" aria-hidden="true" />
                    </div>
                    <div class="flex items-center justify-between truncate w-full rounded-r-md border-b border-r border-t border-gray-200 bg-slate-50">
                      <div class="px-4 py-2 text-sm">
                        <a class="font-bold text-gray-900 hover:text-gray-600">
                          <span class="font-bold">CheckoutID: [{{sale.id}}] </span>
                        </a>
                        <p class="text-gray-500">
                          Umsatzart: {{typ(sale.meta)}}
                        </p>
                        <p class="text-gray-500">
                          <ClockIcon class="h-4 w-4 text-gray-400 inline-flex" aria-hidden="true" />
                          {{renderTimestampForJS(sale.created)}} Uhr
                        </p>
                        <p class="mt-1">
                          <span v-if="sale.status === 'fulfilled'" class="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                            Abgeschlossen: {{ (sale.total).toFixed(2) }}€
                          </span>
                          <span v-else class="inline-flex items-center rounded-full bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-700 ring-1 ring-inset ring-yellow-600/20">
                            Offen: {{ (sale.total).toFixed(2) }}€
                          </span>
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section class="col-sp-12 col-ld-8 row-sp-12 mb-4">
            <div v-if="user.checkPermission('eventManagement')" class="px-4">
              <div class="border-b border-gray-200 bg-white px-4 pt-5 sm:px-6">
                <div class="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
                  <div class="ml-4 mt-2">
                    <h3 class="text-xl font-semibold leading-6 text-gray-900">Kommende Events</h3>
                  </div>
                  <div class="ml-4 mt-2 flex-shrink-0">
                    <router-link to="/events/create" class="relative inline-flex items-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">Event erstellen</router-link>
                  </div>
                </div>
              </div>
              <div class="bg-white p-4 rounded shadow">
                <div class="grid-12">
                  <eventItems v-for="eitems in eventItems" :event-items="eitems" :key="eitems.id" />
                </div>
              </div>
            </div>
            <div class="px-4 mt-4">
              <div class="border-b border-gray-200 bg-white px-4 pt-5 sm:px-6">
                <div class="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
                  <div class="ml-4 mt-2">
                    <h3 class="text-xl font-semibold leading-6 text-gray-900">Aktuelles Feedback</h3>
                  </div>
                  <div class="ml-4 mt-2 flex-shrink-0">
                    <router-link to="/feedback" class="relative inline-flex items-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">Alle ansehen</router-link>
                  </div>
                </div>
              </div>
              <div class="bg-white p-4 rounded shadow">
                <div class="grid-12">
                  <div class="col-sp-12 col-sd-6 row-sp-12" v-for="feedback in feedbacks" :key="feedback.id">
                  </div>
                </div>
                <div class="flow-root">
                  <ul role="list" class="">
                    <li v-for="feedback in feedbacks" :key="feedback.id">
                      <div class="relative pb-8">
                        <span class="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>
                        <div class="relative flex items-start space-x-3">
                          <div class="relative">
                            <img class="flex h-10 w-10 items-center justify-center rounded-full bg-gray-400 ring-8 ring-white" :src="config.projectURL + 'assets/images/users/' + feedback.avatar" alt="">
                            <span class="absolute -bottom-0.5 -right-1 rounded-tl bg-white px-0.5 py-px">
                              <svg class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fill-rule="evenodd" d="M10 2c-2.236 0-4.43.18-6.57.524C1.993 2.755 1 4.014 1 5.426v5.148c0 1.413.993 2.67 2.43 2.902.848.137 1.705.248 2.57.331v3.443a.75.75 0 001.28.53l3.58-3.579a.78.78 0 01.527-.224 41.202 41.202 0 005.183-.5c1.437-.232 2.43-1.49 2.43-2.903V5.426c0-1.413-.993-2.67-2.43-2.902A41.289 41.289 0 0010 2zm0 7a1 1 0 100-2 1 1 0 000 2zM8 8a1 1 0 11-2 0 1 1 0 012 0zm5 1a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
                              </svg>
                            </span>
                          </div>
                          <div class="min-w-0 flex-1">
                            <div>
                              <div class="text-sm">
                                <router-link :to="'/customerInfo/'+feedback.userId" class="font-medium text-gray-900">{{ feedback.firstname }} {{ feedback.lastname }}</router-link>
                              </div>
                              <p class="mt-0.5 text-sm text-gray-500">{{renderTimestampForJS(feedback.timestamp)}} Uhr</p>
                            </div>
                            <div class="mt-2 text-sm text-gray-700">
                              <p class="font-bold">{{feedback.title}} - {{renderDateForJS(feedback.startTime)}}</p>
                              <p>{{feedback.description}}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { authStore } from '@/store/authStore'
import { configStore } from '@/store/configStore'
import { userStore } from '@/store/userStore'
import eventItems from '@/components/dashboard/event'
import {aSyncData} from "@/modules/main";
import {notificationStore} from "@/store/notificationStore";
import {Vue3Lottie} from "vue3-lottie";
import { UserGroupIcon, TagIcon, CurrencyEuroIcon, TicketIcon, ClockIcon, CalendarDaysIcon } from '@heroicons/vue/20/solid'

export default {
  name: 'Dashboard',
  title: 'Dashboard',
  components: {
    Vue3Lottie,
    eventItems: eventItems,
    UserGroupIcon, TagIcon, CurrencyEuroIcon, TicketIcon, ClockIcon, CalendarDaysIcon
  },
  setup () {
    const auth = authStore()
    const config = configStore()
    const user = userStore()
    const notification = notificationStore()

    return { auth, config, user, notification }
  },
  data () {
    return {
      eventItems: [],
      salesData: [],
      stat: [],
      feedbacks: []
    }
  },
  mounted () {
    this.config.loading = false
    this.getData()
    if(this.auth.isAuth) this.notification.pushToken()
  },
  computed: {

  },
  methods: {
    typ(data){
      try {
        const meta = JSON.parse(data)
        switch (meta.type) {
          case 'drinks':
            return 'Getränke'

          case 'tickets':
            return 'Tickets'

          case 'lounges':
            return 'Lounge Reservierung'

          case 'tip':
            return 'Trinkgeld'

          default:
            return 'Sonstiges'
        }
      }catch (e)  {
        return 'Sontiges'
      }
    },
    getData(){
      aSyncData('dashboard/getData', {
        organizerId: this.config.organizerId,
        userId: this.userId
      })
          .then(r => {
            console.log(r)
            if(r.status === 250){
              this.salesData = r.data.sales
              this.eventItems = r.data.events
              this.stat = r.data.stat
              this.feedbacks = r.data.feedbacks
            }
          })
          .catch(e => console.error(e))
    },
    renderTimestampForJS(date){
      const jsTimestamp = date.split(' ')
      const jsDate = jsTimestamp[0].split('-')
      const jsTime = jsTimestamp[1].split(':')
      return jsDate[2] + '.' + jsDate[1] + '.' + jsDate[0] + ' ' + jsTime[0] + ':' + jsTime[1]
    },
    renderDateForJS(date){
      const jsTimestamp = date.split(' ')
      const jsDate = jsTimestamp[0].split('-')
      return jsDate[2] + '.' + jsDate[1] + '.' + jsDate[0]
    },
  }
}
</script>

<style>

</style>
