<template>
  <tr>
    <td class="relative py-4 pr-3 text-sm font-medium text-gray-900">
      {{ coupon.title }}
      <div class="absolute bottom-0 right-full h-px w-screen bg-gray-100" />
      <div class="absolute bottom-0 left-0 h-px w-screen bg-gray-100" />
    </td>
    <td class="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">{{ renderTimestampForJS(coupon.validFrom) }}</td>
    <td class="hidden px-3 py-4 text-sm text-gray-500 md:table-cell">{{ renderTimestampForJS(coupon.validUntil) }}</td>
    <td class="px-3 py-4 text-sm text-gray-500">{{ coupon.code }}</td>
    <td class="relative py-4 pl-3 text-right text-sm font-medium">
      <button class="text-indigo-600 hover:text-indigo-900" @click="$router.push({name: 'Coupon', params: {couponId: coupon.id}})">Bearbeiten</button>
    </td>
  </tr>
</template>

<script>

export default {
  name: "couponList",
  props: {
    coupon: Object
  },
  data(){
    return{
    }
  },
  methods:{
    renderTimestampForJS(date){
      const jsTimestamp = date.split(' ')
      const jsDate = jsTimestamp[0].split('-')
      const jsTime = jsTimestamp[1].split(':')
      return jsDate[2] + '.' + jsDate[1] + '.' + jsDate[0] + ' ' + jsTime[0] + ':' + jsDate[1] + ' Uhr'
    },
  }
}
</script>

<style scoped>



</style>