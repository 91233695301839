<template>
  <div class="bg-neutral-50 h-full">
    <div class="lg:pl-72">
      <main class="py-4">
        <div class="px-4">
          <div class="mb-2">
            <div>
              <nav class="hidden sm:flex" aria-label="Breadcrumb">
                <ol role="list" class="flex items-center space-x-4">
                  <li>
                    <div class="flex">
                      <a href="/fundgrube" class="text-sm font-medium text-gray-500 hover:text-gray-700">Übersicht</a>
                    </div>
                  </li>
                </ol>
              </nav>
            </div>
            <div class="mt-2 md:flex md:items-center md:justify-between">
              <div class="min-w-0 flex-1">
                <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">Fundgrube</h2>
              </div>
              <div class="mt-4 flex flex-shrink-0 md:ml-4 md:mt-0">
              </div>
            </div>
          </div>
          <section>
            <div class="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
              <h3 class="text-base font-semibold leading-6 text-gray-900 mb-4">Offene Anfragen</h3>
              <ul role="list" class="grid grid-cols-1 gap-2 sm:grid-cols-2 lg:grid-cols-3">
                <li v-for="item in fundgrubeItems0" :key="item.id" class="col-span-1 divide-y divide-gray-200 rounded-lg bg-slate-50 shadow">
                  <div class="flex w-full items-center justify-between space-x-6 p-6">
                    <div class="flex-1 truncate">
                      <span class="mt-1 inline-flex flex-shrink-0 items-center rounded-full bg-indigo-50 px-1.5 py-0.5 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-600/20">{{ item.category }}</span>
                      <div class="flex items-center space-x-3">
                        <h3 class="text-m font-bold text-gray-900" style="text-wrap: wrap;">{{ renderTimestampForJS(item.startTime) }} - {{ item.title }}</h3>
                      </div>
                      <p class="text-sm text-gray-500" style="text-wrap: wrap;">{{ item.message }}</p>
                      <p class="truncate mt-1 text-xs text-gray-500 mt-2"><strong>Name:</strong> {{ item.firstname }} {{ item.lastname }}</p>
                      <p class="truncate mt-1 text-xs text-gray-500"><strong>E-Mail:</strong> <a :href="'mailto:'+item.email">{{ item.email }}</a></p>
                      <p class="truncate mt-1 text-xs text-gray-500"><strong>Telefon:</strong> <a :href="'tel:'+item.phone">{{ item.phone }}</a></p>
                    </div>
                  </div>
                  <div>
                    <div class="-mt-px flex divide-x divide-gray-200">
                      <div class="flex w-0 flex-1">
                        <button @click="updateStatus(item.id,3)" class="relative hover:bg-slate-100 -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900">
                          <XCircleIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                          Ablehnen
                        </button>
                      </div>
                      <div class="-ml-px flex w-0 flex-1">
                        <button @click="updateStatus(item.id, 1)" class="relative hover:bg-slate-100 inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900">
                          <CheckCircleIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                          Fund bestätigen
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              <p v-if="fundgrubeItems0.length === 0" class="truncate mt-1 text-xs text-gray-500 text-center">
                Zurzeit gibt es keine offenen Anfragen
              </p>
            </div>
            <div class="mt-4 border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
              <h3 class="text-base font-semibold leading-6 text-gray-900 mb-4">Wird abgeholt</h3>
              <ul role="list" class="grid grid-cols-1 gap-2 sm:grid-cols-2 lg:grid-cols-3">
                <li v-for="item in fundgrubeItems1" :key="item.id" class="col-span-1 divide-y divide-gray-200 rounded-lg bg-yellow-50 shadow">
                  <div class="flex w-full items-center justify-between space-x-6 p-6">
                    <div class="flex-1 truncate">
                      <span class="mt-1 inline-flex flex-shrink-0 items-center rounded-full bg-yellow-100 px-1.5 py-0.5 text-xs font-medium text-yellow-700 ring-1 ring-inset ring-yellow-600/20">{{ item.category }}</span>
                      <div class="flex items-center space-x-3">
                        <h3 class="truncate text-m font-bold text-gray-900">{{ renderTimestampForJS(item.startTime) }} - {{ item.title }}</h3>
                      </div>
                      <p class="truncate text-sm text-gray-500">{{ item.message }}</p>
                      <p class="truncate mt-1 text-xs text-gray-500 mt-2"><strong>Name:</strong> {{ item.firstname }} {{ item.lastname }}</p>
                      <p class="truncate mt-1 text-xs text-gray-500"><strong>E-Mail:</strong> <a :href="'mailto:'+item.email">{{ item.email }}</a></p>
                      <p class="truncate mt-1 text-xs text-gray-500"><strong>Telefon:</strong> <a :href="'tel:'+item.phone">{{ item.phone }}</a></p>
                    </div>
                  </div>
                  <div>
                    <div class="-mt-px flex divide-x divide-gray-200">
                      <div class="flex w-0 flex-1">
                        <button @click="deleteState(item.id)" class="relative hover:bg-yellow-100 -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900">
                          <TrashIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                          Löschen
                        </button>
                      </div>
                      <div class="-ml-px flex w-0 flex-1">
                        <button @click="updateStatus(item.id, 2)" class="relative hover:bg-yellow-100 inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900">
                          <CheckCircleIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                          Wurde abgeholt
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="mt-4 border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
              <h3 class="text-base font-semibold leading-6 text-gray-900 mb-4">Wurde abgeholt</h3>
              <ul role="list" class="grid grid-cols-1 gap-2 sm:grid-cols-2 lg:grid-cols-3">
                <li v-for="item in fundgrubeItems2" :key="item.id" class="col-span-1 divide-y divide-gray-200 rounded-lg bg-green-50 shadow">
                  <div class="flex w-full items-center justify-between space-x-6 p-6">
                    <div class="flex-1 truncate">
                      <span class="mt-1 inline-flex flex-shrink-0 items-center rounded-full bg-green-100 px-1.5 py-0.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">{{ item.category }}</span>
                      <div class="flex items-center space-x-3">
                        <h3 class="truncate text-m font-bold text-gray-900">{{ renderTimestampForJS(item.startTime) }} - {{ item.title }}</h3>
                      </div>
                      <p class="truncate text-sm text-gray-500">{{ item.message }}</p>
                      <p class="truncate mt-1 text-xs text-gray-500 mt-2"><strong>Name:</strong> {{ item.firstname }} {{ item.lastname }}</p>
                      <p class="truncate mt-1 text-xs text-gray-500"><strong>E-Mail:</strong> <a :href="'mailto:'+item.email">{{ item.email }}</a></p>
                      <p class="truncate mt-1 text-xs text-gray-500"><strong>Telefon:</strong> <a :href="'tel:'+item.phone">{{ item.phone }}</a></p>
                    </div>
                  </div>
                  <div>
                    <div class="-mt-px flex divide-x divide-gray-200">
                      <div class="-ml-px flex w-0 flex-1">
                        <button @click="deleteState(item.id)" class="relative hover:bg-green-100 -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900">
                          <TrashIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                          Löschen
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="mt-4 border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
              <h3 class="text-base font-semibold leading-6 text-gray-900 mb-4">Abgelehnte Abfragen</h3>
              <ul role="list" class="grid grid-cols-1 gap-2 sm:grid-cols-2 lg:grid-cols-3">
                <li v-for="item in fundgrubeItems3" :key="item.id" class="col-span-1 divide-y divide-gray-200 rounded-lg bg-red-50 shadow">
                    <div class="flex w-full items-center justify-between space-x-6 p-6">
                      <div class="flex-1 truncate">
                        <span class="mt-1 inline-flex flex-shrink-0 items-center rounded-full bg-red-100 px-1.5 py-0.5 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/20">{{ item.category }}</span>
                        <div class="flex items-center space-x-3">
                          <h3 class="truncate text-m font-bold text-gray-900">{{ renderTimestampForJS(item.startTime) }} - {{ item.title }}</h3>
                        </div>
                        <p class="truncate text-sm text-gray-500">{{ item.message }}</p>
                        <p class="truncate mt-1 text-xs text-gray-500 mt-2"><strong>Name:</strong> {{ item.firstname }} {{ item.lastname }}</p>
                        <p class="truncate mt-1 text-xs text-gray-500"><strong>E-Mail:</strong> <a :href="'mailto:'+item.email">{{ item.email }}</a></p>
                        <p class="truncate mt-1 text-xs text-gray-500"><strong>Telefon:</strong> <a :href="'tel:'+item.phone">{{ item.phone }}</a></p>
                      </div>
                    </div>
                    <div>
                      <div class="-mt-px flex divide-x divide-gray-200">
                        <div class="-ml-px flex w-0 flex-1">
                          <button @click="deleteState(item.id)" class="relative hover:bg-red-100 -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900">
                            <TrashIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                            Löschen
                          </button>
                        </div>
                      </div>
                    </div>
                </li>
              </ul>
            </div>
          </section>
        </div>
      </main>
    </div>
    <notification class="my-4" v-if="saveMessage" type="success" title="Speichern erfolgreich" message="Die Änderungen wurden erfolgreich übernommen."/>
    <notification class="my-4" v-if="deleteMessage" type="success" title="Löschen erfolgreich" message="Der Eintrag wurde erfolgreich gelöscht."/>

  </div>
</template>

<script>

import { XCircleIcon, CheckCircleIcon,TrashIcon } from '@heroicons/vue/20/solid'
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import notification from "@/components/notifications/notification";

export default {
  name: "Fundgrube",
  title: "Fundgrube",
  components: {
    notification,
    XCircleIcon, CheckCircleIcon, TrashIcon
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      saveMessage: false,
      name: '',
      phone: '',
      fundgrubeItems0: [],
      fundgrubeItems1: [],
      fundgrubeItems2: [],
      fundgrubeItems3: [],
      deleteMessage: false

    }
  },
  mounted() {
    this.config.loading = false
    this.getFundgrubeItems()

  },
  methods: {

    deleteState(id){
      this.saveMessage = false
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('id', id)
      this.axios.post('fundgrube/delete', fD)
          .then((r) => {
            console.log(r.status, r.data)
            if(r.status === 250){
              _this.getFundgrubeItems()
              _this.deleteMessage = true
            }
          })
    },
    getFundgrubeItems(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('fundgrube/getFundgrubeItems', fD)
          .then((response) => {
            console.log(response)
            if(response.status === 250){
              _this.fundgrubeItems0 = response.data.status0
              _this.fundgrubeItems1 = response.data.status1
              _this.fundgrubeItems2 = response.data.status2
              _this.fundgrubeItems3 = response.data.status3

            }
          })
    },
    getById(id){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('id', id)
      this.axios.post('fundgrube/getById', fD)
          .then((response) => {
            if(response.status === 250){
              _this.taxiId = response.data.id
              _this.name = response.data.name
              _this.phone = response.data.phone
            }
          })
    },
    renderTimestampForJS(date){
      const jsTimestamp = date.split(' ')
      const jsDate = jsTimestamp[0].split('-')
      return jsDate[2] + '.' + jsDate[1] + '.' + jsDate[0]
    },
    updateStatus(id, status){
      this.saveMessage = false
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('id', id)
      fD.append('status', status)
      this.axios.post('fundgrube/updateStatus', fD)
          .then((r) => {
            console.log(r.status, r.data)
            if(r.status === 250){
              _this.getFundgrubeItems()
              _this.saveMessage = true
            }
          })
    },
  }
}
</script>

<style>
  .disabled{
    background-color: grey !important;
  }
</style>