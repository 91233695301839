<template>
  <div class="bg-neutral-50 h-full">
    <div class="lg:pl-72">
      <main class="py-4">
        <div class="px-4">
          <div class="mb-2">
            <div>
              <nav class="hidden sm:flex" aria-label="Breadcrumb">
                <ol role="list" class="flex items-center space-x-4">
                  <li>
                    <div class="flex">
                      <a href="/taxizentrale" class="text-sm font-medium text-gray-500 hover:text-gray-700">Übersicht</a>
                    </div>
                  </li>
                </ol>
              </nav>
            </div>
            <div class="mt-2 md:flex md:items-center md:justify-between">
              <div class="min-w-0 flex-1">
                <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">Taxizentrale</h2>
              </div>
              <div class="mt-4 flex flex-shrink-0 md:ml-4 md:mt-0">
              </div>
            </div>
          </div>
          <div class="grid-12">
            <div class="col-sp-12 col-sd-8 row-sp-12">
              <div class="bg-white p-4 rounded shadow">
                <h4>
                  {{ taxiId === 0 ? 'Erstellen' : 'Bearbeiten' }}
                </h4>
                <div class="grid-12">
                  <div class="col-sp-12 row-sp-12">
                    <div class="relative mt-4">
                      <label for="name" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Taxi-Name</label>
                      <input v-model="name" type="text" name="name" id="name" required class="px-2 text-l block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6" placeholder="Club Taxi" />
                    </div>
                    <div class="relative mt-4">
                      <label for="phone" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Telefonnummer</label>
                      <input v-model="phone" type="tel" name="phone" id="phone" required class="px-2 text-l block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6" placeholder="+49123456789" />
                    </div>
                    <div class="mt-4 flex flex-row-reverse">
                      <button @click="save" v-if="name && phone" class="relative inline-flex items-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
                        {{ saveMessage ? 'Gespeichert' : (taxiId > 0 ? 'Speichern' : 'Erstellen') }}
                      </button>
                      <button @click="deleteItem(taxiId)" v-if="taxiId" class="relative mr-2 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                        Löschen
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sp-12 col-sd-4 row-sp-12 ml-2">
              <ul role="list" class="divide-y divide-gray-100 overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl">
                <li>
                  <div class="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
                    <div class="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
                      <div class="ml-4 mt-2">
                        <h3 class="text-base font-normal text-xl leading-6 text-gray-900">Taxi-Angebote</h3>
                      </div>
                      <div class="ml-4 mt-2 flex-shrink-0">
                        <button @click="create" class="relative inline-flex items-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
                          Erstellen
                        </button>
                      </div>
                    </div>
                    <ul role="list" class="mt-3 gap-5">
                      <li v-for="taxi in taxiItems" :key="taxi.id" class="col-span-1 flex rounded-md shadow-sm mb-2">
                        <div class="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-slate-50">
                          <div class="flex-1 truncate px-4 py-2 text-sm">
                            <a class="font-medium text-gray-900 hover:text-gray-600">{{ taxi.name }}</a>
                            <p class="text-gray-500">{{ taxi.phone }}</p>
                          </div>
                          <div class="flex-shrink-0 pr-2">
                            <button @click="getById(taxi.id)" type="button" class="inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                              <span class="sr-only">Open options</span>
                              <PencilSquareIcon class="h-5 w-5" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </main>
    </div>
    <notification class="my-4" v-if="saveMessage" type="success" title="Speichern erfolgreich" message="Die Änderungen wurden erfolgreich übernommen."/>
    <notification class="my-4" v-if="deleteMessage" type="success" title="Löschen erfolgreich" message="Der Eintrag wurde erfolgreich gelöscht."/>

  </div>
</template>

<script>


import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import notification from "@/components/notifications/notification";
import { PencilSquareIcon } from '@heroicons/vue/20/solid'

export default {
  name: "Taxizentrale",
  title: "Taxizentrale",
  components: {
    notification,
    PencilSquareIcon
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      saveMessage: false,
      taxiId: 0,
      name: '',
      phone: '',
      taxiItems: [],
      deleteMessage: false

    }
  },
  mounted() {
    this.config.loading = false
    this.getTaxiItems()

  },
  methods: {

    create(){
      this.taxiId = 0;
      this.name = '';
      this.phone = '';
    },
    save(){
      this.saveMessage = false
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('id', this.taxiId)
      fD.append('name', this.name)
      fD.append('phone', this.phone)
      this.axios.post('taxizentrale/save', fD)
          .then((r) => {
            console.log(r.status, r.data)
            if(r.status === 250){
              _this.name = ''
              _this.phone = ''
              _this.getTaxiItems()
              _this.saveMessage = true
            }
          })
    },
    getTaxiItems(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('taxizentrale/getTaxiItems', fD)
          .then((response) => {
            if(response.status === 250){
              _this.taxiItems = response.data
            }
          })
    },
    getById(id){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('id', id)
      this.axios.post('taxizentrale/getById', fD)
          .then((response) => {
            if(response.status === 250){
              _this.taxiId = response.data.id
              _this.name = response.data.name
              _this.phone = response.data.phone
            }
          })
    },
    deleteItem(id){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('id', id)
      this.axios.post('taxizentrale/delete', fD)
          .then((response) => {
            console.log(response)
            if(response.status === 250){
              _this.taxiId = 0
              _this.name = ''
              _this.phone = ''
              _this.getTaxiItems()
              _this.deleteMessage = true
            }
          })
    }
  }
}
</script>

<style>
  .disabled{
    background-color: grey !important;
  }
</style>