<template>
  <tr>
    <td class="relative py-4 pr-3 text-sm font-medium text-gray-900">
      [{{customer.userId}}] <span class="font-bold">{{ customer.firstname }} {{ customer.lastname }}</span>
      <div class="absolute bottom-0 right-full h-px w-screen bg-gray-100" />
      <div class="absolute bottom-0 left-0 h-px w-screen bg-gray-100" />
    </td>
    <td class="hidden px-3 py-4 text-sm text-gray-500 md:table-cell"> {{ reduced.lastSale }} Uhr</td>
    <td class="px-3 py-4 text-sm text-gray-500 text-right">{{ (-reduced.sales/100).toFixed(2) }}€</td>
    <td class="py-4 pl-3 text-right text-sm">
      <button class="button tiny color-white" @click="$router.push({name: 'Customer', params: {userId: customer.userId}})" v-if="customer.userId">
        Info
      </button>
    </td>
  </tr>
</template>

<script>
/* eslint-disable */
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";
import {stringToDate, dateToString} from "@/modules/main";
import SaleEvents from "@/components/sales/saleEvents";

export default {
  name: "SalesByUserComp",
  components: {SaleEvents},
  props: {
    customer: Object
  },
  emits: ['userInfo'],
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
      expanded: false
    }
  },
  computed: {
    reduced(){
      return {
        'sales': Object.values(this.customer.events).reduce((a, c) => {
          return a + c.sales.reduce((a2, c) => {
            return a2 + c.amount
          }, 0)
        }, 0),
        'lastSale': dateToString(new Date(Math.max(... Object.values(this.customer.events).map(c => {
          return c.lastSale
        }))))
      }
    },
  },
  mounted() {
    this.config.loading = false
  },
  methods: {

  }
}
</script>

<style scoped>


</style>