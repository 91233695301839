import {reactive} from "vue";
import {defineStore} from "pinia";

export const mainStore = defineStore('main',{
    state:()=>({
        sidebarOpen:true
    })

});

