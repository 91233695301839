<template>
  <div class="bg-neutral-50 h-full">
    <div class="lg:pl-72">
      <main class="py-4" id="notificationService">
        <router-view />
      </main>
    </div>
  </div>
</template>

<script>

export default {
  name: "NotificationService",
  title: "Nachrichtendienst",
}
</script>

<style scoped>
</style>