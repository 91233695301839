<template>
  <div class="bg-neutral-50 h-full">
    <div class="lg:pl-72">
      <main class="py-4" id="ticketcenter">
        <router-view />
      </main>
    </div>
  </div>
</template>

<script>


import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";

export default {
  name: "Ticketcenter",
  title: "Ticketcenter",
  components: {
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
    }
  },
  mounted() {
    this.config.loading = false
  },
  methods: {

  }
}
</script>

<style>
</style>