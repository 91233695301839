<template>
  <div class="bg-neutral-50 h-full">
    <div class="lg:pl-72">
      <main class="py-4">
        <div class="px-4">
          <div class="mb-2">
            <div>
              <nav class="hidden sm:flex" aria-label="Breadcrumb">
                <ol role="list" class="flex items-center space-x-4">
                  <li>
                    <div class="flex">
                      <a href="/checkin" class="text-sm font-medium text-gray-500 hover:text-gray-700">Übersicht</a>
                    </div>
                  </li>
                </ol>
              </nav>
            </div>
            <div class="mt-2 md:flex md:items-center md:justify-between">
              <div class="min-w-0 flex-1">
                <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">CheckIn System</h2>
              </div>
              <div class="mt-4 flex flex-shrink-0 md:ml-4 md:mt-0">
              </div>
            </div>
          </div>
          <section class="mb-4">
            <div>
              <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2">
                <div class="relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6">
                  <dt>
                    <div class="absolute rounded-md bg-blue-500 p-3">
                      <svg class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
                      </svg>
                    </div>
                    <p class="ml-16 truncate text-sm font-medium text-gray-500">CheckIns in den letzten 24 Stunden</p>
                  </dt>
                  <dd class="ml-16 flex items-baseline">
                    <p class="text-2xl font-semibold text-gray-900">{{stat.today}}</p>
                  </dd>
                </div>
                <div class="relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6">
                  <dt>
                    <div class="absolute rounded-md bg-blue-500 p-3">
                      <svg class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
                      </svg>
                    </div>
                    <p class="ml-16 truncate text-sm font-medium text-gray-500">CheckIns Gesamt</p>
                  </dt>
                  <dd class="ml-16 flex items-baseline">
                    <p class="text-2xl font-semibold text-gray-900">{{stat.allTime}}</p>
                  </dd>
                </div>
              </dl>
            </div>
          </section>
          <div class="grid-12">
            <div class="col-sp-12 col-sd-8 row-sp-12">
              <div class="bg-white p-4 rounded shadow  mb-4">
                <h3 class="text-base font-normal text-xl leading-6 text-gray-900">Events</h3>
              </div>
              <div class="mb-20">
                <ul role="list" class="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-2 xl:gap-x-8">
                  <li v-for="event in events" :key="event.id" class="overflow-hidden rounded-xl border border-gray-200 bg-white">
                    <div class="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50">
                      <img :src="config.projectURL + 'assets/images/events/'+event.image+'.jpg'">
                    </div>
                    <div class="mt-4 text-l font-bold leading-6 text-gray-900 px-6">
                      {{event.title}}
                    </div>
                    <dl class="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                      <div class="flex justify-between gap-x-4 py-3">
                        <dt class="text-gray-500">Event-Datum</dt>
                        <dd class="text-gray-700"><time>{{renderDateForJS(event.startTime)}}</time></dd>
                      </div>
                      <div class="flex justify-between gap-x-4 py-3">
                        <dt class="text-gray-500">Anzahl der CheckIns</dt>
                        <dd class="flex items-start gap-x-2">
                          <div class="font-medium text-gray-900">{{event.checkInCount}}</div>
                        </dd>
                      </div>
                      <button @click="loadMiniQRCode(event.id)" type="button" class="w-full mb-4 rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">CheckIn QR-Code anzeigen</button>
                      <div v-if="clickedQR === event.id">
                        <img :id="'QRCode'+event.id" class="pad-16 br-10 bgcolor-white mx-auto">
                        <button @click="downloadImage(event.id)" type="button" class="w-full mb-4 rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">QR-Code downloaden</button>
                      </div>
                    </dl>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-sp-12 col-sd-4 row-sp-12 ml-2">
              <ul role="list" class="divide-y divide-gray-100 overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl">
                <li>
                  <div class="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
                    <div class="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
                      <div class="ml-4 mt-2">
                        <h3 class="text-base font-normal text-xl leading-6 text-gray-900">Letzte CheckIns</h3>
                      </div>
                    </div>
                    <ul role="list" class="mt-3 gap-5">
                      <li v-for="checkInUser in last100" :key="checkInUser.id" class="col-span-1 flex rounded-md shadow-sm mb-2 py-2">
                        <a :href="'/customerinfo/'+checkInUser.userId" class="group block flex-shrink-0">
                          <div class="flex items-center">
                            <div>
                              <img class="inline-block h-9 w-9 rounded-full bg-slate-200" :src="config.projectURL + 'assets/images/users/' + checkInUser.avatar" alt="">
                            </div>
                            <div class="ml-3">
                              <p class="text-sm font-medium text-gray-700 group-hover:text-gray-900">{{checkInUser.firstname}} {{checkInUser.lastname}}</p>
                              <p class="text-xs font-medium text-gray-500 group-hover:text-gray-700">{{checkInUser.title}}</p>
                              <p class="text-xs font-medium text-gray-500 group-hover:text-gray-700">{{ renderTimestampForJS(checkInUser.timestamp) }} Uhr</p>
                            </div>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </main>
    </div>
    <notification class="my-4" v-if="saveMessage" type="success" title="Speichern erfolgreich" message="Die Änderungen wurden erfolgreich übernommen."/>
    <notification class="my-4" v-if="deleteMessage" type="success" title="Löschen erfolgreich" message="Der Eintrag wurde erfolgreich gelöscht."/>

  </div>
</template>

<script>
/* eslint-disable */
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import notification from "@/components/notifications/notification";
import { PencilSquareIcon } from '@heroicons/vue/20/solid'
import Qrious from "qrious";


export default {
  name: "Checkin",
  title: "Checkin",
  components: {
    notification,
    PencilSquareIcon
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      last100: [],
      stat: [],
      events:[],
      deleteMessage: false,
      clickedQR: ''

    }
  },
  mounted() {
    this.config.loading = false
    this.getData()
  },
  methods: {
    downloadImage(id) {
      const link = document.createElement('a');
      const element = document.getElementById('QRCode'+id).src;
      link.href = element;
      link.download = 'QRCode-'+id+'.jpg';

      link.click();
    },
    loadMiniQRCode(id) {
      this.clickedQR = id;
      this.$nextTick(() => {
        new Qrious({
          element: document.getElementById('QRCode'+id),
          size: 250,
          value: this.config.projectURLApp+'tickets?id='+id,
          background: '#ffffff',
          foreground: '#000000',
          level: 'H',
        })
      })
    },
    getData(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('organizerId', this.config.organizerId)
      this.axios.post('checkin/getData', fD)
          .then((response) => {
            console.log(response)
            if(response.status === 250){
              _this.last100 = response.data.last100
              _this.stat = response.data.stat
              _this.events = response.data.events

            }
          })
    },
    renderTimestampForJS(date){
      const jsTimestamp = date.split(' ')
      const jsDate = jsTimestamp[0].split('-')
      const jsTime = jsTimestamp[1].split(':')
      return jsDate[2] + '.' + jsDate[1] + '.' + jsDate[0] + ' ' + jsTime[0] + ':' + jsTime[1]
    },
    renderDateForJS(date){
      const jsTimestamp = date.split(' ')
      const jsDate = jsTimestamp[0].split('-')
      return jsDate[2] + '.' + jsDate[1] + '.' + jsDate[0]
    },


  }
}
</script>

