<template>
  <div class="px-4">
    <div class="mb-2">
      <div>
        <nav class="sm:hidden" aria-label="Back">
          <a href="/events" class="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700">
            <ChevronLeftIcon class="-ml-1 mr-1 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
            Zurück
          </a>
        </nav>
        <nav class="hidden sm:flex" aria-label="Breadcrumb">
          <ol role="list" class="flex items-center space-x-4">
            <li>
              <div class="flex">
                <a href="/events" class="text-sm font-medium text-gray-500 hover:text-gray-700">Events</a>
              </div>
            </li>
            <li>
              <div class="flex items-center">
                <ChevronRightIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                <a class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">Medienverwaltung</a>
              </div>
            </li>
          </ol>
        </nav>
      </div>
      <div class="mt-2 md:flex md:items-center md:justify-between">
        <div class="min-w-0 flex-1">
          <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">Medienverwaltung</h2>
        </div>
        <div class="mt-4 flex flex-shrink-0 md:ml-4 md:mt-0">
        </div>
      </div>
    </div>
    <div>
      <div class="mt-4 mb-8">
        <div @drop.prevent="fileDrop" @dragenter.prevent @dragover.prevent @click="$refs.file.click()" class="dropZone cursor-pointer relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
          <svg class="mx-auto h-12 w-12 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
          </svg>
          <span class="mt-2 block text-sm font-semibold text-gray-900">Medien hier ablegen oder zum Auswählen klicken</span>
        </div>

      </div>
      <input type="file" @change="fileDrop" accept=".jpeg, .jpg, .png, .mp4, .webm, .webp" multiple style="display: none" ref="file">
      <VueModal :show="formatModal" approve="ok" @approve="formatModal = false" @close="formatModal = false">
        Es werden nur folgende Formate unterstützt: JPG, PNG, WebP, WebM sowie MP4
      </VueModal>
      <loader v-if="loading" />
      <div class="mediaContainer" v-else>
        <h3 class="font-bold text-xl">
          Medien
        </h3>
        <div class="mt-4">
          <div class="grid-12">
            <media v-for="singleMedia in media" :key="singleMedia.name" :upload="singleMedia" :event-id="eventId" @delete-media="deleteMedia" />
          </div>
          <p class="text-gray-500 text-sm" v-if="!media.length">Es sind noch keine Medien zu diesem Event vorhanden</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import Loader from "@/components/basic/loader";
import Media from "@/components/events/media";
import { ChevronLeftIcon, ChevronRightIcon, CurrencyEuroIcon, TicketIcon, QrCodeIcon, CheckCircleIcon, ClockIcon, ArrowDownIcon, ArrowUpIcon,   BriefcaseIcon, CalendarIcon, EnvelopeIcon, ChevronDownIcon, LinkIcon, MapPinIcon, PencilSquareIcon, BanknotesIcon  } from '@heroicons/vue/20/solid'
import notification from "@/components/notifications/notification";
import alert from "@/components/alerts/alert";
import { RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption, Menu, MenuButton, MenuItem, MenuItems, Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/vue/24/outline'

export default {
  name: "eventMedia",
  title: 'Eventmedia',
  props: {
    eventId: Number
  },
  components: {
    ChevronLeftIcon, ChevronRightIcon, TicketIcon, CurrencyEuroIcon, QrCodeIcon,
    notification,alert,RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption,
    CheckCircleIcon, ClockIcon,ArrowDownIcon, ArrowUpIcon,  BriefcaseIcon,
    CalendarIcon, EnvelopeIcon, ChevronDownIcon, LinkIcon, MapPinIcon, PencilSquareIcon, BanknotesIcon,
    Menu, MenuButton, MenuItem, MenuItems, Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot,
    ExclamationTriangleIcon, XMarkIcon, Media, Loader
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      loading: true,
      formatModal: false,
      media: []
    }
  },
  mounted() {
    this.loadMedia()
  },
  methods: {
    loadMedia(){
      const _this = this
      let fD = new FormData();
      fD.append('sessionID', this.auth.sessionID);
      fD.append('eventId', this.eventId);
      this.axios.post('events/getMedia',fD)
          .then(r => {
            if(r.status === 250){
              _this.media = r.data
              _this.loading = false
            }
          })
          .catch(e => {
            console.log(e)
          })
    },
    deleteMedia(name){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('eventId', this.eventId)
      fD.append('fileName', name)
      this.axios.post('events/deleteMedia', fD)
          .then(r => {
            if(r.status === 250){
              _this.media = _this.media.filter(e => {
                return e.name !== name
              })
            }
          })
    },
    fileDrop(e){
      let tempFiles = e.target.files || e.dataTransfer.files
      let files = [];

      for(let i = 0; i < tempFiles.length; i++){
        if(!['jpg', 'jpeg', 'gif', 'png', 'webp', 'mp4', 'webm'].includes(tempFiles[i].name.split('.').pop().toLowerCase())){
          this.formatModal = true
        }else{
          files.push(tempFiles[i])
        }
      }

      for(let i = files.length - 1; i >= 0; i--){
        this.media.unshift({name: files[i].name})
      }

      for(let i = 0; i < files.length; i++){
        let fD = new FormData();
        fD.append('sessionID', this.auth.sessionID);
        fD.append('eventId', this.eventId);
        fD.append('upload', files[i]);
        this.axios.post('events/upload',fD,
            {
              headers: {
                'Content-Type': 'multipart/form-data'
              },
              onUploadProgress: (progressEvent) => {
                const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
                this.media[i].progress = Math.floor(progressEvent.loaded*90 / totalLength)
                console.log(Math.floor(progressEvent.loaded*90 / totalLength))
              }
            }
        )
            .then(r => {
              console.log(r)
              if(r.status === 250){
                console.log(this.media, this.media[i], i)
                this.media[i] = {
                  name: r.data.name,
                  extension: r.data.extension,
                  uploaded: true,
                  progress: 100
                }
              }

            })
            .catch(e => {
              console.log(e)
              this.media[i].progress = 0
              this.media[i].fail = true
            })
      }
    }
  }
}
</script>

<style scoped>

</style>