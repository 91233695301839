<template>
  <div class="bg-neutral-50 h-full">
    <div class="lg:pl-72">
      <main class="py-4" id="lounge">
        <div class="px-4">
          <div class="mb-2">
            <div>
              <nav class="hidden sm:flex" aria-label="Breadcrumb">
                <ol role="list" class="flex items-center space-x-4">
                  <li>
                    <div class="flex">
                      <a href="/lounges" class="text-sm font-medium text-gray-500 hover:text-gray-700">Übersicht</a>
                    </div>
                  </li>
                </ol>
              </nav>
            </div>
            <div class="mt-2 md:flex md:items-center md:justify-between">
              <div class="min-w-0 flex-1">
                <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">Lounges</h2>
              </div>
              <div class="mt-4 flex flex-shrink-0 md:ml-4 md:mt-0">
                <button @click="newModal = !newModal" type="button" class="ml-4 h-10 items-center justify-center rounded-lg px-6 text-sm sm:flex bg-blue-600 font-semibold text-white hover:bg-blue-700">
                  Lounge erstellen
                </button>
              </div>
            </div>
          </div>
          <div>
            <div class="bg-white rounded shadow p-4 grid-12">
              <lounge v-for="lounge in lounges" :key="lounge.id" :lounge="lounge" @edit-lounge="editLounge" @delete-lounge="deleteLounge" />
            </div>
          </div>
        </div>
      </main>
    </div>
    <TransitionRoot as="template" :show="newModal">
      <Dialog as="div" class="relative z-10" @close="resetLoungeForm()">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>
        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <form @submit.prevent="addLounge()">
                  <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <button type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" @click="resetLoungeForm()">
                      <span class="sr-only">Close</span>
                      <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div class="sm:flex sm:items-start">
                    <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                      <svg class="h-6 w-6 text-blue-600" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                        <path d="M29 18.18v-2.84A3.35 3.35 0 0 0 25.66 12h-7.32A3.34 3.34 0 0 0 16 13a3.34 3.34 0 0 0-2.34-1H6.34A3.35 3.35 0 0 0 3 15.34v2.84A3 3 0 0 0 1 21v7a1 1 0 0 0 1 1h1v1a1 1 0 0 0 2 0v-1h22v1a1 1 0 0 0 2 0v-1h1a1 1 0 0 0 1-1v-7a3 3 0 0 0-2-2.82ZM18.34 14h7.32A1.34 1.34 0 0 1 27 15.34v2.83h-.07a2.86 2.86 0 0 0-.43.21l-.15.08a2.68 2.68 0 0 0-.49.4l-.09.12a2.88 2.88 0 0 0-.3.39l-.11.19a2.2 2.2 0 0 0-.17.39.61.61 0 0 0-.05.12h-.08a2.39 2.39 0 0 0-.66-.1H17v-4.63A1.34 1.34 0 0 1 18.34 14ZM7 22.6a.6.6 0 0 1 .6-.6H15v1H7Zm10-.6h7.4a.6.6 0 0 1 .6.6v.4h-8ZM5 15.34A1.34 1.34 0 0 1 6.34 14h7.32A1.34 1.34 0 0 1 15 15.34V20H7.6a2.84 2.84 0 0 0-.66.09h-.07v-.11a3.89 3.89 0 0 0-.19-.42l-.09-.17a2.93 2.93 0 0 0-.41-.52 2.74 2.74 0 0 0-.5-.4s-.12-.06-.17-.1a3.49 3.49 0 0 0-.39-.19h-.07ZM3 21a1 1 0 0 1 1-1 1 1 0 0 1 .71.31A.93.93 0 0 1 5 21v6H3Zm4 4h18v2H7Zm22 2h-2v-6a1 1 0 0 1 1-1 1 1 0 0 1 .71.31.93.93 0 0 1 .29.69Z" data-name="Layer 2" fill="#2564eb" class="fill-000000"></path>
                      </svg>
                    </div>
                    <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">Lounge {{loungeTemplateId ? 'bearbeiten' : 'erstellen'}}</DialogTitle>
                      <div class="mt-2">
                        <p class="text-sm text-gray-500 block w-100">
                          Erstelle oder bearbeite Lounges in Clubs für Events.
                        </p>
                      </div>
                      <div>
                        <div class="flex">
                          <div class="pt-4">
                            <cropper-upload :init="false" :ratio="1" @cropped="c => image = c" button-text="Lounge-Bild hochladen" />
                          </div>
                          <div class="ml-4 flex-shrink-0 self-center">
                            <img :src="config.projectURL + 'assets/images/lounges/' + oldImage + '.jpg'" v-if="oldImage && !image" class="mt-4 mr-4 rounded w-20">
                          </div>
                        </div>
                        <div class="relative mt-4">
                          <label for="title" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Title</label>
                          <input required type="text" v-model="title" placeholder="Titel" name="title" id="title" class="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                        </div>
                        <div class="relative mt-4">
                          <label for="description" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Beschreibung</label>
                          <textarea v-model="description" placeholder="Beschreibung" name="description" id="description" class="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></textarea>
                        </div>
                        <div class="relative mt-4 rounded-md shadow-sm">
                          <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            <span class="text-gray-500 sm:text-sm">€</span>
                          </div>
                          <label for="price" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Preis (inkl. MwSt.)</label>
                          <input v-model="price" type="number" step="0.01" name="price" id="price" class="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="0.00"  />
                          <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                            <span class="text-gray-500 sm:text-sm" id="price-currency">EUR</span>
                          </div>
                        </div>
                        <div class="relative mt-5 rounded-md shadow-sm">
                          <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            <span class="sm:text-sm">
                              <TagIcon class="h-5 w-4 text-gray-500" aria-hidden="true" />
                            </span>
                          </div>
                          <label for="quantity" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Verfügbare Anzahl</label>
                          <input v-model="quantity" type="number" name="quantity" id="quantity" class="block w-full rounded-md border-0 py-1.5 pl-8 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Verfügbare Anzahl"  />
                          <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                            <span class="text-gray-500 sm:text-sm" id="quantity-currency">Stück</span>
                          </div>
                        </div>
                        <div class="relative mt-5 rounded-md shadow-sm">
                          <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            <span class="sm:text-sm">
                              <UserGroupIcon class="h-5 w-4 text-gray-500" aria-hidden="true" />
                            </span>
                          </div>
                          <label for="maxGuests" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Maximale Gäste</label>
                          <input v-model="maxGuests" type="number" name="maxGuests" id="maxGuests" class="block w-full rounded-md border-0 py-1.5 pl-8 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Maximale Gäste"  />
                          <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                            <span class="text-gray-500 sm:text-sm" id="loungeMaxGuests-currency">Gäste</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button type="submit" class="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto">{{loungeTemplateId ? 'Änderungen speichern' : 'Lounge erstellen'}}</button>
                    <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto" @click="resetLoungeForm()">Abbrechen</button>
                  </div>
                </form>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <notification class="my-4" v-if="successDelete" type="success" title="Lounge gelöscht" message="Die Lounge wurde erfolgreich gelöscht."/>
    <notification class="my-4" v-if="saveMessage" type="success" title="Speichern erfolgreich" message="Die Änderungen wurden erfolgreich übernommen."/>
  </div>
</template>

<script>
/* eslint-disable */
import {aSyncData} from '@/modules/main.js'
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import Lounge from "@/components/lounges/lounge";
import CropperUpload from "@/components/images/cropperUpload";
import { ChevronLeftIcon, ChevronRightIcon, CurrencyEuroIcon, TicketIcon, QrCodeIcon, CheckCircleIcon, ClockIcon, ArrowDownIcon, ArrowUpIcon,   BriefcaseIcon, CalendarIcon, EnvelopeIcon, ChevronDownIcon, LinkIcon, MapPinIcon, PencilSquareIcon, BanknotesIcon, ShoppingBagIcon, TagIcon, UserGroupIcon } from '@heroicons/vue/20/solid'
import notification from "@/components/notifications/notification";
import alert from "@/components/alerts/alert";
import { RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption, Menu, MenuButton, MenuItem, MenuItems, Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/vue/24/outline'

export default {
  name: 'lounges',
  components: {
    Lounge,
    CropperUpload,
    ChevronLeftIcon, ChevronRightIcon, TicketIcon, CurrencyEuroIcon, QrCodeIcon,
    notification,alert,RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption,
    CheckCircleIcon, ClockIcon,ArrowDownIcon, ArrowUpIcon,  BriefcaseIcon,
    CalendarIcon, EnvelopeIcon, ChevronDownIcon, LinkIcon, MapPinIcon, PencilSquareIcon, BanknotesIcon,
    Menu, MenuButton, MenuItem, MenuItems, Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot,
    ExclamationTriangleIcon, XMarkIcon, ShoppingBagIcon, TagIcon, UserGroupIcon
    },
  title: 'Lounges',
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
      lounges: [],
      newModal: false,
      loungeTemplateId: 0,
      title: '',
      description: '',
      price: 0,
      quantity: 1,
      image: null,
      maxGuests: 10,
      oldImage: null,
      successDelete: false,
      saveMessage: false,
    }
  },
  mounted() {
    this.getLounges()
  },
  methods: {
    getLounges() {
      const _this = this
      aSyncData('lounges/getTemplates', {organizerId: this.config.organizerId})
          .then(r => {
            console.log(r.data)
            if (r.status === 250) {
              _this.lounges = r.data
            }
          })
          .catch(e => {
            console.error(e)
          })
    },
    addLounge() {
      this.saveMessage = false
      const _this = this
      aSyncData(this.loungeTemplateId ? 'lounges/editTemplate' : 'lounges/addTemplate', {
        organizerId: this.config.organizerId,
        loungeTemplateId: this.loungeTemplateId,
        title: this.title,
        description: this.description,
        price: this.price,
        quantity: this.quantity,
        maxGuests: this.maxGuests,
        loungeImage: this.image
      }, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
          .then(r => {
            console.log(r)
            if (r.status === 250) {
              _this.lounges = r.data
              _this.saveMessage = true
              _this.resetLoungeForm()
            }
          })
          .catch(e => {
            console.log(e)
          })
    },
    editLounge(lounge){
      this.loungeTemplateId = lounge.id
      this.title = lounge.title
      this.description = lounge.description
      this.price = lounge.price
      this.quantity = lounge.quantity
      this.maxGuests = lounge.maxGuests
      this.newModal = true
      this.oldImage = lounge.image
    },
    resetLoungeForm(){
      this.newModal = false
      this.loungeTemplateId = 0
      this.title = ''
      this.description = ''
      this.price = 0
      this.quantity = 1
      this.maxGuests = 10
      this.image = null
      this.oldImage = null
    },
    deleteLounge(loungeTemplateId){
      this.successDelete = false
      const _this = this
      aSyncData('lounges/deleteTemplate', {
        organizerId: this.config.organizerId,
        loungeTemplateId: loungeTemplateId,
      })
          .then(r => {
            console.log(r)
            if (r.status === 250) {
              _this.successDelete = true
              _this.lounges = r.data
              _this.resetLoungeForm()
            }
          })
          .catch(e => {
            console.log(e)
          })
    }
  }
}
</script>

<style scoped>



</style>
