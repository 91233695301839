<template>
  <article class="col-sp-12 col-tb-6 col-sd-12 col-ld-6 row-sp-12 mb-4 px-4" >
    <div class="grid-12 bg-slate-50 py-2 rounded cursor-pointer" @click="$router.push({name: 'EventEdit', params: { eventId: eventItems.id }});">
      <div class="col-sp-12 col-ld-4 row-sp-12">
        <img class="w-full rounded" :src="config.projectURL + 'assets/images/events/'+eventItems.image+'.jpg'">
      </div>
      <div class="col-sp-12 col-ld-8 row-sp-12 px-2">
        <span class="inline-flex items-center rounded-md bg-blue-100 px-2 py-1 mt-2 text-xs font-medium text-blue-700">{{ eventItems.categories }}</span>
        <h4 class="font-bold text-l">{{ eventItems.title }}</h4>
        <h5 class="font-normal text-sm text-gray-500">{{ eventItems.subTitle }}</h5>
        <h6 class="font-thin text-xs text-gray-500">{{ this.covertStartTime }}</h6>
      </div>
    </div>
  </article>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";

export default {
  name: "eventItems",
  props: ["eventItems"],
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
      covertStartTime: '',
    }
  },
  mounted() {
    this.config.loading = false
    this.covertTime()
  },
  methods: {
    covertTime(){
      const _this = this
      let timestampArr = this.eventItems.startTime.split(" ");
      let date = timestampArr[0];
      let time = timestampArr[1];
      const dateArr = date.split("-");
      const timeArr = time.split(":");
      _this.covertStartTime = 'Am '+dateArr[2]+'.'+dateArr[1]+'.'+dateArr[0]+' ab '+timeArr[0]+':'+timeArr[1]+' Uhr';
    },
  }
}
</script>

<style scoped>

</style>