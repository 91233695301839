import { defineStore } from 'pinia'
import {aSyncData} from "@/modules/main";
import {configStore} from "@/store/configStore";
import {initializeApp} from "firebase/app";
import {getMessaging, getToken, onMessage, deleteToken} from "firebase/messaging"

export const notificationStore = defineStore('notification', {
    state: () => ({
        firebaseConfig: {
            apiKey: "AIzaSyAcJ-ra7SzCQ8CTdKpV0gWd6hwy-1iUCKI",
            authDomain: "nachtaktivevents-bd793.firebaseapp.com",
            projectId: "nachtaktivevents-bd793",
            storageBucket: "nachtaktivevents-bd793.appspot.com",
            messagingSenderId: "748799925022",
            appId: "1:748799925022:web:b247bee6e99837915ac70f"
        },
        fcmToken: null,
        messages: []
    }),
    actions: {
        /**
         * get push token
         */
        pushToken(){
            const config = configStore()
            if(config.cordova && typeof cordova.plugins.firebase !== 'undefined'){
                this.firebaseSetup()
            }else if("serviceWorker" in navigator && "PushManager" in window){
                this.pushWebToken()
            }else{
                console.log('no push method available')
            }
        },
        deleteToken(){
            const config = configStore()
            if(config.cordova && typeof cordova.plugins.firebase !== 'undefined'){
                cordova.plugins.firebase.messaging.deleteToken();
            }else if("serviceWorker" in navigator && "PushManager" in window){
                let fbMessaging = getMessaging(initializeApp(this.firebaseConfig))
                deleteToken(fbMessaging).then(r => {console.log('token deleted', r)}).catch(e => {console.log('error deleting token', e)})
            }
        },
        clearNotification(){
            const config = configStore()
            this.messages = []
            if(config.cordova && typeof cordova.plugins.firebase !== 'undefined'){
                cordova.plugins.firebase.messaging.clearNotifications();
                cordova.plugins.firebase.messaging.setBadge(this.messages.length);
            }else if("serviceWorker" in navigator && "PushManager" in window){
                //geht net :D
            }
        },
        /**
         * push web token to backend
         */
        pushWebToken () {
            const _this = this
            try {
                let fbMessaging = getMessaging(initializeApp(this.firebaseConfig))
                getToken(fbMessaging).then(currentToken => {
                    if (currentToken) {
                        _this.sendToken(currentToken)
                        onMessage(fbMessaging, (payload) => {
                            new Audio('/assets/audio/service-bell.mp3').play()
                                .catch(e => {
                                    console.error('audio not permitted')
                                })
                            _this.messages.push(payload)
                        })
                    } else {
                        console.log('No registration token available. Request permission to generate one.')
                    }
                }).catch((err) => {
                    console.log('An error occurred while retrieving token. ', err)
                })
            } catch (e) {
                console.error(e)
            }
        },
        /**
         * init firebase
         */
        firebaseSetup () {
            const _this = this
            const config = configStore()

            cordova.plugins.firebase.messaging.requestPermission({forceShow: false}).then(() => {
                cordova.plugins.firebase.messaging.onTokenRefresh(() => {
                    cordova.plugins.firebase.messaging.getToken().then(token =>  {
                        _this.sendToken(token)
                    })
                })
                cordova.plugins.firebase.messaging.getToken().then(token =>  {
                    _this.sendToken(token)
                })
            })

            cordova.plugins.firebase.messaging.onBackgroundMessage(payload => {
                this.messages.push(payload)
                cordova.plugins.firebase.messaging.setBadge(this.messages.length);
                if(payload.link && payload.link.length > 0){
                    const link = payload.link
                    const linkSmall = link.toLowerCase()
                    if(linkSmall.startsWith('http://' + config.projectURLRaw) || linkSmall.startsWith('https://' + config.projectURLRaw) ||linkSmall.startsWith( + config.projectURLRaw)){
                        _this.$router.push(link)
                    }else{
                        window.open(linkSmall.startsWith('http') ? link : 'https://' + link, '_system')
                    }

                }
                //console.log(this.messages)
            })

            cordova.plugins.firebase.messaging.onMessage(payload => {
                this.messages.push(payload)
                new Audio('/assets/audio/service-bell.mp3').play()
                    .catch(e => {
                        console.error('audio not permitted')
                    })
                //cordova.plugins.firebase.messaging.setBadge(this.messages.length);
                //console.log(this.messages)
            })
        },
        /**
         * send token to backend
         * @param fcmToken
         */
        sendToken (fcmToken) {
            const _this = this
            const config = configStore()
            if (this.fcmToken !== fcmToken) {
                _this.fcmToken = fcmToken
                aSyncData('settings/addPushToken', {
                    token: JSON.stringify(fcmToken),
                    platform: config.platform
                })
                    .then(r => {
                        console.log(r)
                    })
                    .catch(e => {
                        console.log(e)
                    })
            }
        }
    }
})