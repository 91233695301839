<template>
  <tr class="cursor-pointer">
    <td class="relative py-4 pr-3 text-sm font-medium text-gray-900">
      [{{ reduced.lastSale }} Uhr] - <span class="font-bold">{{ event.eventDate }} {{ event.eventTitle }}</span>
      <div class="absolute bottom-0 right-full h-px w-screen bg-gray-100" />
      <div class="absolute bottom-0 left-0 h-px w-screen bg-gray-100" />
    </td>
    <td class="px-3 py-4 text-sm text-gray-500 text-right">{{ (-reduced.sales/100).toFixed(2) }}€</td>
    <td class="py-4 pl-3 text-right text-sm">
      <button class="button tiny color-white" @click="expanded = !expanded">
        Zahlungen
        <span v-if="expanded">einklappen</span>
        <span v-else>ansehen</span>
      </button>
    </td>
  </tr>
  <tr>
    <td colspan="100%">
      <div v-if="expanded" class="eventDetails">
        <hr>
        <table class="w-full text-left">
          <thead class="bg-white">
          <tr>
            <th scope="col" class="relative isolate py-3.5 pr-3 text-left text-sm font-semibold text-gray-900">
              Name
              <div class="absolute inset-y-0 right-full -z-10 w-screen border-b border-b-gray-200" />
              <div class="absolute inset-y-0 left-0 -z-10 w-screen border-b border-b-gray-200" />
            </th>
            <th scope="col" class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell">E-Mail-Adresse</th>
            <th scope="col" class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell">Letzer Kauf</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 text-right">Umsatz</th>
            <th scope="col" class="relative py-3.5 pl-3 text-sm font-semibold text-right">
              Mehr Infos
            </th>
          </tr>
          </thead>
          <tbody>
          <sale-users v-for="customer in event.customer" :key="customer.userId" :customer="customer" @user-info="u => $emit('userInfo', u)" />
          </tbody>
        </table>
      </div>

    </td>
  </tr>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";
import {stringToDate, dateToString} from "@/modules/main";
import SaleUsers from "@/components/sales/saleUsers";

export default {
  name: "SalesByEventComp",
  components: {SaleUsers},
  props: {
    event: Object
  },
  emits: ['userInfo'],
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
      expanded: false
    }
  },
  computed: {
    reduced(){
      return {
        'sales': Object.values(this.event.customer).reduce((a, c) => {
          return a + c.sales.reduce((a2, c) => {
            return a2 + c.amount
          }, 0)
        }, 0),
        'lastSale': dateToString(new Date(Math.max(... Object.values(this.event.customer).map(c => {
          return c.lastSale
        }))))
      }
    },
  },
  mounted() {
    this.config.loading = false
  },
  methods: {
  }
}
</script>

<style scoped>


</style>