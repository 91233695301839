<template>
  <div class="bg-neutral-50 h-full">
    <div class="lg:pl-72">
      <main class="py-4" id="slotOverview">
        <div class="px-4">
          <div class="mb-2">
            <div>
              <nav class="hidden sm:flex" aria-label="Breadcrumb">
                <ol role="list" class="flex items-center space-x-4">
                  <li>
                    <div class="flex">
                      <a href="/slotoverview" class="text-sm font-medium text-gray-500 hover:text-gray-700">Übersicht</a>
                    </div>
                  </li>
                </ol>
              </nav>
            </div>
            <div class="mt-2 md:flex md:items-center md:justify-between">
              <div class="min-w-0 flex-1">
                <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">Schichten</h2>
              </div>
              <div class="mt-4 flex flex-shrink-0 md:ml-4 md:mt-0">
              </div>
            </div>
          </div>
          <div>
            <div class="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mb-4">
              <div class="sm:col-span-3 pad-2">
                <div class="border-b border-gray-200 bg-white px-4 py-5">
                  <h3 class="text-xl font-semibold leading-6 text-gray-900">
                    Offene Jobs
                  </h3>
                </div>
                <!-- Büro vFor-->
                <div class="bg-white rounded shadow p-4" v-for="slot in freeOfficeSlot" :key="slot.id">
                  <div class="border-b border-gray-200 bg-white py-5">
                    <div class="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
                      <div class="ml-4 mt-2">
                        <h3 class="text-base font-semibold leading-6 text-gray-900">Büroschicht</h3>
                        <p class="mt-1 text-sm text-gray-500">{{ slot.description }}</p>
                      </div>
                      <div class="ml-4 mt-2 flex-shrink-0">
                        <button  @click="deleteSlotById(slot.id)" type="button" class="relative inline-flex items-center rounded-md bg-gray-600 px-2 py-1.5 text-xs font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600">
                          Löschen
                        </button>
                      </div>
                    </div>
                  </div>
                  <ul>
                    <li class="col-span-1 flex rounded-md shadow-sm mb-3">
                      <div class="bg-blue-700 flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white">
                        <CurrencyEuroIcon class="h-5 w-5 text-white" aria-hidden="true" />
                      </div>
                      <div class="flex items-center justify-between truncate w-full rounded-r-md border-b border-r border-t border-gray-200 bg-slate-50">
                        <div class="px-4 py-2 text-sm">
                          <a class="font-bold text-gray-900 hover:text-gray-600">
                            Stundenlohn
                          </a>
                          <p class="text-gray-500">
                            {{ slot.officePay.toFixed(2) }}€
                          </p>
                        </div>
                      </div>
                    </li>
                    <li class="col-span-1 flex rounded-md shadow-sm mb-3">
                      <div class="bg-blue-700 flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white">
                        <ClockIcon class="h-5 w-5 text-white" aria-hidden="true" />
                      </div>
                      <div class="flex items-center justify-between truncate w-full rounded-r-md border-b border-r border-t border-gray-200 bg-slate-50">
                        <div class="px-4 py-2 text-sm">
                          <a class="font-bold text-gray-900 hover:text-gray-600">
                            Zeitraum
                          </a>
                          <p class="text-gray-500">
                            Am <span class="font-bold">{{ new Date(slot.slotStart).toLocaleDateString() }}</span> um <span class="font-bold">{{new Date(slot.slotStart).toLocaleTimeString().slice(0,-3)}}</span> Uhr - <span class="font-bold">{{ new Date(slot.slotEnd).toLocaleDateString() }}</span> um <span class="font-bold">{{new Date(slot.slotEnd).toLocaleTimeString().slice(0,-3)}}</span> Uhr
                          </p>
                        </div>
                      </div>
                    </li>
                    <li class="col-span-1 flex rounded-md shadow-sm mb-3">
                      <div class="bg-blue-700 flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white">
                        <UserGroupIcon class="h-5 w-5 text-white" aria-hidden="true" />
                      </div>
                      <div class="flex items-center justify-between truncate w-full rounded-r-md border-b border-r border-t border-gray-200 bg-slate-50">
                        <div class="px-4 py-2 text-sm">
                          <a class="font-bold text-gray-900 hover:text-gray-600">
                            Anzahl der Plätze
                          </a>
                          <p class="text-gray-500">
                            {{ slot.officeJobs }} Plätze
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <div class="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mb-4">
                    <div class="sm:col-span-3">
                      <button class="w-full rounded-md bg-white px-2.5 py-1.5 text-xl font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50" @click="createSlotMember(slot.id)">✋</button>
                    </div>
                    <div class="sm:col-span-3">
                      <button class="w-full rounded-md bg-white px-2.5 py-1.5 text-xl font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50" @click="denySlotFromMember( slot.id)">❌</button>
                    </div>
                  </div>
                </div>
                <!-- Eventcrew vFor-->
                <div class="bg-white rounded shadow p-4" v-for="slot in freeCrewSlot" :key="slot.id">
                  <div class="border-b border-gray-200 bg-white py-5">
                    <div class="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
                      <div class="ml-4 mt-2">
                        <h3 class="text-base font-semibold leading-6 text-gray-900">Eventschicht</h3>
                        <p class="mt-1 text-sm text-gray-500">{{ slot.description }}</p>
                      </div>
                      <div class="ml-4 mt-2 flex-shrink-0">
                        <button  @click="deleteSlotById(slot.id)" type="button" class="relative inline-flex items-center rounded-md bg-gray-600 px-2 py-1.5 text-xs font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600">
                          Löschen
                        </button>
                      </div>
                    </div>
                  </div>
                  <ul>
                    <li class="col-span-1 flex rounded-md shadow-sm mb-3">
                      <div class="bg-blue-700 flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white">
                        <CurrencyEuroIcon class="h-5 w-5 text-white" aria-hidden="true" />
                      </div>
                      <div class="flex items-center justify-between truncate w-full rounded-r-md border-b border-r border-t border-gray-200 bg-slate-50">
                        <div class="px-4 py-2 text-sm">
                          <a class="font-bold text-gray-900 hover:text-gray-600">
                            Stundenlohn
                          </a>
                          <p class="text-gray-500">
                            {{ slot.officePay.toFixed(2) }}€
                          </p>
                        </div>
                      </div>
                    </li>
                    <li class="col-span-1 flex rounded-md shadow-sm mb-3">
                      <div class="bg-blue-700 flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white">
                        <ClockIcon class="h-5 w-5 text-white" aria-hidden="true" />
                      </div>
                      <div class="flex items-center justify-between truncate w-full rounded-r-md border-b border-r border-t border-gray-200 bg-slate-50">
                        <div class="px-4 py-2 text-sm">
                          <a class="font-bold text-gray-900 hover:text-gray-600">
                            Zeitraum
                          </a>
                          <p class="text-gray-500">
                            Abfahrt: <span class="font-bold">{{ new Date(slot.driveStart).toLocaleDateString() }}</span> um <span class="font-bold">{{new Date(slot.driveStart).toLocaleTimeString().slice(0,-3)}}</span> Uhr - <span class="font-bold">{{ new Date(slot.driveEnd).toLocaleDateString() }}</span> um <span class="font-bold">{{new Date(slot.driveEnd).toLocaleTimeString().slice(0,-3)}}</span> Uhr
                          </p>
                          <p class="text-gray-500">
                            Am <span class="font-bold">{{ new Date(slot.slotStart).toLocaleDateString() }}</span> um <span class="font-bold">{{new Date(slot.slotStart).toLocaleTimeString().slice(0,-3)}}</span> Uhr - <span class="font-bold">{{ new Date(slot.slotEnd).toLocaleDateString() }}</span> um <span class="font-bold">{{new Date(slot.slotEnd).toLocaleTimeString().slice(0,-3)}}</span> Uhr
                          </p>
                        </div>
                      </div>
                    </li>
                    <li class="col-span-1 flex rounded-md shadow-sm mb-3">
                      <div class="bg-blue-700 flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white">
                        <MapPinIcon class="h-5 w-5 text-white" aria-hidden="true" />
                      </div>
                      <div class="flex items-center justify-between truncate w-full rounded-r-md border-b border-r border-t border-gray-200 bg-slate-50">
                        <div class="px-4 py-2 text-sm">
                          <a class="font-bold text-gray-900 hover:text-gray-600">
                            Location
                          </a>
                          <p class="text-gray-500">
                            <span class="f-bold">{{ slot.clubname }}</span> {{ slot.eventPlz }} {{ slot.eventCity }}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li class="col-span-1 flex rounded-md shadow-sm mb-3">
                      <div class="bg-blue-700 flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white">
                        <UserGroupIcon class="h-5 w-5 text-white" aria-hidden="true" />
                      </div>
                      <div class="flex items-center justify-between truncate w-full rounded-r-md border-b border-r border-t border-gray-200 bg-slate-50">
                        <div class="px-4 py-2 text-sm">
                          <a class="font-bold text-gray-900 hover:text-gray-600">
                            Anzahl der Plätze
                          </a>
                          <p class="text-gray-500">
                            {{ slot.officeJobs }} Plätze
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <div class="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mb-4">
                    <div class="sm:col-span-3">
                      <button class="w-full rounded-md bg-white px-2.5 py-1.5 text-xl font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50" @click="createSlotMember(slot.id)">✋</button>
                    </div>
                    <div class="sm:col-span-3">
                      <button class="w-full rounded-md bg-white px-2.5 py-1.5 text-xl font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50" @click="denySlotFromMember( slot.id)">❌</button>
                    </div>
                  </div>
                </div>
                <!-- DJ vFor-->
                <div class="bg-white rounded shadow p-4" v-for="slot in freeDjSlot" :key="slot.id">
                  <div class="border-b border-gray-200 bg-white py-5">
                    <div class="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
                      <div class="ml-4 mt-2">
                        <h3 class="text-base font-semibold leading-6 text-gray-900">DJ</h3>
                        <p class="mt-1 text-sm text-gray-500">{{ slot.description }}</p>
                      </div>
                      <div class="ml-4 mt-2 flex-shrink-0">
                        <button  @click="deleteSlotById(slot.id)" type="button" class="relative inline-flex items-center rounded-md bg-gray-600 px-2 py-1.5 text-xs font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600">
                          Löschen
                        </button>
                      </div>
                    </div>
                  </div>
                  <ul>
                    <li class="col-span-1 flex rounded-md shadow-sm mb-3">
                      <div class="bg-blue-700 flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white">
                        <CurrencyEuroIcon class="h-5 w-5 text-white" aria-hidden="true" />
                      </div>
                      <div class="flex items-center justify-between truncate w-full rounded-r-md border-b border-r border-t border-gray-200 bg-slate-50">
                        <div class="px-4 py-2 text-sm">
                          <a class="font-bold text-gray-900 hover:text-gray-600">
                            Stundenlohn
                          </a>
                          <p class="text-gray-500">
                            {{ slot.officePay.toFixed(2) }}€
                          </p>
                        </div>
                      </div>
                    </li>
                    <li class="col-span-1 flex rounded-md shadow-sm mb-3">
                      <div class="bg-blue-700 flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white">
                        <ClockIcon class="h-5 w-5 text-white" aria-hidden="true" />
                      </div>
                      <div class="flex items-center justify-between truncate w-full rounded-r-md border-b border-r border-t border-gray-200 bg-slate-50">
                        <div class="px-4 py-2 text-sm">
                          <a class="font-bold text-gray-900 hover:text-gray-600">
                            Zeitraum
                          </a>
                          <p class="text-gray-500">
                            Am <span class="font-bold">{{ new Date(slot.slotStart).toLocaleDateString() }}</span> um <span class="font-bold">{{new Date(slot.slotStart).toLocaleTimeString().slice(0,-3)}}</span> Uhr - <span class="font-bold">{{ new Date(slot.slotEnd).toLocaleDateString() }}</span> um <span class="font-bold">{{new Date(slot.slotEnd).toLocaleTimeString().slice(0,-3)}}</span> Uhr
                          </p>
                        </div>
                      </div>
                    </li>
                    <li class="col-span-1 flex rounded-md shadow-sm mb-3">
                      <div class="bg-blue-700 flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white">
                        <MapPinIcon class="h-5 w-5 text-white" aria-hidden="true" />
                      </div>
                      <div class="flex items-center justify-between truncate w-full rounded-r-md border-b border-r border-t border-gray-200 bg-slate-50">
                        <div class="px-4 py-2 text-sm">
                          <a class="font-bold text-gray-900 hover:text-gray-600">
                            Location
                          </a>
                          <p class="text-gray-500">
                            <span class="f-bold">{{ slot.clubname }}</span> {{ slot.eventPlz }} {{ slot.eventCity }}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li class="col-span-1 flex rounded-md shadow-sm mb-3">
                      <div class="bg-blue-700 flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white">
                        <MusicalNoteIcon class="h-5 w-5 text-white" aria-hidden="true" />
                      </div>
                      <div class="flex items-center justify-between truncate w-full rounded-r-md border-b border-r border-t border-gray-200 bg-slate-50">
                        <div class="px-4 py-2 text-sm">
                          <a class="font-bold text-gray-900 hover:text-gray-600">
                            Musikrichtung
                          </a>
                          <p class="text-gray-500">
                            {{ slot.music }}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li class="col-span-1 flex rounded-md shadow-sm mb-3">
                      <div class="bg-blue-700 flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white">
                        <UserGroupIcon class="h-5 w-5 text-white" aria-hidden="true" />
                      </div>
                      <div class="flex items-center justify-between truncate w-full rounded-r-md border-b border-r border-t border-gray-200 bg-slate-50">
                        <div class="px-4 py-2 text-sm">
                          <a class="font-bold text-gray-900 hover:text-gray-600">
                            Anzahl der Plätze
                          </a>
                          <p class="text-gray-500">
                            {{ slot.officeJobs }} Plätze
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <div class="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mb-4">
                    <div class="sm:col-span-3">
                      <button class="w-full rounded-md bg-white px-2.5 py-1.5 text-xl font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50" @click="createSlotMember(slot.id)">✋</button>
                    </div>
                    <div class="sm:col-span-3">
                      <button class="w-full rounded-md bg-white px-2.5 py-1.5 text-xl font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50" @click="denySlotFromMember( slot.id)">❌</button>
                    </div>
                  </div>
                </div>
                <!-- Freebookings vFor-->
                <div class="bg-white rounded shadow p-4" v-for="slot in freeFreelancerSlot" :key="slot.id">
                  <div class="border-b border-gray-200 bg-white py-5">
                    <div class="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
                      <div class="ml-4 mt-2">
                        <h3 class="text-base font-semibold leading-6 text-gray-900">DJ</h3>
                        <p class="mt-1 text-sm text-gray-500">{{ slot.description }}</p>
                      </div>
                      <div class="ml-4 mt-2 flex-shrink-0">
                        <button  @click="deleteSlotById(slot.id)" type="button" class="relative inline-flex items-center rounded-md bg-gray-600 px-2 py-1.5 text-xs font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600">
                          Löschen
                        </button>
                      </div>
                    </div>
                  </div>
                  <ul>
                    <li class="col-span-1 flex rounded-md shadow-sm mb-3">
                      <div class="bg-blue-700 flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white">
                        <CurrencyEuroIcon class="h-5 w-5 text-white" aria-hidden="true" />
                      </div>
                      <div class="flex items-center justify-between truncate w-full rounded-r-md border-b border-r border-t border-gray-200 bg-slate-50">
                        <div class="px-4 py-2 text-sm">
                          <a class="font-bold text-gray-900 hover:text-gray-600">
                            Gage
                          </a>
                          <p class="text-gray-500">
                            {{ slot.roomGage.toFixed(2) }}€
                          </p>
                        </div>
                      </div>
                    </li>
                    <li class="col-span-1 flex rounded-md shadow-sm mb-3">
                      <div class="bg-blue-700 flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white">
                        <ClockIcon class="h-5 w-5 text-white" aria-hidden="true" />
                      </div>
                      <div class="flex items-center justify-between truncate w-full rounded-r-md border-b border-r border-t border-gray-200 bg-slate-50">
                        <div class="px-4 py-2 text-sm">
                          <a class="font-bold text-gray-900 hover:text-gray-600">
                            Zeitraum
                          </a>
                          <p class="text-gray-500">
                            Am <span class="font-bold">{{ new Date(slot.slotStart).toLocaleDateString() }}</span> um <span class="font-bold">{{new Date(slot.slotStart).toLocaleTimeString().slice(0,-3)}}</span> Uhr - <span class="font-bold">{{ new Date(slot.slotEnd).toLocaleDateString() }}</span> um <span class="font-bold">{{new Date(slot.slotEnd).toLocaleTimeString().slice(0,-3)}}</span> Uhr
                          </p>
                        </div>
                      </div>
                    </li>
                    <li class="col-span-1 flex rounded-md shadow-sm mb-3">
                      <div class="bg-blue-700 flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white">
                        <MapPinIcon class="h-5 w-5 text-white" aria-hidden="true" />
                      </div>
                      <div class="flex items-center justify-between truncate w-full rounded-r-md border-b border-r border-t border-gray-200 bg-slate-50">
                        <div class="px-4 py-2 text-sm">
                          <a class="font-bold text-gray-900 hover:text-gray-600">
                            Location
                          </a>
                          <p class="text-gray-500">
                            <span class="f-bold">{{ slot.clubname }}</span> {{ slot.eventPlz }} {{ slot.eventCity }}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li class="col-span-1 flex rounded-md shadow-sm mb-3">
                      <div class="bg-blue-700 flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white">
                        <MagnifyingGlassIcon class="h-5 w-5 text-white" aria-hidden="true" />
                      </div>
                      <div class="flex items-center justify-between truncate w-full rounded-r-md border-b border-r border-t border-gray-200 bg-slate-50">
                        <div class="px-4 py-2 text-sm">
                          <a class="font-bold text-gray-900 hover:text-gray-600">
                            Gesucht
                          </a>
                          <p class="text-gray-500">
                            {{ slot.crewJobs }}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li class="col-span-1 flex rounded-md shadow-sm mb-3">
                      <div class="bg-blue-700 flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white">
                        <UserGroupIcon class="h-5 w-5 text-white" aria-hidden="true" />
                      </div>
                      <div class="flex items-center justify-between truncate w-full rounded-r-md border-b border-r border-t border-gray-200 bg-slate-50">
                        <div class="px-4 py-2 text-sm">
                          <a class="font-bold text-gray-900 hover:text-gray-600">
                            Anzahl der Plätze
                          </a>
                          <p class="text-gray-500">
                            {{ slot.officeJobs }} Plätze
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <div class="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mb-4">
                    <div class="sm:col-span-3">
                      <button class="w-full rounded-md bg-white px-2.5 py-1.5 text-xl font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50" @click="createSlotMember(slot.id)">✋</button>
                    </div>
                    <div class="sm:col-span-3">
                      <button class="w-full rounded-md bg-white px-2.5 py-1.5 text-xl font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50" @click="denySlotFromMember( slot.id)">❌</button>
                    </div>
                  </div>
                  <div class="relative mt-4">
                    <label for="message" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Nachricht (optional)</label>
                    <textarea v-model="message" placeholder="Nachricht (optional)" name="message" id="message" class="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" ></textarea>
                  </div>
                </div>
                <!-- Keine Schichten vorhanden -->
                <div class="bg-white rounded shadow p-4" v-if="!freeOfficeSlot.length && !freeCrewSlot.length && !freeDjSlot.length && !freeFreelancerSlot.length ">
                  <p class="text-gray-500 text-sm">
                    Zurzeit gibt es keine offenen Jobs
                  </p>
                </div>
              </div>
              <div class="sm:col-span-3 pad-2">
                <div class="border-b border-gray-200 bg-white px-4 py-5">
                  <h3 class="text-xl font-semibold leading-6 text-gray-900">
                    Offene Bewerbungen
                  </h3>
                </div>
                <div class="bg-white rounded shadow p-4" v-for="slot in slotRequests" :key="slot.id">
                  <div class="border-b border-gray-200 bg-white py-5">
                    <h3 class="text-base font-semibold leading-6 text-gray-900">{{slot.job=== 6?'DJ':''}}{{slot.job=== 7?'Büro':''}}{{slot.job=== 8?'Freelancer':''}}{{slot.job=== 9?'Eventcrew':''}}</h3>
                    <p class="mt-1 text-sm text-gray-500">{{ slot.firstname }} {{slot.lastname}}</p>
                  </div>
                  <ul>
                    <li class="col-span-1 flex rounded-md shadow-sm mb-3">
                      <div class="bg-blue-700 flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white">
                        <ClockIcon class="h-5 w-5 text-white" aria-hidden="true" />
                      </div>
                      <div class="flex items-center justify-between truncate w-full rounded-r-md border-b border-r border-t border-gray-200 bg-slate-50">
                        <div class="px-4 py-2 text-sm">
                          <a class="font-bold text-gray-900 hover:text-gray-600">
                            Zeitraum
                          </a>
                          <p class="text-gray-500" v-if="slot.job=== 9">
                            Abfahrt: <span class="font-bold">{{ new Date(slot.driveStart).toLocaleDateString() }}</span> um <span class="font-bold">{{new Date(slot.driveStart).toLocaleTimeString().slice(0,-3)}}</span> Uhr - <span class="font-bold">{{ new Date(slot.driveEnd).toLocaleDateString() }}</span> um <span class="font-bold">{{new Date(slot.driveEnd).toLocaleTimeString().slice(0,-3)}}</span> Uhr
                          </p>
                          <p class="text-gray-500">
                            Am <span class="font-bold">{{ new Date(slot.slotStart).toLocaleDateString() }}</span> um <span class="font-bold">{{new Date(slot.slotStart).toLocaleTimeString().slice(0,-3)}}</span> Uhr - <span class="font-bold">{{ new Date(slot.slotEnd).toLocaleDateString() }}</span> um <span class="font-bold">{{new Date(slot.slotEnd).toLocaleTimeString().slice(0,-3)}}</span> Uhr
                          </p>
                        </div>
                      </div>
                    </li>
                    <li class="col-span-1 flex rounded-md shadow-sm mb-3">
                      <div class="bg-blue-700 flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white">
                        <CurrencyEuroIcon class="h-5 w-5 text-white" aria-hidden="true" />
                      </div>
                      <div class="flex items-center justify-between truncate w-full rounded-r-md border-b border-r border-t border-gray-200 bg-slate-50">
                        <div class="px-4 py-2 text-sm">
                          <a class="font-bold text-gray-900 hover:text-gray-600">
                            <span v-if="slot.job === 8">Gage</span>
                            <span v-else>Stundenlohn</span>
                          </a>
                          <p class="text-gray-500">
                            {{ slot.officePay.toFixed(2) }}€
                          </p>
                        </div>
                      </div>
                    </li>
                    <li v-if="slot.job !== 7" class="col-span-1 flex rounded-md shadow-sm mb-3">
                      <div class="bg-blue-700 flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white">
                        <MapPinIcon class="h-5 w-5 text-white" aria-hidden="true" />
                      </div>
                      <div class="flex items-center justify-between truncate w-full rounded-r-md border-b border-r border-t border-gray-200 bg-slate-50">
                        <div class="px-4 py-2 text-sm">
                          <a class="font-bold text-gray-900 hover:text-gray-600">
                            Location
                          </a>
                          <p class="text-gray-500">
                            <span class="f-bold">{{ slot.clubname }}</span> {{ slot.eventPlz }} {{ slot.eventCity }}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li v-if="slot.job === 6" class="col-span-1 flex rounded-md shadow-sm mb-3">
                      <div class="bg-blue-700 flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white">
                        <MusicalNoteIcon class="h-5 w-5 text-white" aria-hidden="true" />
                      </div>
                      <div class="flex items-center justify-between truncate w-full rounded-r-md border-b border-r border-t border-gray-200 bg-slate-50">
                        <div class="px-4 py-2 text-sm">
                          <a class="font-bold text-gray-900 hover:text-gray-600">
                            Musikrichtung
                          </a>
                          <p class="text-gray-500">
                            {{ slot.music }}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li v-if="slot.job === 8" class="col-span-1 flex rounded-md shadow-sm mb-3">
                      <div class="bg-blue-700 flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white">
                        <MagnifyingGlassIcon class="h-5 w-5 text-white" aria-hidden="true" />
                      </div>
                      <div class="flex items-center justify-between truncate w-full rounded-r-md border-b border-r border-t border-gray-200 bg-slate-50">
                        <div class="px-4 py-2 text-sm">
                          <a class="font-bold text-gray-900 hover:text-gray-600">
                            Gesucht wird ein
                          </a>
                          <p class="text-gray-500">
                            {{ slot.crewJobs }}
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <div class="mt-4">
                    <button v-if="slot.status === 0" @click="deleteSlotMember(slot.sMId)" class="w-full ded-md broung-white px-2.5 py-1.5 text-l font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300">Bewerbung zurückziehen</button>
                    <button v-if="slot.status === 1" class="w-full cursor-default rounded-md bg-white px-2.5 py-1.5 text-xl font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 " >✅ Bestätigt</button>
                    <button v-if="slot.status === 2" class="w-full cursor-default rounded-md bg-white px-2.5 py-1.5 text-xl font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 " >❌ Abgelehnt</button>
                  </div>
                </div>
                <div class="bg-white rounded shadow p-4" v-if="!slotRequests.length">
                  <p class="text-gray-500 text-sm">
                    Zurzeit hast du keine offenen Berwerbungen
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import {UserGroupIcon, CurrencyEuroIcon,ClockIcon, MapPinIcon,MusicalNoteIcon,MagnifyingGlassIcon, EnvelopeIcon, PhoneIcon} from '@heroicons/vue/20/solid'

export default {
  name: "slotOverview",
  components: {CurrencyEuroIcon, UserGroupIcon, ClockIcon, MapPinIcon,MusicalNoteIcon,MagnifyingGlassIcon, EnvelopeIcon, PhoneIcon},

  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
      nextSlot: [],
      freeOfficeSlot: [],
      freeCrewSlot: [],
      freeDjSlot: [],
      freeFreelancerSlot: [],
      slotRequests:[],
      message:'',
    }
  },
  mounted() {
    this.getFreeSlotsForUser()
    this.getAllFinishedSlots()
  },
  methods: {
    //Job Ablehnen
    denySlotFromMember(id){
      const fD = new FormData
      fD.append('sessionID', this.auth.sessionID)
      fD.append('id', id)
      console.log(id)
      this.axios.post(this.config.projectURL + 'slots/denySlotFromMember', fD, {})
          .then(response => {
            console.log(response)
            if (response.status === 250) {
              this.getAllFinishedSlots()
              this.getFreeSlotsForUser()
            }
          })
          .catch(e => {
            console.error(e)
          })
    },
    //Alle offenen Jobs
    getFreeSlotsForUser() {
      const fD = new FormData
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post(this.config.projectURL + 'slots/getAllSlotsFromJob', fD)
          .then(response => {
            if (response.status === 250) {
              this.freeOfficeSlot=[]
              this.freeCrewSlot=[]
              this.freeDjSlot=[]
              this.freeFreelancerSlot=[]
              console.log(response)
              response.data.map(job=>{
                switch (job.job){
                  case 7:{
                    this.freeOfficeSlot.push(job)
                    break
                  }
                  case 9:{
                    this.freeCrewSlot.push(job)
                    break
                  }
                  case 6:{
                    this.freeDjSlot.push(job)
                    break
                  }
                  case 8:{
                    this.freeFreelancerSlot.push(job)
                    break
                  }
                }
              })
            }
          })
          .catch(e => {
            console.error(e)
          })
    },
    // auf den Job Bewerben
    createSlotMember(id){
      const fD = new FormData
      fD.append('sessionID', this.auth.sessionID)
      fD.append('id', id)
      fD.append('message', this.message)
      fD.append('status', 0)
      this.axios.post(this.config.projectURL + 'slots/createSlotMember', fD, {})
          .then(response => {
            console.log('Test')
            if (response.status === 250) {
              this.getAllFinishedSlots()
              this.getFreeSlotsForUser()
            }
          })
          .catch(e => {
            console.error(e)
          })
    },
    // Gebe mir alle meine Bewerbungen
    getAllFinishedSlots() {
      const fD = new FormData
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post(this.config.projectURL + 'slots/getMySlots', fD, {})
          .then(response => {
            if (response.status === 250) {
              this.slotRequests = response.data
            }
          })
          .catch(e => {
            console.error(e)
          })
    },
    //
    deleteSlotMember(id){
      const fD = new FormData
      fD.append('sessionID', this.auth.sessionID)
      fD.append('id', id)
      console.log(id)
      this.axios.post(this.config.projectURL + 'slots/deleteSlotMember', fD, {})
          .then(response => {
            if (response.status === 250) {
              this.getAllFinishedSlots()
              this.getFreeSlotsForUser()
            }
          })
          .catch(e => {
            console.error(e)
          })
    }
  },
}
</script>

<style scoped>

  main#slotOverview{
    padding-bottom: 80px;
  }
  #slotOverview h1{
    font-size: 2em;
    margin-bottom: 16px;
  }
  #slotOverview h3{
    font-size: 1.4em;
  }
</style>