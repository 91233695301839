<template>
  <div class="bg-neutral-50 h-full">
    <div class="lg:pl-72">
      <main class="py-4" id="ticketcenter">
        <div class="px-4" id="ticketScanner">
    <div class="mb-2">
      <div>
        <nav class="sm:hidden" aria-label="Back">
          <a href="/ticketcenter" class="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700">
            <ChevronLeftIcon class="-ml-1 mr-1 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
            Zurück
          </a>
        </nav>
        <nav class="hidden sm:flex" aria-label="Breadcrumb">
          <ol role="list" class="flex items-center space-x-4">
            <li>
              <div class="flex">
                <a href="/lounges" class="text-sm font-medium text-gray-500 hover:text-gray-700">Lounges</a>
              </div>
            </li>
            <li>
              <div class="flex items-center">
                <ChevronRightIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                <a class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">Lounge-Scanner</a>
              </div>
            </li>
          </ol>
        </nav>
      </div>
      <div class="mt-2 md:flex md:items-center md:justify-between">
        <div class="min-w-0 flex-1 flex justify-between">
          <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">Lounge-Scanner</h2>
        </div>
        <div class="mt-4 flex flex-shrink-0 md:ml-4 md:mt-0">
        </div>
      </div>
    </div>
    <div>
      <div>
        <div>
          <form>
            <div :class="[tutorial===1?'z-[60]':'','relative mt-4 mb-4']">
              <div v-if="tutorial===1" class="absolute z-[60] animate-bounce -top-14 shadow text-white bg-blue-600 p-3 rounded-md ">
                <p>Hier das Event auswählen</p>
              </div>
              <label for="eventId" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Event auswählen</label>
              <select v-model="eventId" @change="getLastScans(); scannerTools = true; tutorial===1?tutorial=2:null;" id="eventId" class="text-l px-2 block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6">
                <option value="-1" disabled>
                  Event...
                </option>
                <option v-for="event in events" :key="event.id" :value="event.id">
                  {{ event.title + ' - ' + event.startTime.split(' ')[0].split('-')[2] + '.' + event.startTime.split(' ')[0].split('-')[1] }}
                </option>
              </select>
            </div>
          </form>
          <div v-if="eventId == -1" class="bg-blue-600 text-white p-10 rounded text-center">
            <QrCodeIcon class="h-20 w-20 flex-shrink-0 text-white " aria-hidden="true" style="margin: 0 auto;"/>
            <h5 style="font-size: 1.4em;">
              Bitte wähle ein Event aus
            </h5>
          </div>
        </div>
        <div class="grid-12">
          <div :class="['col-sp-12 col-tb-6 row-sp-12 pad-8']">
            <section id="Scanner" :class="tutorial===3?'z-[60] relative':''">
              <div v-if="tutorial===3" class="absolute z-[60] -top-8 flex gap-2">
                <p class="text-white bg-blue-600 p-3 shadow rounded-md"> Hier musst du den QR Code des Tickets einscannen, um diesen zu entwerten bzw. nach Gültigkeit zu prüfen.</p>
              </div>
              <transition name="scanned">
                <div id="ticketChecked" class="overlay green" v-if="checkedAnimation">
                  <div style="padding-top: 25%;" class="t-center">
                    <div class="pad-8"></div>
                    <img src="assets/images/icons/check-white.svg" class="width-64 mx-auto">
                    <h5 style="font-size: 1.5em; margin-bottom: 8px; margin-top: 8px">
                      Ticket ist gültig
                    </h5>
                    <!--
                    <button @click="checkedAnimation = false; this.startScanner()" class="bgcolor-white color-black button">
                      Okay
                    </button>
                    -->
                  </div>
                </div>
              </transition>
              <transition name="error" id="error">
                <div id="error" class="overlay error" v-if="error">
                  <div style="padding-top: 25%;" class="t-center">
                    <div class="pad-8"></div>
                    <img src="assets/images/icons/error-white.svg" class="width-64 mx-auto">
                    <h5 style="font-size: 1em; margin-bottom: 8px; margin-top: 8px">
                      {{ error }}
                    </h5>
                    <button @click="error = false; this.startScanner()" class="bgcolor-white color-black button">
                      Okay
                    </button>
                  </div>
                </div>
              </transition>
              <div class="videoContainer">
                <video ref="scanner" />
              </div>
            </section>
            <div v-if="eventId != -1" :class="tutorial===4?'z-[60] relative':''">
              <div v-if="tutorial===4" class="absolute z-[60] -bottom-20 shadow text-white bg-blue-600 p-3 rounded-md ">
                <p>Sollte ein QR Code mal nicht lesbar sein, kannst du über diesen Button auch die Ticket-Nummer eingeben, um das Ticket wie beim herkömlichen Scan zu prüfen und zu entwerten.</p>
              </div>
              <button :class="['inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500']" @click="scanTicketModal = true">
                QR-Code nicht lesbar? Hier klicken
              </button>
            </div>
          </div>
          <div :class="[tutorial===2?'z-[60] relative':'','col-sp-12 col-tb-6 row-sp-12 pad-8']">
            <div v-if="tutorial===2" class="absolute z-[60] -top-8 flex gap-2">
              <p class="text-white bg-blue-600 p-3 shadow rounded-md"> Hier siehst du wie viele Tickets bereits gescannt wurden und die Details der letzen Scans</p>
            </div>
            <div v-if="eventId != -1">
              <ul role="list" class="mt-8">
                <li class="col-span-1 flex rounded-md shadow-sm mt-3">
                  <div class="bg-blue-700 flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white">
                    <QrCodeIcon class="h-6 w-6 text-white" aria-hidden="true" />
                  </div>
                  <div class="flex items-center justify-between truncate w-full rounded-r-md border-b border-r border-t border-gray-200 bg-white">
                    <div class="px-4 py-2 text-sm">
                      <a class="font-bold text-gray-900 hover:text-gray-600">Gescannte Tickets</a>
                      <p class="text-gray-500">{{ ScanTicketsCount }} von {{ScanTicketsCount + UnscanTicketsCount }}</p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <section class="bg-white shadow rounded mt-4 pt-4" v-if="eventId != -1">
              <div class="relative">
                <div class="absolute inset-0 flex items-center" aria-hidden="true">
                  <div class="w-full border-t border-gray-300" />
                </div>
                <div class="relative flex justify-center">
                  <span class="bg-white px-2 text-sm text-gray-500">Letzte Ticket-Scans</span>
                </div>
              </div>
              <div style="overflow-x: scroll;" class="pad-8">
                <ul role="list" class="space-y-6">
                  <li v-for="(activityItem, activityItemIdx) in ticketItems" :key="activityItem.id" class="relative flex gap-x-4">
                    <div :class="[activityItemIdx === ticketItems.length - 1 ? 'h-6' : '-bottom-6', 'absolute left-0 top-0 flex w-6 justify-center']">
                      <div class="w-px bg-gray-200" />
                    </div>
                      <img :src="config.projectURL + 'assets/images/users/'+activityItem.userImg" alt="" class="relative mt-3 h-6 w-6 flex-none rounded-full bg-gray-50" />
                      <div class="flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200">
                        <div class="flex justify-between gap-x-4">
                          <div class="py-0.5 text-xs leading-5 text-gray-500">
                            <span class="font-medium text-gray-900">{{ activityItem.firstname }} {{ activityItem.lastname }}</span>
                          </div>
                          <time :datetime="activityItem.scanTimestamp" class="flex-none py-0.5 text-xs leading-5 text-gray-500">{{ covertTime(activityItem.scanTimestamp) }}</time>
                        </div>
                        <p class="text-sm leading-6 text-gray-500">
                          hat Ticket <span class="font-bold">#{{ activityItem.id }}</span> von <span class="text-blue-700">{{ activityItem.email }}</span> gescannt<br>
                          Sicherheitscode: {{ activityItem.secureCode }}
                        </p>
                      </div>
                  </li>
                </ul>
                <div v-if="!ticketItems.length">
                  <p class="pad-16 t-center color-grey">
                    Noch keine Lounges gescannt
                  </p>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      <TransitionRoot as="template" :show="scanTicketModal">
        <Dialog as="div" class="relative z-10" @close="scanTicketModal = false">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>
          <div class="fixed inset-0 z-10 overflow-y-auto">
            <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <form @submit.prevent="manualTicketScan()">
                    <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                      <button type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" @click="scanTicketModal = false">
                        <span class="sr-only">Close</span>
                        <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                    <div class="sm:flex sm:items-start">
                      <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                        <QrCodeIcon class="h-6 w-6 text-blue-600" aria-hidden="true" />
                      </div>
                      <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">Lounge Manuell entwerten</DialogTitle>
                        <div class="mt-2">
                          <p class="text-sm text-gray-500">
                            Bitte gebe die LoungeID / Loungenummer ein um das Lounge manuell zu entwerten:
                          </p>
                        </div>
                        <div>
                          <div class="relative mt-4">
                            <label for="ticketId" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">LoungeID</label>
                            <input required type="number" v-model="ticketId" name="ticketId" id="ticketId" class="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="LoungeID" />
                          </div>
                        </div>
                        <div class="my-3" v-if="manualTicketScanError">
                          <alert type="error" title="Ups! Da ist was schiefgelaufen" message="Es konnte keine Lounge unter der eingegebenen Loungenummer gefunden werden."/>
                        </div>
                      </div>
                    </div>
                    <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                      <button type="submit" class="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto">Lounge entwerten</button>
                      <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto" @click="scanTicketModal = false">Abbrechen</button>
                    </div>
                  </form>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </TransitionRoot>
    </div>
  </div>
      </main>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import QrScanner from 'qr-scanner';
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import { ChevronLeftIcon, ChevronRightIcon, CurrencyEuroIcon, TicketIcon, QrCodeIcon, CheckCircleIcon, ClockIcon, ArrowDownIcon, ArrowUpIcon,   BriefcaseIcon, CalendarIcon, EnvelopeIcon, ChevronDownIcon, LinkIcon, MapPinIcon, PencilSquareIcon, BanknotesIcon  } from '@heroicons/vue/20/solid'
import notification from "@/components/notifications/notification";
import alert from "@/components/alerts/alert";
import { RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption, Menu, MenuButton, MenuItem, MenuItems, Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/vue/24/outline'
export default {
  name: "scanner",
  title: "LoungeScanner",
  components: {
    ChevronLeftIcon, ChevronRightIcon, TicketIcon, CurrencyEuroIcon, QrCodeIcon,
    notification,alert,RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption,
    CheckCircleIcon, ClockIcon,ArrowDownIcon, ArrowUpIcon,  BriefcaseIcon,
    CalendarIcon, EnvelopeIcon, ChevronDownIcon, LinkIcon, MapPinIcon, PencilSquareIcon, BanknotesIcon,
    Menu, MenuButton, MenuItem, MenuItems, Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot,
    ExclamationTriangleIcon, XMarkIcon
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
      qrScanner: null,
      error: false,
      checkedAnimation: false,
      startAnimation: false,
      cancelAnimation: false,
      ticketItems: [],
      eventId: -1,
      events: [],
      lastScans: [],
      scannerTools: false,
      scanTicketModal: false,
      ticketId: '',
      manualTicketScanError: false,
      ScanTicketsCount: 0,
      UnscanTicketsCount: 0,
    }
  },
  watch: {
    eventId(){
      this.startScanner()
    }
  },
  mounted() {
    this.initScanner()
    this.getEvents()
  },
  beforeUnmount() {
    try {
      this.qrScanner.stop()
      this.qrScanner.destroy();
      this.qrScanner = null;
    }catch (e) {}
  },
  methods:{
    async initScanner(){
      const _this = this
      return new Promise(resolve => {
        this.qrScanner = new QrScanner(
            this.$refs.scanner,
            _this.scanned,
            {
              highlightScanRegion: true,
              highlightCodeOutline: true
            })
        this.qrScanner.setInversionMode('both');
        resolve()
      })
    },
    startScanner(){
      const _this = this
      this.qrScanner.start()
      this.startAnimation = true
      setTimeout(() => {
        _this.startAnimation = false
      }, 1000)
    },
    stopScanner(){
      try {
        this.qrScanner.stop()
      }catch (e) {}
    },
    scanned(r){
      this.stopScanner()
      const _this = this
      const fD = new FormData()

      fD.append('sessionID', this.auth.sessionID)
      fD.append('secureHash',  r.data)
      fD.append('eventId',  this.eventId)
      this.axios.post('ticketCenter/loungescan', fD)
          .then(r => {
            if(r.status === 250) {
              _this.lastScans.push(r.data)
              _this.checkedAnimation = true
              _this.getLastScans()
              const successAudio = new Audio('assets/audio/success.mp3');
              successAudio.play();
              setTimeout(() => {
                _this.checkedAnimation = false
                _this.startScanner()
              }, 1000)
            }else{
              _this.error = r.data
              const denyAudio = new Audio('assets/audio/deny.mp3');
              denyAudio.play();
            }
          })
          .catch(e => {
            console.log(e)
          })
    },
    getEvents(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('organizerId',  this.config.organizerId)
      this.axios.post('ticketCenter/getEvents', fD)
          .then(r => {
            console.log(r.data, r.status)
            if(r.status === 250) {
              _this.events = r.data
            }
          })
          .catch(e => {
            console.log(e)
          })
    },
    manualTicketScan(){
      this.manualTicketScanError = false
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('id', this.ticketId)
      this.axios.post('ticketCenter/getSingleTicketById', fD)
          .then(r => {
            if(r.status === 250) {
              console.log(r.data.secureCode)
              _this.scanTicketModal = false;
              _this.scanned(JSON.parse('{"data":"'+r.data.secureCode+'"}'))
              _this.ticketId = '';
            }else{
              _this.manualTicketScanError = true;
            }
          })
          .catch(e => {
            console.log(e)
          })
    },
    changeGuestStatus(id, status){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('id',  id)
      fD.append('status',  status)
      this.axios.post('guestlist/changeGuestListStatus', fD)
          .then(r => {
            if(r.status === 250) {
              this.getGuests()
            }
          })
          .catch(e => {
            console.log(e)
          })
    },
    getLastScans(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('eventId',  this.eventId)
      this.axios.post('ticketCenter/getLastScans', fD)
          .then(r => {
            console.log(r)
            if(r.status === 250) {
              _this.ticketItems = r.data
            }
          })
          .catch(e => {
            console.log(e)
          })
      _this.getScanTicketsCount();
      _this.getUnscanTicketsCount();
    },
    covertTime(scanTimestamp){
      let timestampArr = scanTimestamp.split(" ");
      let date = timestampArr[0];
      let time = timestampArr[1];
      const dateArr = date.split("-");
      const timeArr = time.split(":");
      return dateArr[2]+'.'+dateArr[1]+'.'+dateArr[0]+' - '+timeArr[0]+':'+timeArr[1]+' Uhr';
    },
    getScanTicketsCount(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('eventId',  this.eventId)
      this.axios.post('ticketCenter/getScanTicketsCount', fD)
          .then(r => {
            if(r.status === 250) {
              _this.ScanTicketsCount = r.data.counter
            }
          })
          .catch(e => {
            console.log(e)
          })
    },
    getUnscanTicketsCount(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('eventId',  this.eventId)
      this.axios.post('ticketCenter/getUnscanTicketsCount', fD)
          .then(r => {
            if(r.status === 250) {
              _this.UnscanTicketsCount = r.data.counter
            }
          })
          .catch(e => {
            console.log(e)
          })
    },
  },
}
</script>

<style scoped>
section#Scanner video{
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
#Scanner{
  position: relative;
  min-height: 400px;
}
#Scanner #ticketChecked{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  color: #ffffff;
  background-color: #00cc66;
  border-radius: 10px;
  z-index: 9;
}
#Scanner #error{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  color: #ffffff;
  background-color: #BD081C;
  border-radius: 10px;
  z-index: 9;
}


@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}


</style>