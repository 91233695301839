<template>
  <div class="px-4">
    <div class="mb-2">
      <div>
        <nav class="hidden sm:flex" aria-label="Breadcrumb">
          <ol role="list" class="flex items-center space-x-4">
            <li>
              <div class="flex">
                <a href="/coupons" class="text-sm font-medium text-gray-500 hover:text-gray-700">Übersicht</a>
              </div>
            </li>
          </ol>
        </nav>
      </div>
      <div class="mt-2 md:flex md:items-center md:justify-between">
        <div class="min-w-0 flex-1">
          <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">Coupons</h2>
        </div>
        <div class="mt-4 flex flex-shrink-0 md:ml-4 md:mt-0">
          <button @click="$router.push({name: 'NotificationService'})" class="ml-4 h-10 items-center justify-center rounded-lg px-6 text-sm sm:flex bg-white font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
            Gutscheine versenden
          </button>
          <button @click="$router.push({name: 'Coupon'})" class="ml-4 h-10 items-center justify-center rounded-lg px-6 text-sm sm:flex bg-blue-600 font-semibold	 text-white hover:bg-blue-700">
            Gutschein erstellen
          </button>
        </div>
      </div>
    </div>
    <div class="bg-white shadow rounded p-4 mt-4">
      <div class="border-b border-gray-200 bg-white py-5">
        <h3 class="text-base font-semibold leading-6 text-xl text-gray-900">Aktuelle Coupons</h3>
      </div>
      <table class="w-full text-left">
        <thead class="bg-white">
          <tr>
            <th scope="col" class="relative isolate py-3.5 pr-3 text-left text-sm font-semibold text-gray-900">
              Titel
              <div class="absolute inset-y-0 right-full -z-10 w-screen border-b border-b-gray-200" />
              <div class="absolute inset-y-0 left-0 -z-10 w-screen border-b border-b-gray-200" />
            </th>
            <th scope="col" class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
              Gültig ab
            </th>
            <th scope="col" class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell">
              Gültig bis
            </th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Code</th>
            <th scope="col" class="relative py-3.5 pl-3">
              <span class="sr-only">Bearbeiten</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <coupon-list v-for="coupon in currentCoupons" :coupon="coupon" :key="coupon.id" />
        </tbody>
      </table>
      <p v-if="!currentCoupons.length" class="text-center text-sm text-gray-600">
        Keine aktuellen Coupons
      </p>
    </div>
    <div class="bg-white shadow rounded p-4 mt-4">
      <div class="border-b border-gray-200 bg-white py-5">
        <h3 class="text-base font-semibold leading-6 text-xl text-gray-900">Abgelaufende Coupons</h3>
      </div>
      <table class="w-full text-left">
        <thead class="bg-white">
          <tr>
            <th scope="col" class="relative isolate py-3.5 pr-3 text-left text-sm font-semibold text-gray-900">
              Titel
              <div class="absolute inset-y-0 right-full -z-10 w-screen border-b border-b-gray-200" />
              <div class="absolute inset-y-0 left-0 -z-10 w-screen border-b border-b-gray-200" />
            </th>
            <th scope="col" class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
              Gültig ab
            </th>
            <th scope="col" class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell">
              Gültig bis
            </th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Code</th>
            <th scope="col" class="relative py-3.5 pl-3">
              <span class="sr-only">Bearbeiten</span>
            </th>
          </tr>
        </thead>
        <tbody>
        <coupon-list v-for="coupon in expiredCoupons" :coupon="coupon" :key="coupon.id" />
        </tbody>
      </table>
      <p v-if="!expiredCoupons.length" class="text-center text-sm text-gray-600">
        Keine abgelaufenen Coupons
      </p>
    </div>
  </div>
  <notification class="my-4" v-if="this.$route.query.delete == 'true'" type="success" title="Löschen erfolgreich" message="Der Coupon wurde erfolgreich gelöscht."/>
  <notification class="my-4" v-if="this.$route.query.save == 'true'" type="success" title="Speichern erfolgreich" message="Der Coupon wurde erfolgreich gespeichert."/>

</template>

<script>
import {aSyncData, stringToDate} from "@/modules/main";
import CouponList from "@/components/coupons/couponList";
import {configStore} from "@/store/configStore";
import notification from "@/components/notifications/notification";

export default {
  name: "couponsOverviewView",
  components: {CouponList,notification},
  title: "Coupons",
  setup() {
    const config = configStore()

    return {config}
  },
  data(){
    return {
      coupons: []
    }
  },
  computed: {
    currentCoupons(){
      return this.coupons.filter(c => stringToDate(c.validUntil) >= (new Date()))
    },
    expiredCoupons(){
      return this.coupons.filter(c => stringToDate(c.validUntil) < (new Date()))
    }
  },
  mounted() {
    this.getCoupons()
  },
  methods: {
    getCoupons(){
      aSyncData('coupons/getAll', {organizerId: this.config.organizerId})
          .then(r => {
            if(r.status === 250){
              this.coupons = r.data
            }
          })
          .catch(e => {
            console.error(e)
          })
    }
  }
}
</script>

<style scoped>

</style>