<template>
  <div class="center">
    <transition name="hint">
      <div :style="background" class="inner" v-if="show">
        <span>{{ text }}</span>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "hint",
  props: {
    text: {
      type: String,
      required: true
    },
    hintType: {
      type: String,
      default: 'info'
    },
    seconds: {
      type: Number,
      default: 3
    }
  },
  emits: ['bye'],
  data(){
    return{
      show: false
    }
  },
  computed:{
    background(){
      return{
        'background-color': this.hintType === 'info' ? 'blue' : (this.hintType === 'alert' ? 'red' : 'green')
      }
    }
  },
  watch: {
    text(){
      this.changeText()
    }
  },
  methods:{
    changeText(){
      if(!this.text.length) return
      this.show = true

      setTimeout(() => {
        this.$emit('bye')
      }, this.seconds * 1000)

      setTimeout(() => {
        this.show = false
      }, this.seconds * 1000 - 500)
    }
  }
}
</script>

<style scoped>
div.inner{
  color: white;
  padding: 20px;
  border-radius: 10px;
}
.center{
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
}
.hint-enter-active,
.hint-leave-active{
  transition: all 0.5s ease;
}
.hint-enter-from,
.hint-leave-to {
  opacity: 0;
  transform: translateY(-50px);
}
</style>