<template>
  <div>
    <div class="px-4" v-if="userInfo.id">
      <div class="mb-2">
        <div>
          <nav class="sm:hidden" aria-label="Back">
            <router-link to="/kundeninfo" class="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700">
              <ChevronLeftIcon class="-ml-1 mr-1 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
              Zurück
            </router-link>
          </nav>
          <nav class="hidden sm:flex" aria-label="Breadcrumb">
            <ol role="list" class="flex items-center space-x-4">
              <li>
                <div class="flex">
                  <a href="/kundeninfo" class="text-sm font-medium text-gray-500 hover:text-gray-700">Umsätze & Kundeninformation</a>
                </div>
              </li>
              <li>
                <div class="flex items-center">
                  <ChevronRightIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                  <a class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">Kundenübersicht</a>
                </div>
              </li>
            </ol>
          </nav>
        </div>
        <div class="mt-2 md:flex md:items-center md:justify-between">
          <div class="min-w-0 flex-1">
            <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
              Kundenübersicht
            </h2>
          </div>
          <div class="mt-4 flex flex-shrink-0 md:ml-4 md:mt-0">
          </div>
        </div>
      </div>
      <div class="mt-4 bg-white rounded shadow p-4">
        <div class="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mb-4">
          <div class="sm:col-span-3">
            <ul>
              <li class="col-span-1 flex rounded-md shadow-sm mt-3">
                <div class="bg-blue-700 flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white">
                  <img v-if="userInfo.userImg != 'NULL' && userInfo.userImg != null" class="h-8 w-8 rounded-full" :src="config.projectURL + 'assets/images/users/'+userInfo.userImg" alt="">
                  <UserIcon v-else class="h-5 w-5 text-white" aria-hidden="true" />
                </div>
                <div class="flex items-center justify-between truncate w-full rounded-r-md border-b border-r border-t border-gray-200 bg-slate-50">
                  <div class="px-4 py-2 text-sm">
                    <a class="font-bold text-gray-900 hover:text-gray-600">
                      Vorname & Nachname
                    </a>
                    <p class="text-gray-500">
                      {{ userInfo.firstname }} {{ userInfo.lastname }}
                    </p>
                  </div>
                </div>
              </li>
              <li class="col-span-1 flex rounded-md shadow-sm mt-3">
                <div class="bg-blue-700 flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white">
                  <HeartIcon class="h-5 w-5 text-white" aria-hidden="true" />
                </div>
                <div class="flex items-center justify-between truncate w-full rounded-r-md border-b border-r border-t border-gray-200 bg-slate-50">
                  <div class="px-4 py-2 text-sm">
                    <a class="font-bold text-gray-900 hover:text-gray-600">
                      Geschlecht
                    </a>
                    <p class="text-gray-500">
                      <span v-if="userInfo.gender === 'male'">Männlich</span>
                      <span v-else-if="userInfo.gender === 'female'">Weiblich</span>
                      <span v-else>Divers</span>

                    </p>
                  </div>
                </div>
              </li>
              <li class="col-span-1 flex rounded-md shadow-sm mt-3">
                <div class="bg-blue-700 flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white">
                  <EnvelopeIcon class="h-5 w-5 text-white" aria-hidden="true" />
                </div>
                <div class="flex items-center justify-between truncate w-full rounded-r-md border-b border-r border-t border-gray-200 bg-slate-50">
                  <div class="px-4 py-2 text-sm">
                    <a class="font-bold text-gray-900 hover:text-gray-600">
                      E-Mail-Adresse
                    </a>
                    <p class="text-gray-500">
                      {{ userInfo.email }}
                    </p>
                  </div>
                </div>
              </li>
              <li class="col-span-1 flex rounded-md shadow-sm mt-3">
                <div class="bg-blue-700 flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white">
                  <PhoneIcon class="h-5 w-5 text-white" aria-hidden="true" />
                </div>
                <div class="flex items-center justify-between truncate w-full rounded-r-md border-b border-r border-t border-gray-200 bg-slate-50">
                  <div class="px-4 py-2 text-sm">
                    <a class="font-bold text-gray-900 hover:text-gray-600">
                      Telefonnummer
                    </a>
                    <p class="text-gray-500">
                      {{ userInfo.phone }}
                      <span v-if="!userInfo.phone">Keine Angabe</span>
                    </p>
                  </div>
                </div>
              </li>
              <li class="col-span-1 flex rounded-md shadow-sm mt-3">
                <div class="bg-blue-700 flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white">
                  <CakeIcon class="h-5 w-5 text-white" aria-hidden="true" />
                </div>
                <div class="flex items-center justify-between truncate w-full rounded-r-md border-b border-r border-t border-gray-200 bg-slate-50">
                  <div class="px-4 py-2 text-sm">
                    <a class="font-bold text-gray-900 hover:text-gray-600">
                      Geburtstag
                    </a>
                    <p class="text-gray-500">
                      {{ covertTime(userInfo.dob) }}
                    </p>
                  </div>
                </div>
              </li>
              <li class="col-span-1 flex rounded-md shadow-sm mt-3">
                <div class="bg-blue-700 flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white">
                  <ArrowRightOnRectangleIcon class="h-5 w-5 text-white" aria-hidden="true" />
                </div>
                <div class="flex items-center justify-between truncate w-full rounded-r-md border-b border-r border-t border-gray-200 bg-slate-50">
                  <div class="px-4 py-2 text-sm">
                    <a class="font-bold text-gray-900 hover:text-gray-600">
                      Registriert am
                    </a>
                    <p class="text-gray-500">
                      {{ covertTime(userInfo.register_timestamp) }}
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="sm:col-span-3">
            <div class="map">
              <ol-map class="rounded" :load-tiles-while-animating="true" :load-tiles-while-interacting="true" style="height:300px; width: 100%">
                <ol-view
                    ref="view" :center="center" :rotation="rotation" :zoom="zoom"
                    :projection="projection"
                />
                <ol-tile-layer>
                  <ol-source-osm />
                </ol-tile-layer>
                <ol-interaction-clusterselect @select="featureSelected" :point-radius="20">
                  <ol-style>
                    <ol-style-stroke color="green" :width="5" />
                    <ol-style-fill color="rgba(255,255,255,0.5)" />
                    <ol-style-icon src="assets/images/icons/marker.png" :scale="0.05" />
                  </ol-style>
                </ol-interaction-clusterselect>
                <ol-animated-clusterlayer :animation-duration="500" :distance="40">
                  <ol-source-vector ref="vectorsource">
                    <ol-feature v-for="(position, index) in positionsGPS" :key="index">
                      <ol-geom-point
                          :coordinates="[position.lon, position.lat]"
                          v-if="gps"
                      />
                    </ol-feature>
                    <ol-feature v-for="(position, index) in positionsIP" :key="index">
                      <ol-geom-point
                          :coordinates="[position.lon, position.lat]"
                          v-if="ip"
                      />
                    </ol-feature>
                  </ol-source-vector>

                  <ol-style :override-style-function="overrideStyleFunction">
                    <ol-style-stroke color="red" :width="2" />
                    <ol-style-fill color="rgba(255,255,255,0.1)" />

                    <ol-style-circle :radius="20">
                      <ol-style-stroke color="black" :width="15" :line-dash="[]" line-cap="butt" />
                      <ol-style-fill color="black" />
                    </ol-style-circle>

                    <ol-style-text>
                      <ol-style-fill color="white" />
                    </ol-style-text>
                  </ol-style>
                </ol-animated-clusterlayer>
              </ol-map>
              <RadioGroup class="mt-4">
                <RadioGroupLabel class="text-base font-semibold leading-6 text-gray-900">Trackingart auswählen</RadioGroupLabel>
                <div class="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                  <RadioGroupOption as="template" @click="gps = !gps">
                    <div :class="[gps ? 'border-transparent' : 'border-gray-300', gps ? 'border-indigo-600 ring-2 ring-indigo-600' : '', 'relative flex cursor-pointer rounded-lg border bg-slate-50 p-4 shadow-sm focus:outline-none']">
                      <span class="flex flex-1">
                        <span class="flex flex-col">
                          <RadioGroupLabel as="span" class="block text-sm font-medium text-gray-900">GPS</RadioGroupLabel>
                          <RadioGroupDescription as="span" class="mt-1 flex items-center text-sm text-gray-500">Tacking-Orte anhand des GPS anzeigen.</RadioGroupDescription>
                        </span>
                      </span>
                      <CheckCircleIcon :class="[!gps ? 'invisible' : '', 'h-5 w-5 text-indigo-600']" aria-hidden="true" />
                      <span :class="[gps ? 'border' : 'border-2', gps ? 'border-indigo-600' : 'border-transparent', 'pointer-events-none absolute -inset-px rounded-lg']" aria-hidden="true" />
                    </div>
                  </RadioGroupOption>
                  <RadioGroupOption as="template" @click="ip = !ip">
                    <div :class="[ip ? 'border-transparent' : 'border-gray-300', ip ? 'border-indigo-600 ring-2 ring-indigo-600' : '', 'relative flex cursor-pointer rounded-lg border bg-slate-50 p-4 shadow-sm focus:outline-none']">
                      <span class="flex flex-1">
                        <span class="flex flex-col">
                          <RadioGroupLabel as="span" class="block text-sm font-medium text-gray-900">IP</RadioGroupLabel>
                          <RadioGroupDescription as="span" class="mt-1 flex items-center text-sm text-gray-500">Tacking-Orte anhand der IP anzeigen.</RadioGroupDescription>
                        </span>
                      </span>
                      <CheckCircleIcon :class="[!ip ? 'invisible' : '', 'h-5 w-5 text-indigo-600']" aria-hidden="true" />
                      <span :class="[ip ? 'border' : 'border-2', ip ? 'border-indigo-600' : 'border-transparent', 'pointer-events-none absolute -inset-px rounded-lg']" aria-hidden="true" />
                    </div>
                  </RadioGroupOption>
                </div>
              </RadioGroup>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4 bg-white rounded shadow p-4">
        <div class="border-b border-gray-200 bg-white py-5">
          <h3 class="text-xl font-semibold leading-6 text-gray-900">Umsatzhistorie</h3>
        </div>
        <table class="w-full text-left">
          <thead class="bg-white">
          <tr>
            <th scope="col" class="relative isolate py-3.5 pr-3 text-left text-sm font-semibold text-gray-900">
              Event
              <div class="absolute inset-y-0 right-full -z-10 w-screen border-b border-b-gray-200" />
              <div class="absolute inset-y-0 left-0 -z-10 w-screen border-b border-b-gray-200" />
            </th>
            <th scope="col" class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell">Kauf-Zeitstempel</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 text-right">Umsatz</th>
            <th scope="col" class="relative py-3.5 pl-3 text-sm font-semibold text-right">Umsatz-Art</th>
          </tr>
          </thead>
          <tbody>
            <sale-events v-for="event in sales" :key="event.eventId" :event="event" />
          </tbody>
        </table>

      </div>
    </div>
  </div>
</template>

<script>
import {aSyncData} from "@/modules/main";
import {configStore} from "@/store/configStore";
import SaleEvents from "@/components/sales/saleEvents";
import { ChevronLeftIcon, ChevronRightIcon, UserIcon, EnvelopeIcon, CakeIcon, ArrowRightOnRectangleIcon, CheckCircleIcon, PhoneIcon, HeartIcon} from '@heroicons/vue/20/solid'
import { RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption } from '@headlessui/vue'

export default {
  name: "userInfo",
  components: {
    SaleEvents,ChevronLeftIcon, ChevronRightIcon, UserIcon, EnvelopeIcon, CakeIcon, ArrowRightOnRectangleIcon, CheckCircleIcon, PhoneIcon,
    RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption, HeartIcon
  },
  props:{
    userId: Number
  },
  setup(){
    const config = configStore()
    return {config}
  },
  data(){
    return{
      userInfo: {},
      positionsGPS: [],
      positionsIP: [],
      sales: [],
      center: [10.9254728, 50.6843502],
      projection: 'EPSG:4326',
      zoom: 6,
      rotation: 0,
      gps: true,
      ip: false
    }
  },
  mounted() {
    this.getUserInfo()
  },
  methods: {
    covertTime(timestamp){
      if(timestamp){
        let timestampArr = timestamp.split(" ");
        let date = timestampArr[0];
        const dateArr = date.split("-");
        return dateArr[2]+'.'+dateArr[1]+'.'+dateArr[0];
      }else{
        return null;
      }
    },
    getUserInfo(){
      aSyncData('sales/getCustomerInfo', {
        organizerId: this.config.organizerId,
        userId: this.userId
      })
          .then(r => {
            console.log(r)
            if(r.status === 250){
              this.userInfo = r.data.user
              this.positionsGPS = r.data.positionsGPS
              this.positionsIP = r.data.positionsIP
              this.sales = r.data.sales
            }
          })
          .catch(e => console.error(e))
    },
    featureSelected(event) {
      console.log(event)
    },
    overrideStyleFunction(feature, style) {

      let clusteredFeatures = feature.get('features');
      let size = clusteredFeatures.length;

      let color = size > 20 ? "192,0,0" : size > 8 ? "255,128,0" : "0,128,0";
      let radius = Math.max(8, Math.min(size, 20));
      let dash = 2 * Math.PI * radius / 6;
      let calculatedDash = [0, dash, dash, dash, dash, dash, dash];

      style.getImage().getStroke().setLineDash(dash);
      style.getImage().getStroke().setColor("rgba(" + color + ",0.5)");
      style.getImage().getStroke().setLineDash(calculatedDash);
      style.getImage().getFill().setColor("rgba(" + color + ",1)");

      style.getImage().setRadius(radius)

      style.getText().setText(size.toString());

    }
  }
}
</script>

<style scoped>
</style>