<template>
  <div class="px-4">
    <div class="mb-2">
      <div>
        <nav class="hidden sm:flex" aria-label="Breadcrumb">
          <ol role="list" class="flex items-center space-x-4">
            <li>
              <div class="flex">
                <a href="/barmenu" class="text-sm font-medium text-gray-500 hover:text-gray-700">Übersicht</a>
              </div>
            </li>
          </ol>
        </nav>
      </div>
      <div class="mt-2 md:flex md:items-center md:justify-between">
        <div class="min-w-0 flex-1">
          <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">Barkarten</h2>
        </div>
        <div class="mt-4 flex flex-shrink-0 md:ml-4 md:mt-0">
        </div>
      </div>
    </div>
    <div>
      <div>
        <div class="relative mt-4">
          <div class="mt-2 flex rounded-md shadow-sm">
            <div class="relative flex flex-grow items-stretch">
              <label for="name" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Neue Barkarte erstellen</label>
              <input @keyup.enter="addBarMenu" v-model="name" type="text" name="name" id="name" class="block w-full rounded-none rounded-l-md border-0 py-1.5 pl-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-l sm:leading-6" placeholder="Name der Barkarte..." />
            </div>
            <button @click="addBarMenu" type="button" class="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-white bg-blue-600 ring-1 ring-inset ring-gray-300 hover:bg-blue-500">
              Erstellen
            </button>
          </div>
        </div>
        <div class="bg-white shadow rounded p-4 mt-4">
          <div class="border-b border-gray-200 bg-white py-5">
            <h3 class="text-base font-semibold leading-6 text-xl text-gray-900">
              Auswahl Barkarten
            </h3>
          </div>
          <div class="grid-12">
            <div style="cursor: pointer;" class="col-sp-12 col-tb-6 col-sd-4 row-sp-12 pad-4" v-for="barMenu in barMenues" :key="barMenu.id" @click="$router.push({name: 'BarMenuDetails', params: {barMenuId: barMenu.id}})">
              <li class="col-span-1 flex rounded-md shadow-sm mt-3">
                <div class="bg-blue-700 flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white">
                  <svg class="h-8 w-8 text-white" data-name="Layer 2" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="M22.53 2a4.67 4.67 0 0 0-4.66 4.66h-4.35a.93.93 0 0 0 0 1.86h8.08v1.86h-8.83l-5.32-8a.93.93 0 0 0-1.55 1L8 6.68H5.74a.93.93 0 0 0-.93.93v4.65a2.79 2.79 0 0 0 2.8 2.8.91.91 0 0 1 .66.29.9.9 0 0 1 .27.65 2.8 2.8 0 0 0 2.8 2.8h1.86v6.53h-.93a2.8 2.8 0 0 0-2.8 2.8v.93a.93.93 0 0 0 .93.93h7.46a.93.93 0 0 0 .93-.93v-.93a2.8 2.8 0 0 0-2.8-2.8h-.93V18.8h1.87a2.79 2.79 0 0 0 2.8-2.8.94.94 0 0 1 .94-.93 2.8 2.8 0 0 0 2.8-2.8V7.62a.93.93 0 0 0-.93-.93h-2.81a2.8 2.8 0 1 1 5.59 0 2.76 2.76 0 0 1-.69 1.83A.93.93 0 1 0 26 9.75 4.66 4.66 0 0 0 22.53 2Zm-5.6 26.12h-5.59a.93.93 0 0 1 .93-.93H16a.93.93 0 0 1 .93.93Zm3.73-14.92a2.8 2.8 0 0 0-2.8 2.8.93.93 0 0 1-.28.66.89.89 0 0 1-.66.27h-5.58a.93.93 0 0 1-.93-.93 2.81 2.81 0 0 0-2.8-2.8.92.92 0 0 1-.66-.28.89.89 0 0 1-.27-.65h1.87a.93.93 0 0 0 0-1.86H6.68V8.55h2.61l4.07 6.1a.93.93 0 1 0 1.55-1L14 12.28h7.6a.93.93 0 0 1-.94.92Z" fill="#ffffff" class="fill-000000"></path></svg>
                </div>
                <div class="flex items-center justify-between truncate w-full rounded-r-md border-b border-r border-t border-gray-200 bg-slate-50">
                  <div class="px-4 py-2 text-sm">
                    <a class="font-bold text-gray-900 hover:text-gray-600">
                      Getränkekarte:
                    </a>
                    <p class="text-gray-500">
                      {{ barMenu.name }}
                    </p>
                  </div>
                </div>
              </li>
            </div>
          </div>
        </div>
      </div>
    </div>
    <notification class="my-4" v-if="this.$route.query.feedback == 'deleteSuccess'" type="success" title="Erfolgreich gelöscht" message="Die Barkarte wurde erfolgreich gelöscht."/>

  </div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import notification from "@/components/notifications/notification";

export default {
  name: "barMenu",
  title: "Barkarten",
  components: {notification},
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return{
      barMenues: [],
      name: ''
    }
  },
  mounted() {
    this.getBarMenues()
  },
  methods: {
    getBarMenues(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('organizerId', this.config.organizerId)
      this.axios.post('barMenu/getBarMenues', fD)
          .then(r => {
            console.log(r)
            if(r.status === 250){
              _this.barMenues = r.data
            }
          })
    },
    addBarMenu(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('organizerId', this.config.organizerId)
      fD.append('name', this.name)
      this.axios.post('barMenu/addBarMenu', fD)
          .then(r => {
            console.log(r)
            if(r.status === 250){
              /*_this.barMenues.push({id: r.data.id, name: r.data.name})
              _this.name = ''*/
              _this.$router.push({name: 'BarMenuDetails', params: {barMenuId: r.data.id}})
            }
          })
    }
  }
}
</script>

<style scoped>

  .flex {
    display: flex;
  }

  .title-wrapper{
    justify-content: space-between;
    align-items: center;
  }

  .title-wrapper h1{
    font-size: 2em;
  }

  .button {
    background-color: #2068ff;
    color: white;
  }

  .addBar {
    align-items: center;
    column-gap: 8px;
    margin-bottom: 12px;
  }

  .addBar input{
    display: block;
    width: 40%;
    text-align: left;
    border-radius: 10px;
    border: none;
    background-color: rgba(0,0,0,0.05);
    padding: 12px;
  }

  .addBar .button {
    width: 140px;
    padding: 12px 20px !important;
  }

  .selectBarcard-wrapper{
    margin-top: 12px;
    display: flex;
    column-gap: 12px;
    row-gap: 12px;
    flex-wrap: wrap;
  }

  .singleBarcard{
    /*flex: 1;*/
    background-color: rgba(0,0,0,0.05);
  }



</style>