<template>
  <tr>
    <td class="relative py-4 pr-3 text-sm font-medium text-gray-900">
      #{{ transactionItems.transactionId }}
      <div class="absolute bottom-0 right-full h-px w-screen bg-gray-100" />
      <div class="absolute bottom-0 left-0 h-px w-screen bg-gray-100" />
    </td>
    <td class="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">{{ transactionItems.firstname }}</td>
    <td class="hidden px-3 py-4 text-sm text-gray-500 md:table-cell">{{ transactionItems.lastname }}</td>
    <td class="px-3 py-4 text-sm text-gray-500">{{ covertTimestamp }}</td>
    <td class="py-4 pl-3 text-right text-sm">
      <span class="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
        {{ (transactionItems.amount / 100).toFixed(2).replace("-","") }}€
      </span>
    </td>
  </tr>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";

export default {
  name: "TransactionItems",
  props: ["transactionItems"],
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
      covertTimestamp: '',
    }
  },
  mounted() {
    this.config.loading = false
    this.covertTime()
  },
  methods: {
    covertTime(){
      const _this = this
      let timestampArr = this.transactionItems.date.split(" ");
      let date = timestampArr[0];
      let time = timestampArr[1];
      const dateArr = date.split("-");
      const timeArr = time.split(":");
      _this.covertTimestamp = dateArr[2]+'.'+dateArr[1]+'.'+dateArr[0]+' - '+timeArr[0]+':'+timeArr[1]+' Uhr';
    },
  }
}
</script>

<style scoped>
tr td{
  padding: 4px;
  font-size: 0.9em;
}
span.label{
  background-color: #9bf2d233;
  color: #67cba6;
  padding: 4px 8px;
  border-radius: 10px;
  font-size: .9em;
}
</style>