<template>
  <div class="bg-neutral-50 h-full">
    <div class="lg:pl-72">
      <main class="py-4" id="slotPlaner">
        <div class="px-4">
          <div class="mb-2">
            <div>
              <nav class="hidden sm:flex" aria-label="Breadcrumb">
                <ol role="list" class="flex items-center space-x-4">
                  <li>
                    <div class="flex">
                      <a href="/slotplaner" class="text-sm font-medium text-gray-500 hover:text-gray-700">Übersicht</a>
                    </div>
                  </li>
                </ol>
              </nav>
            </div>
            <div class="mt-2 md:flex md:items-center md:justify-between">
              <div class="min-w-0 flex-1">
                <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">Schichtenplaner</h2>
              </div>
              <div class="mt-4 flex flex-shrink-0 md:ml-4 md:mt-0">
                <button @click="createModal=true" type="button" class="h-10 items-center justify-center rounded-lg px-6 text-sm sm:flex bg-blue-600 font-semibold text-white hover:bg-blue-700">
                  Schicht erstellen
                </button>
              </div>
            </div>
          </div>
          <div>
            <div class="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mb-4">
              <div class="sm:col-span-3 pad-2">
                <div class="border-b border-gray-200 bg-white px-4 py-5">
                  <h3 class="text-xl font-semibold leading-6 text-gray-900">
                    Erstellte Schichten
                  </h3>
                </div>
                <!-- Büro vFor-->
                <div class="bg-white rounded shadow p-4" v-for="slot in openOfficeSlots" :key="slot.id">
                  <div class="border-b border-gray-200 bg-white py-5">
                    <div class="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
                      <div class="ml-4 mt-2">
                        <h3 class="text-base font-semibold leading-6 text-gray-900">Büroschicht</h3>
                        <p class="mt-1 text-sm text-gray-500">{{ slot.description }}</p>
                      </div>
                      <div class="ml-4 mt-2 flex-shrink-0">
                        <button  @click="deleteSlotById(slot.id)" type="button" class="relative inline-flex items-center rounded-md bg-gray-600 px-2 py-1.5 text-xs font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600">
                          Löschen
                        </button>
                      </div>
                    </div>
                  </div>
                  <ul>
                    <li class="col-span-1 flex rounded-md shadow-sm mb-3">
                      <div class="bg-blue-700 flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white">
                        <CurrencyEuroIcon class="h-5 w-5 text-white" aria-hidden="true" />
                      </div>
                      <div class="flex items-center justify-between truncate w-full rounded-r-md border-b border-r border-t border-gray-200 bg-slate-50">
                        <div class="px-4 py-2 text-sm">
                          <a class="font-bold text-gray-900 hover:text-gray-600">
                            Stundenlohn
                          </a>
                          <p class="text-gray-500">
                            {{ slot.officePay.toFixed(2) }}€
                          </p>
                        </div>
                      </div>
                    </li>
                    <li class="col-span-1 flex rounded-md shadow-sm mb-3">
                      <div class="bg-blue-700 flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white">
                        <ClockIcon class="h-5 w-5 text-white" aria-hidden="true" />
                      </div>
                      <div class="flex items-center justify-between truncate w-full rounded-r-md border-b border-r border-t border-gray-200 bg-slate-50">
                        <div class="px-4 py-2 text-sm">
                          <a class="font-bold text-gray-900 hover:text-gray-600">
                            Zeitraum
                          </a>
                          <p class="text-gray-500">
                            Am <span class="font-bold">{{ new Date(slot.slotStart).toLocaleDateString() }}</span> um <span class="font-bold">{{new Date(slot.slotStart).toLocaleTimeString().slice(0,-3)}}</span> Uhr - <span class="font-bold">{{ new Date(slot.slotEnd).toLocaleDateString() }}</span> um <span class="font-bold">{{new Date(slot.slotEnd).toLocaleTimeString().slice(0,-3)}}</span> Uhr
                          </p>
                        </div>
                      </div>
                    </li>
                    <li class="col-span-1 flex rounded-md shadow-sm mb-3">
                      <div class="bg-blue-700 flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white">
                        <UserGroupIcon class="h-5 w-5 text-white" aria-hidden="true" />
                      </div>
                      <div class="flex items-center justify-between truncate w-full rounded-r-md border-b border-r border-t border-gray-200 bg-slate-50">
                        <div class="px-4 py-2 text-sm">
                          <a class="font-bold text-gray-900 hover:text-gray-600">
                            Anzahl der Plätze
                          </a>
                          <p class="text-gray-500">
                            {{ slot.officeJobs }} Plätze
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>


                </div>
                <!-- Eventcrew vFor-->
                <div class="bg-white rounded shadow p-4" v-for="slot in openEventSlots" :key="slot.id">
                  <div class="border-b border-gray-200 bg-white py-5">
                    <div class="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
                      <div class="ml-4 mt-2">
                        <h3 class="text-base font-semibold leading-6 text-gray-900">Eventschicht</h3>
                        <p class="mt-1 text-sm text-gray-500">{{ slot.description }}</p>
                      </div>
                      <div class="ml-4 mt-2 flex-shrink-0">
                        <button  @click="deleteSlotById(slot.id)" type="button" class="relative inline-flex items-center rounded-md bg-gray-600 px-2 py-1.5 text-xs font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600">
                          Löschen
                        </button>
                      </div>
                    </div>
                  </div>
                  <ul>
                    <li class="col-span-1 flex rounded-md shadow-sm mb-3">
                      <div class="bg-blue-700 flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white">
                        <CurrencyEuroIcon class="h-5 w-5 text-white" aria-hidden="true" />
                      </div>
                      <div class="flex items-center justify-between truncate w-full rounded-r-md border-b border-r border-t border-gray-200 bg-slate-50">
                        <div class="px-4 py-2 text-sm">
                          <a class="font-bold text-gray-900 hover:text-gray-600">
                            Stundenlohn
                          </a>
                          <p class="text-gray-500">
                            {{ slot.officePay.toFixed(2) }}€
                          </p>
                        </div>
                      </div>
                    </li>
                    <li class="col-span-1 flex rounded-md shadow-sm mb-3">
                      <div class="bg-blue-700 flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white">
                        <ClockIcon class="h-5 w-5 text-white" aria-hidden="true" />
                      </div>
                      <div class="flex items-center justify-between truncate w-full rounded-r-md border-b border-r border-t border-gray-200 bg-slate-50">
                        <div class="px-4 py-2 text-sm">
                          <a class="font-bold text-gray-900 hover:text-gray-600">
                            Zeitraum
                          </a>
                          <p class="text-gray-500">
                            Abfahrt: <span class="font-bold">{{ new Date(slot.driveStart).toLocaleDateString() }}</span> um <span class="font-bold">{{new Date(slot.driveStart).toLocaleTimeString().slice(0,-3)}}</span> Uhr - <span class="font-bold">{{ new Date(slot.driveEnd).toLocaleDateString() }}</span> um <span class="font-bold">{{new Date(slot.driveEnd).toLocaleTimeString().slice(0,-3)}}</span> Uhr
                          </p>
                          <p class="text-gray-500">
                            Am <span class="font-bold">{{ new Date(slot.slotStart).toLocaleDateString() }}</span> um <span class="font-bold">{{new Date(slot.slotStart).toLocaleTimeString().slice(0,-3)}}</span> Uhr - <span class="font-bold">{{ new Date(slot.slotEnd).toLocaleDateString() }}</span> um <span class="font-bold">{{new Date(slot.slotEnd).toLocaleTimeString().slice(0,-3)}}</span> Uhr
                          </p>
                        </div>
                      </div>
                    </li>
                    <li class="col-span-1 flex rounded-md shadow-sm mb-3">
                      <div class="bg-blue-700 flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white">
                        <MapPinIcon class="h-5 w-5 text-white" aria-hidden="true" />
                      </div>
                      <div class="flex items-center justify-between truncate w-full rounded-r-md border-b border-r border-t border-gray-200 bg-slate-50">
                        <div class="px-4 py-2 text-sm">
                          <a class="font-bold text-gray-900 hover:text-gray-600">
                            Location
                          </a>
                          <p class="text-gray-500">
                            <span class="f-bold">{{ slot.clubname }}</span> {{ slot.eventPlz }} {{ slot.eventCity }}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li class="col-span-1 flex rounded-md shadow-sm mb-3">
                      <div class="bg-blue-700 flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white">
                        <UserGroupIcon class="h-5 w-5 text-white" aria-hidden="true" />
                      </div>
                      <div class="flex items-center justify-between truncate w-full rounded-r-md border-b border-r border-t border-gray-200 bg-slate-50">
                        <div class="px-4 py-2 text-sm">
                          <a class="font-bold text-gray-900 hover:text-gray-600">
                            Anzahl der Plätze
                          </a>
                          <p class="text-gray-500">
                            {{ slot.officeJobs }} Plätze
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <!-- DJ vFor-->
                <div class="bg-white rounded shadow p-4" v-for="slot in openDjSlots" :key="slot.id">
                  <div class="border-b border-gray-200 bg-white py-5">
                    <div class="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
                      <div class="ml-4 mt-2">
                        <h3 class="text-base font-semibold leading-6 text-gray-900">DJ</h3>
                        <p class="mt-1 text-sm text-gray-500">{{ slot.description }}</p>
                      </div>
                      <div class="ml-4 mt-2 flex-shrink-0">
                        <button  @click="deleteSlotById(slot.id)" type="button" class="relative inline-flex items-center rounded-md bg-gray-600 px-2 py-1.5 text-xs font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600">
                          Löschen
                        </button>
                      </div>
                    </div>
                  </div>
                  <ul>
                    <li class="col-span-1 flex rounded-md shadow-sm mb-3">
                      <div class="bg-blue-700 flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white">
                        <CurrencyEuroIcon class="h-5 w-5 text-white" aria-hidden="true" />
                      </div>
                      <div class="flex items-center justify-between truncate w-full rounded-r-md border-b border-r border-t border-gray-200 bg-slate-50">
                        <div class="px-4 py-2 text-sm">
                          <a class="font-bold text-gray-900 hover:text-gray-600">
                            Stundenlohn
                          </a>
                          <p class="text-gray-500">
                            {{ slot.officePay.toFixed(2) }}€
                          </p>
                        </div>
                      </div>
                    </li>
                    <li class="col-span-1 flex rounded-md shadow-sm mb-3">
                      <div class="bg-blue-700 flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white">
                        <ClockIcon class="h-5 w-5 text-white" aria-hidden="true" />
                      </div>
                      <div class="flex items-center justify-between truncate w-full rounded-r-md border-b border-r border-t border-gray-200 bg-slate-50">
                        <div class="px-4 py-2 text-sm">
                          <a class="font-bold text-gray-900 hover:text-gray-600">
                            Zeitraum
                          </a>
                          <p class="text-gray-500">
                            Am <span class="font-bold">{{ new Date(slot.slotStart).toLocaleDateString() }}</span> um <span class="font-bold">{{new Date(slot.slotStart).toLocaleTimeString().slice(0,-3)}}</span> Uhr - <span class="font-bold">{{ new Date(slot.slotEnd).toLocaleDateString() }}</span> um <span class="font-bold">{{new Date(slot.slotEnd).toLocaleTimeString().slice(0,-3)}}</span> Uhr
                          </p>
                        </div>
                      </div>
                    </li>
                    <li class="col-span-1 flex rounded-md shadow-sm mb-3">
                      <div class="bg-blue-700 flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white">
                        <MapPinIcon class="h-5 w-5 text-white" aria-hidden="true" />
                      </div>
                      <div class="flex items-center justify-between truncate w-full rounded-r-md border-b border-r border-t border-gray-200 bg-slate-50">
                        <div class="px-4 py-2 text-sm">
                          <a class="font-bold text-gray-900 hover:text-gray-600">
                            Location
                          </a>
                          <p class="text-gray-500">
                            <span class="f-bold">{{ slot.clubname }}</span> {{ slot.eventPlz }} {{ slot.eventCity }}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li class="col-span-1 flex rounded-md shadow-sm mb-3">
                      <div class="bg-blue-700 flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white">
                        <MusicalNoteIcon class="h-5 w-5 text-white" aria-hidden="true" />
                      </div>
                      <div class="flex items-center justify-between truncate w-full rounded-r-md border-b border-r border-t border-gray-200 bg-slate-50">
                        <div class="px-4 py-2 text-sm">
                          <a class="font-bold text-gray-900 hover:text-gray-600">
                            Musikrichtung
                          </a>
                          <p class="text-gray-500">
                            {{ slot.music }}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li class="col-span-1 flex rounded-md shadow-sm mb-3">
                      <div class="bg-blue-700 flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white">
                        <UserGroupIcon class="h-5 w-5 text-white" aria-hidden="true" />
                      </div>
                      <div class="flex items-center justify-between truncate w-full rounded-r-md border-b border-r border-t border-gray-200 bg-slate-50">
                        <div class="px-4 py-2 text-sm">
                          <a class="font-bold text-gray-900 hover:text-gray-600">
                            Anzahl der Plätze
                          </a>
                          <p class="text-gray-500">
                            {{ slot.officeJobs }} Plätze
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <!-- Freebookings vFor-->
                <div class="bg-white rounded shadow p-4" v-for="slot in openFreeBookingSlots" :key="slot.id">
                  <div class="border-b border-gray-200 bg-white py-5">
                    <div class="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
                      <div class="ml-4 mt-2">
                        <h3 class="text-base font-semibold leading-6 text-gray-900">DJ</h3>
                        <p class="mt-1 text-sm text-gray-500">{{ slot.description }}</p>
                      </div>
                      <div class="ml-4 mt-2 flex-shrink-0">
                        <button  @click="deleteSlotById(slot.id)" type="button" class="relative inline-flex items-center rounded-md bg-gray-600 px-2 py-1.5 text-xs font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600">
                          Löschen
                        </button>
                      </div>
                    </div>
                  </div>
                  <ul>
                    <li class="col-span-1 flex rounded-md shadow-sm mb-3">
                      <div class="bg-blue-700 flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white">
                        <CurrencyEuroIcon class="h-5 w-5 text-white" aria-hidden="true" />
                      </div>
                      <div class="flex items-center justify-between truncate w-full rounded-r-md border-b border-r border-t border-gray-200 bg-slate-50">
                        <div class="px-4 py-2 text-sm">
                          <a class="font-bold text-gray-900 hover:text-gray-600">
                            Gage
                          </a>
                          <p class="text-gray-500">
                            {{ slot.roomGage.toFixed(2) }}€
                          </p>
                        </div>
                      </div>
                    </li>
                    <li class="col-span-1 flex rounded-md shadow-sm mb-3">
                      <div class="bg-blue-700 flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white">
                        <ClockIcon class="h-5 w-5 text-white" aria-hidden="true" />
                      </div>
                      <div class="flex items-center justify-between truncate w-full rounded-r-md border-b border-r border-t border-gray-200 bg-slate-50">
                        <div class="px-4 py-2 text-sm">
                          <a class="font-bold text-gray-900 hover:text-gray-600">
                            Zeitraum
                          </a>
                          <p class="text-gray-500">
                            Am <span class="font-bold">{{ new Date(slot.slotStart).toLocaleDateString() }}</span> um <span class="font-bold">{{new Date(slot.slotStart).toLocaleTimeString().slice(0,-3)}}</span> Uhr - <span class="font-bold">{{ new Date(slot.slotEnd).toLocaleDateString() }}</span> um <span class="font-bold">{{new Date(slot.slotEnd).toLocaleTimeString().slice(0,-3)}}</span> Uhr
                          </p>
                        </div>
                      </div>
                    </li>
                    <li class="col-span-1 flex rounded-md shadow-sm mb-3">
                      <div class="bg-blue-700 flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white">
                        <MapPinIcon class="h-5 w-5 text-white" aria-hidden="true" />
                      </div>
                      <div class="flex items-center justify-between truncate w-full rounded-r-md border-b border-r border-t border-gray-200 bg-slate-50">
                        <div class="px-4 py-2 text-sm">
                          <a class="font-bold text-gray-900 hover:text-gray-600">
                            Location
                          </a>
                          <p class="text-gray-500">
                            <span class="f-bold">{{ slot.clubname }}</span> {{ slot.eventPlz }} {{ slot.eventCity }}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li class="col-span-1 flex rounded-md shadow-sm mb-3">
                      <div class="bg-blue-700 flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white">
                        <MagnifyingGlassIcon class="h-5 w-5 text-white" aria-hidden="true" />
                      </div>
                      <div class="flex items-center justify-between truncate w-full rounded-r-md border-b border-r border-t border-gray-200 bg-slate-50">
                        <div class="px-4 py-2 text-sm">
                          <a class="font-bold text-gray-900 hover:text-gray-600">
                            Gesucht
                          </a>
                          <p class="text-gray-500">
                            {{ slot.crewJobs }}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li class="col-span-1 flex rounded-md shadow-sm mb-3">
                      <div class="bg-blue-700 flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white">
                        <UserGroupIcon class="h-5 w-5 text-white" aria-hidden="true" />
                      </div>
                      <div class="flex items-center justify-between truncate w-full rounded-r-md border-b border-r border-t border-gray-200 bg-slate-50">
                        <div class="px-4 py-2 text-sm">
                          <a class="font-bold text-gray-900 hover:text-gray-600">
                            Anzahl der Plätze
                          </a>
                          <p class="text-gray-500">
                            {{ slot.officeJobs }} Plätze
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>


                </div>
                <!-- Keine Schichten vorhanden -->
                <div class="bg-white rounded shadow p-4" v-if="!openOfficeSlots.length && !openEventSlots.length && !openDjSlots.length && !openFreeBookingSlots.length ">
                  <p class="text-gray-500 text-sm">
                    Zurzeit gibt es keine aktuellen Schichten
                  </p>
                </div>
              </div>
              <div class="sm:col-span-3 pad-2">
                <div class="border-b border-gray-200 bg-white px-4 py-5">
                  <h3 class="text-xl font-semibold leading-6 text-gray-900">
                    Offene Schichtbewerbungen
                  </h3>
                </div>
                <!-- Muster muss als Vfor auf openUserSlots geändert werden-->
                <div class="bg-white rounded shadow p-4" v-for="slot in openUserSlots" :key="slot.id">
                  <div class="border-b border-gray-200 bg-white py-5">
                      <h3 class="text-base font-semibold leading-6 text-gray-900">{{slot.job=== 6?'DJ':''}}{{slot.job=== 7?'Büro':''}}{{slot.job=== 8?'Freelancer':''}}{{slot.job=== 9?'Eventcrew':''}}</h3>
                      <p class="mt-1 text-sm text-gray-500">{{ slot.firstname }} {{slot.lastname}}</p>
                  </div>
                  <ul>
                    <li class="col-span-1 flex rounded-md shadow-sm mb-3">
                      <div class="bg-blue-700 flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white">
                        <ClockIcon class="h-5 w-5 text-white" aria-hidden="true" />
                      </div>
                      <div class="flex items-center justify-between truncate w-full rounded-r-md border-b border-r border-t border-gray-200 bg-slate-50">
                        <div class="px-4 py-2 text-sm">
                          <a class="font-bold text-gray-900 hover:text-gray-600">
                            Zeitraum
                          </a>
                          <p class="text-gray-500">
                            Am <span class="font-bold">{{ new Date(slot.slotStart).toLocaleDateString() }}</span> um <span class="font-bold">{{new Date(slot.slotStart).toLocaleTimeString().slice(0,-3)}}</span> Uhr - <span class="font-bold">{{ new Date(slot.slotEnd).toLocaleDateString() }}</span> um <span class="font-bold">{{new Date(slot.slotEnd).toLocaleTimeString().slice(0,-3)}}</span> Uhr
                          </p>
                        </div>
                      </div>
                    </li>
                    <li class="col-span-1 flex rounded-md shadow-sm mb-3">
                      <div class="bg-blue-700 flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white">
                        <CurrencyEuroIcon class="h-5 w-5 text-white" aria-hidden="true" />
                      </div>
                      <div class="flex items-center justify-between truncate w-full rounded-r-md border-b border-r border-t border-gray-200 bg-slate-50">
                        <div class="px-4 py-2 text-sm">
                          <a class="font-bold text-gray-900 hover:text-gray-600">
                            <span v-if="slot.job === 8">Gage</span>
                            <span v-else>Stundenlohn</span>
                          </a>
                          <p class="text-gray-500">
                            {{ slot.officePay.toFixed(2) }}€
                          </p>
                        </div>
                      </div>
                    </li>
                    <li class="col-span-1 flex rounded-md shadow-sm mb-3">
                      <div class="bg-blue-700 flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white">
                        <EnvelopeIcon class="h-5 w-5 text-white" aria-hidden="true" />
                      </div>
                      <div class="flex items-center justify-between truncate w-full rounded-r-md border-b border-r border-t border-gray-200 bg-slate-50">
                        <div class="px-4 py-2 text-sm">
                          <a class="font-bold text-gray-900 hover:text-gray-600">
                            E-Mail-Adresse
                          </a>
                          <p class="text-gray-500">
                            {{ slot.email }}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li class="col-span-1 flex rounded-md shadow-sm mb-3">
                      <div class="bg-blue-700 flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white">
                        <PhoneIcon class="h-5 w-5 text-white" aria-hidden="true" />
                      </div>
                      <div class="flex items-center justify-between truncate w-full rounded-r-md border-b border-r border-t border-gray-200 bg-slate-50">
                        <div class="px-4 py-2 text-sm">
                          <a class="font-bold text-gray-900 hover:text-gray-600">
                            Telefonnummer
                          </a>
                          <p class="text-gray-500">
                            {{ slot.phone }}
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <div v-if="slot.message != null && slot.message != 'null' && slot.message != ''" class="bg-slate-50 p-4 rounded">
                    <h6 class="font-bold text-sm">Zusätzliche Nachricht:</h6>
                    <p class="mt-1 text-sm text-gray-500">{{ slot.message }}</p>
                  </div>
                  <div class="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mb-4">
                    <div class="sm:col-span-3">
                      <button class="w-full rounded-md bg-white px-2.5 py-1.5 text-xl font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50" @click="acceptSlotMember(slot.sMId)">✅</button>
                    </div>
                    <div class="sm:col-span-3">
                      <button class="w-full rounded-md bg-white px-2.5 py-1.5 text-xl font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50" @click="denySlotMember(slot.sMId)">❌</button>
                    </div>
                  </div>
                </div>
                <div class="bg-white rounded shadow p-4" v-if="!openUserSlots.length">
                  <p class="text-gray-500 text-sm">
                    Zurzeit gibt es keine offenen Berwerbungen
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <create-new-slot-modal v-if="createModal" @closeNewSlotModal ="createModal = false;getOpenSlots()"/>
    </div>
  </div>
</template>

<script>
import CreateNewSlotModal from "@/components/schichten/createNewSlotModal.vue";
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import {UserGroupIcon, CurrencyEuroIcon,ClockIcon, MapPinIcon,MusicalNoteIcon,MagnifyingGlassIcon, EnvelopeIcon, PhoneIcon} from '@heroicons/vue/20/solid'

export default {
  name: "slotPlaner",
  components: {CreateNewSlotModal,CurrencyEuroIcon, UserGroupIcon, ClockIcon, MapPinIcon,MusicalNoteIcon,MagnifyingGlassIcon, EnvelopeIcon, PhoneIcon},
  data(){
    return{
      createModal:false,
      openSlots:[],
      openOfficeSlots:[],
      openEventSlots:[],
      openDjSlots:[],
      openFreeBookingSlots:[],
      openUserSlots:[]
    }
  },
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  mounted() {
  this.getOpenSlots()
    this.getOpenSlotMembers()
    },
  methods:{

    // Gib mir alle erstellen offenen Schichten
    getOpenSlots() {
      const fD = new FormData
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post(this.config.projectURL + 'slots/getAllSlots', fD, {})
          .then(response => {
            console.log(response)
            if (response.status === 250) {
              this.openSlots = response.data
              this.openOfficeSlots = []
              this.openEventSlots = []
              this.openDjSlots = []
              this.openFreeBookingSlots = []
              response.data.map((slot)=>{

                switch (slot.job) {
                  case 7:{
                    this.openOfficeSlots.push(slot)
                    break
                  }
                  case 9:{
                    this.openEventSlots.push(slot)
                    break
                  }
                  case 6:{
                    this.openDjSlots.push(slot)
                    break
                  }
                  case 8:{
                    this.openFreeBookingSlots.push(slot)
                    break
                  }
                }
              })
            }
          })
          .catch(e => {
            console.error(e)
          })
    },
    //Lösche einen Slot mit der Id x
    deleteSlotById(deleteId){
      const fD = new FormData
      fD.append('sessionID', this.auth.sessionID)
      fD.append('id', deleteId)
      this.axios.post(this.config.projectURL + 'slots/deleteSlot', fD, {})
          .then(response => {
            if (response.status === 250) {
              this.getOpenSlots()
            }
          })
          .catch(e => {
            console.error(e)
          })
    },
    //Alle schichten mit Bewerbungen
    getOpenSlotMembers(){
      const fD = new FormData
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post(this.config.projectURL + 'slots/getOpenSlotMembers', fD, {})
          .then(response => {
            if (response.status === 250) {
              this.openUserSlots = response.data
              console.log(this.openUserSlots)
            }
          })
          .catch(e => {
            console.error(e)
          })
    },
    //Update schicht mit annahme oder absage
    acceptSlotMember(id) {
      const fD = new FormData
      fD.append('sessionID', this.auth.sessionID)
      fD.append('id', id)
      this.axios.post(this.config.projectURL + 'slots/acceptSlotMember', fD, {})
          .then(response => {
            console.log(response.data, response.status)
            if (response.status === 250) {
              console.log(response)
              this.getOpenSlotMembers()
            }
          })
          .catch(e => {
            console.error(e)
          })
    },
    denySlotMember(id) {
      const fD = new FormData
      fD.append('sessionID', this.auth.sessionID)
      fD.append('id', id)
      this.axios.post(this.config.projectURL + 'slots/denySlotMember', fD, {})
          .then(response => {
            console.log(response.data, response.status)
            if (response.status === 250) {
              console.log(response)
              this.getOpenSlotMembers()
            }
          })
          .catch(e => {
            console.error(e)
          })
    },

  }
}
</script>

<style scoped>
  main#slotPlaner{
    padding-bottom: 80px;
  }
  #slotPlaner h1{
    font-size: 2em;
    margin-bottom: 16px;
  }
  #slotPlaner h3{
    font-size: 1.4em;
  }
</style>