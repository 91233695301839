/* eslint-disable */
import { defineStore } from 'pinia'
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import { io } from "socket.io-client";

export const socketStore = defineStore('socket', {
    state: () => ({
        io: io,
        socket: {},
        online: false,
        connecting: false,
        smids: [] //SocketMessageIDs
    }),
    getters: {
    },
    actions: {
        /**
         * connect Socket
         */
        connect() {
            //TODO socket reaktivieren oder auch nicht
            return
            if(this.online) return
            const _this = this
            const auth = authStore()
            const config = configStore()

            this.connecting = true

            this.socket = this.io.connect(config.socketURL, {
                reconnection: true
            })

            this.socket.removeAllListeners()

            this.socket.on('error', (err) => {
                console.log("error due to "+err.message);
                //_this.connecting = false
                //_this.changeNodeServer()
            })

            this.socket.on('disconnect', (err) => {
                console.log("disconnect due to "+err.message);
            })

            this.socket.on('connect_error', (err) => {
                console.log("connect_error due to "+err.message);
            })

            this.socket.on('connect_failed', (err) => {
                console.log("connect_failed due to " + err.message);
            })

            this.socket.on('connect', () => {
                console.log('connected')
                _this.connecting = false
                _this.socket.emit('nodeToken', { nodeToken: auth.nodeToken })
            })

            this.socket.on('registered', () => {
                console.log('registered')
                _this.online = true
            })
        },
        disconnect() {
            this.socket.removeAllListeners()
        }
    },
})