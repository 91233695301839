<template>
  <li :class="{active: config.organizerId == clubItems.id}" class="relative flex justify-between gap-x-6 px-4 py-5 hover:bg-gray-50 sm:px-6">
    <div class="flex gap-x-4">
      <img class="h-auto w-24 flex-none bg-gray-50" :src="config.projectURL + 'assets/images/eventhoster/'+clubItems.logo" alt="" />
      <div class="min-w-0 flex-auto">
        <p class="text-sm font-semibold leading-6 text-gray-900">
          <a>
            <span class="absolute -top-px bottom-0" />
            {{ clubItems.organizerName  }}
          </a>
        </p>
      </div>
    </div>
    <div class="flex items-center gap-x-4">
      <div class="sm:flex sm:flex-col sm:items-end">
        <button v-if="config.organizerId != clubItems.id" @click="updateOrganizerId" type="button" class="rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
          Auswählen
        </button>
        <button v-else type="button" class="rounded bg-blue-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
          Aktiv
        </button>
      </div>
    </div>
  </li>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";

export default {
  name: "clubItems",
  props: ["clubItems"],
  emits:  ['update'],
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
    }
  },
  mounted() {
    this.config.loading = false
  },
  methods: {
    updateOrganizerId(){
      this.config.organizerId = this.clubItems.id
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('clubId', this.clubItems.id)
      this.axios.post(this.config.projectURL + 'profil/updateOrganizerId', fD)
          .then(response => {
            this.$emit('update')
          })
          .catch(e => {console.log(e)})
    },
  }
}
</script>

<style scoped>

.active{
  --tw-bg-opacity: 1;
  background-color: rgb(239 246 255 / var(--tw-bg-opacity));
}

</style>