<template>
  <div class="px-4">
    <div class="mb-2">
      <div>
        <nav class="sm:hidden" aria-label="Back">
          <router-link to="/notificationservice" class="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700">
            <ChevronLeftIcon class="-ml-1 mr-1 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
            Zurück
          </router-link>
        </nav>
        <nav class="hidden sm:flex" aria-label="Breadcrumb">
          <ol role="list" class="flex items-center space-x-4">
            <li>
              <div class="flex">
                <a href="/notificationservice" class="text-sm font-medium text-gray-500 hover:text-gray-700">Übersicht</a>
              </div>
            </li>
            <li>
              <div class="flex items-center">
                <ChevronRightIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                <a class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">Geburtstagsnachricht</a>
              </div>
            </li>
          </ol>
        </nav>
      </div>
      <div class="mt-2 md:flex md:items-center md:justify-between">
        <div class="min-w-0 flex-1">
          <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">Geburtstagsnachricht</h2>
        </div>
        <div class="mt-4 flex flex-shrink-0 md:ml-4 md:mt-0">
        </div>
      </div>
    </div>
    <section class="mt-4 bg-white p-4 rounded shadow">
      <div class="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mb-4">
        <div class="sm:col-span-3">
          <div>
            <div>
              <div class="border-b border-gray-200 bg-white py-5">
                <h3 class="text-xl font-semibold leading-6 text-gray-900">E-Mail Konfigurieren</h3>
              </div>
              <div class="relative mt-4">
                <label for="mailTemplate" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">E-Mail-Design <span>*</span></label>
                <select v-model="mail.template" name="mailTemplate" id="mailTemplate" class="px-2 text-sm block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6">
                  <option v-for="template in mailTemplates" :key="template.id" :value="template.id">
                    {{ template.name }}
                  </option>
                </select>
              </div>
              <div class="relative mt-4">
                <label for="mailSubject" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Betreff <span>*</span></label>
                <input v-model="mail.subject" type="text" name="mailSubject" id="mailSubject" placeholder="Betreff" required class="px-2 text-sm block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6" />
              </div>
              <div class="relative mt-4">
                <label for="mailGreeting" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Begrüßung <span>*</span></label>
                <input v-model="mail.greeting" type="text" name="mailGreeting" id="mailGreeting" placeholder="Hey / Hallo o.Ä." required class="px-2 text-sm block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6" />
              </div>
              <div class="relative mt-4">
                <label for="mailBody" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Inhalt <span>*</span></label>
                <textarea v-model="mail.body" name="mailBody" id="mailBody" rows="4" required placeholder="Inhalt" class="px-2 text-sm block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6"></textarea>
              </div>
              <div class="relative mt-4">
                <label for="mailEnding" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Abschieds / Grußformel <span>*</span></label>
                <textarea v-model="mail.ending" name="mailEnding" id="mailEnding" required rows="4" placeholder="Viel Spaß auf der Party" class="px-2 text-sm block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6"></textarea>
              </div>
              <div class="relative mt-8">
                <label for="mailEvents" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Events auswählen</label>
                <select v-model="mail.events" multiple name="mailEvents" id="mailEvents" class="px-2 text-sm block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6">
                  <option v-for="event in events" :value="event.id" :key="event.id">
                    {{ dateString(event.startTime) + ' - ' + event.title }}
                  </option>
                </select>
                <p class="text-grey-300 text-xs">Mehrfachauswahl möglich: (Strg / Command) gedrückt halten.</p>
              </div>
            </div>
            <div class="relative mt-8">
              <label for="mailCoupons" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Coupon auswählen</label>
              <select v-model="mail.coupons" multiple name="mailCoupons" id="mailCoupons" class="px-2 text-sm block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6">
                <option v-for="coupon in coupons" :key="coupon.id" :value="coupon.id">
                  {{ coupon.title + ' - ' + coupon.code }}
                </option>
              </select>
              <p class="text-grey-300 text-xs">Mehrfachauswahl möglich: (Strg / Command) gedrückt halten.</p>
            </div>
            <div class="mt-4">
              <button @click="mailPreview" :disabled="!checkPreview" :class="{disabled: !checkPreview}" type="button" class="rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">E-Mail Vorschau anzeigen</button>
            </div>
          </div>
        </div>
        <div class="sm:col-span-3">
          <div>
            <div class="border-b border-gray-200 bg-white py-5">
              <h3 class="text-xl font-semibold leading-6 text-gray-900">Push-Nachricht konfigurieren</h3>
            </div>
            <div class="relative mt-4">
              <label for="pushTitle" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Titel <span>*</span></label>
              <input v-model="push.title" type="text" name="pushTitle" id="pushTitle" maxlength="64" placeholder="Titel (max. 64 Zeichen)" required class="px-2 text-sm block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6" />
            </div>
            <div class="relative mt-4">
              <label for="pushBody" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Inhalt <span>*</span></label>
              <textarea v-model="push.body" rows="3" name="pushBody" id="pushBody" maxlength="256" placeholder="Inhalt (max. 256 Zeichen)" required class="px-2 text-sm block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6"></textarea>
            </div>
            <div class="relative mt-4">
              <label for="pushLink" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Link</label>
              <input v-model="push.link" type="text" name="pushLink" id="pushLink" placeholder="https://www.zeillink.de" class="px-2 text-sm block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6" />
            </div>
          </div>
        </div>
      </div>
      <div class="mt-8 flex flex-row-reverse">
        <button @click="save" :disabled="!checkSend" :class="{disabled: !checkSend}" type="button" class="rounded-md bg-blue-600 px-3 py-2 text-l font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
          Speichern
        </button>
      </div>
    </section>
  </div>
  <TransitionRoot as="template" :show="mailPreviewModal">
    <Dialog as="div" class="relative z-10" @close="mailPreviewModal = false">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
              <div>
                <div class="mt-3 text-center sm:mt-5">
                  <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">E-Mail Vorschau</DialogTitle>
                  <div class="mt-2">
                    <div v-html="mailPreviewHTML" />
                  </div>
                </div>
              </div>
              <div class="mt-5 sm:mt-6">
                <button type="button" class="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600" @click="mailPreviewModal = false">Vorschau schließen</button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import {aSyncData, stringToDate} from "@/modules/main";
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/24/outline'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'

export default {
  name: "birthday",
  components:{
    ChevronLeftIcon, ChevronRightIcon,
    Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return{
      saved: true,
      events: [],
      coupons: [],
      mailTemplates: [],
      mailPreviewModal: false,
      mailPreviewHTML: '',
      mailActive: false,
      pushActive: false,
      mail: {
        templateId: 1,
        subject: '',
        greeting: '',
        body: '',
        ending: '',
        events: [],
        coupons: []
      },
      push: {
        title: '',
        body: ''
      }
    }
  },
  computed: {
    checkSend(){
      return (this.mailActive || this.pushActive) && (!this.mailActive || (this.mail.subject.length && this.mail.greeting.length && this.mail.body.length && this.mail.ending.length)) && (!this.pushActive || (this.push.title.length && this.push.body.length))
    },
    checkPreview(){
      return this.mail.greeting.length && this.mail.body.length && this.mail.ending.length
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init(){
      const _this = this
      aSyncData('notificationService/getBirthdayData', {organizerId: this.config.organizerId})
          .then(r => {
            console.log(r)
            if(r.status === 250){
              _this.events = r.data.events
              _this.coupons = r.data.coupons
              _this.mailTemplates = r.data.mailTemplates
              if(r.data.birthdayMailTemplate){
                _this.mailActive = r.data.birthdayMailTemplate.mailActive
                _this.pushActive = r.data.birthdayMailTemplate.pushActive
                _this.mail = JSON.parse(r.data.birthdayMailTemplate.mail)
                _this.push = JSON.parse(r.data.birthdayMailTemplate.push)
              }
            }
          })
          .catch(e => {
            console.error(e)
          })
    },
    dateString(eventDate){
      let timestampArr = eventDate.split(" ");
      let date = timestampArr[0];
      let time = timestampArr[1];
      const dateArr = date.split("-");
      const timeArr = time.split(":");
      return dateArr[2]+'.'+dateArr[1]+'.'+dateArr[0];
    },
    mailPreview(){
      const _this = this
      aSyncData('notificationService/mailPreview', {
        organizerId: this.config.organizerId,
        mailTemplate: this.mail.template,
        mailGreeting: this.mail.greeting,
        mailBody: this.mail.body,
        mailEnding: this.mail.ending,
        mailEvents: JSON.stringify(this.mail.events),
        mailCoupons: JSON.stringify(this.mail.coupons)
      })
          .then(r => {
            if(r.status === 250){
              _this.mailPreviewHTML = r.data
              _this.mailPreviewModal = true
            }else{
              console.log(r)
            }
          })
          .catch(e => {
            console.log(e)
          })
    },
    save(){
      const _this = this
      aSyncData('notificationService/saveBirthdayTemplate', {
        organizerId: this.config.organizerId,
        mailActive: this.mailActive,
        pushActive: this.pushActive,
        mail: JSON.stringify(this.mail),
        push: JSON.stringify(this.push)
      })
          .then(r => {
            console.log(r)
            if(r.status === 250)
              _this.$router.push('/notificationservice?save=success')
          })
          .catch(e => {
            console.log(e)
          })
    }
  }
}
</script>

<style scoped>
#notificationService h1{
  font-size: 2em;
}
.active{
  background-color: #2068ff;
}
button, .button{
  text-align: center;
}
</style>